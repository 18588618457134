import React, {Component, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './bar_container.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import AuthService from '../../services/auth.service';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
  NavLink,
  useNavigate
} from "react-router-dom";

import './bar_container.css';


import logoImage from '../../assets/LOGO-BBA-TRANSPARENTE1.webp';

function BarContainer() {
  const [update,setUpdate]= useState(false);
  const navigate = useNavigate();
  async function logout ()  
  {
   
    localStorage.clear();
     navigate("/login");
     window.location.reload(false);
  }
  const actualPath = useLocation().pathname;
  const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user : null;
  if(user.hasOwnProperty("groups") === false)
  {
    user= null;
  }

  //console.log("User: ",user);
    return ( 

      
        
        
        
          <nav className="navbar navbar-expand-lg navbar-light bg-light">

            <div className="container-fluid">
              <a className="navbar-brand" href="">
                <img src={logoImage} width="302.137" height="84.81" className="d-inline-block align-top" alt=""/>
              </a>
                    
              <div className="collapse navbar-collapse " id="navbarNavAltMarkup">
                <div className="navbar-nav  position-absolute top-50 start-50 translate-middle">

                {
                  ( (user !== null ) && user.groups.find(obj => (obj === 1 || obj === 2 || obj === 3) )) && (  <Link style={{ fontWeight: 600,color:'navy'}} className= { actualPath==="/" ? "activo nav-link m-2 " : "noActivo nav-link m-2"} to="/">INICIO</Link> )
                  
                }
                {
                  ((user !== null) && user.groups.find(obj => (obj === 1 || obj === 3))) && ( <Link style={{ fontWeight: 600,color:'navy'}} className={ actualPath==="/solicitudes" ? "activo nav-link m-2" : "noActivo nav-link m-2"} to="/solicitudes">SOLICITUDES</Link> )
                  
                }
                {
                  ((user !== null) && user.groups.find(obj => (obj === 1 || obj === 3))) && ( <Link style={{ fontWeight: 600,color:'navy'}} className={ actualPath==="/cotizaciones" ? "activo nav-link m-2" : "noActivo nav-link m-2"} to="/cotizaciones">COTIZACIONES DE TRANSPORTE</Link>  )
                  
                }
                {
                  ((user !== null) && user.groups.find(obj => (obj === 1 || obj === 3))) && ( <Link style={{ fontWeight: 600,color:'navy'}} className={ actualPath==="/consolidados" ? "activo nav-link m-2" : "noActivo nav-link m-2"} to="/consolidados">CONSOLIDADOS</Link>  )
                  
                }
                {
                  ((user !== null) && user.groups.find(obj => (obj === 1 || obj === 3))) && ( <Link style={{ fontWeight: 600,color:'navy'}} className={ actualPath==="/servicios" ? "activo nav-link m-2" : " noActivo nav-link m-2"} to="/servicios">COTIZACIONES DE SERVICIOS</Link> )
                  
                }
                {
                  ((user !== null) && user.groups.find(obj => (obj === 1 || obj === 2))) && ( <Link style={{ fontWeight: 600,color:'navy'}} className={ actualPath==="/proveedores" ? "activo nav-link m-2" : "noActivo nav-link m-2"} to="/proveedores">PROVEEDORES</Link>   )
                  
                }
                {
                  ((user !== null) && user.groups.find(obj => (obj === 1 || obj === 3))) && ( <Link style={{ fontWeight: 600,color:'navy'}} className={ actualPath==="/clientes" ? "activo  nav-link m-2" : " noActivo nav-link m-2"} to="/clientes">CLIENTES</Link> )
                  
                }
                {
                  ((user !== null) && user.groups.find(obj => (obj === 1 || obj === 2))) && ( <Link style={{ fontWeight: 600,color:'navy'}} className={ actualPath==="/driver" ? "activo  nav-link m-2" : " noActivo nav-link m-2"} to="/driver">MOTORISTAS</Link> )
                  
                }
                {
                  ((user !== null) && user.groups.find(obj => (obj === 1 || obj === 2))) && ( <Link style={{ fontWeight: 600,color:'navy'}} className={ actualPath==="/transporter" ? "activo  nav-link m-2" : " noActivo nav-link m-2"} to="/transporter">TRANSPORTADORAS</Link> )
                  
                }

               
                  
                  
                  
                  
                  
                  
                  
                  {/*<Link style={{ fontWeight: 700,color:'navy'}} className={ actualPath==="/" ? "activo nav-link m-2" : "noActivo nav-link m-2"} to="/">OTROS</Link>*/}
                </div>
             </div>
              <div className="m-2">
                { (user !== null) && user.name  }
              </div>
              <button 
                  className="m-2 btn  btn-sm"
                  onClick={logout}
                >
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16" >
                  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                  <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>

                </svg>
              </button>
            </div>
                
          </nav>
            
           
        
      
     );
}

export default BarContainer;