import React, {Component,useState} from 'react';


import 'bootstrap/dist/css/bootstrap.min.css';


const FilterComponent = ({ filterText, onFilter, name }) => (
	<>
		  <label className='p-1' >{name}</label>
          <input 
            name={name+" Input"}
            type={filterText}
            placeholder={"Filtrar por "+name}
            onChange={onFilter}
            className= "form-control"               
            />
    </>
);
export default FilterComponent;