
import React, { useEffect, useState, useMemo } from "react";
import userService from "../../services/user.service";
import UserService    from '../../services/user.service';
import { TableHeader, Search,Pagination} from "../DataTable";

import DataTable from "react-data-table-component"

import FormContainer from '../form/form_container';
import LoadingSpinner from "../loading/loadingComponent";


const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',

};

const DataTableTransporters = ({plateSearch,nameSearch,setIDSelected,setEditTrans}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [dataRow,setDataRow] = useState();
    
    const [transporterData,setTransporterData] = useState("");
    const [observations,setObservations] = useState("");
    const customStyles = {
       
        headCells: {
            style: {
                background :    'navy',
                fontWeight: 700,
                fontSize : "2.2cap",
                color : 'white',
                opacity: 0.9,
                textAlign : 'center'
            },
        },
        
    };
    async function onSubmit (e) {
        e.preventDefault();
        try{
            const response = await UserService.patchObserTrans(observations,transporterData.id  );
              
            if(response.data)
            {
              
                reloadTable();
                console.log(response.data);
            }    
            else{
                console.log(response.data);
            }
          
        }catch (error){
            console.error(error);
        }
    }


    async function onSubmitDelete (e) {
        e.preventDefault();
        try{
           
            const response = await UserService.deleteTransporter(dataRow.id);
            console.log("response", response);
                //.then((response)=>{if(response.data){console.log("Put Respond: ",response.data);setValidDescription(""); e.target.reset();} else{console.log(response.data);}});
                reloadTable();

            
        }catch (error){
            console.error(error);
        }

        
       
       
    }

    const columns1 = [
        {
            name: 'NOMBRE ',
            selector: row => row.name.toUpperCase(),
            sortable: true,
            
            
        },
        {
            name: 'CNPJ',
            selector: row => row.cnpj?.toUpperCase(),
            sortable: true,
            
            
        },
        {
            name: 'ZONA',
            selector: row => row.zone?.toUpperCase(),
            sortable: true,
            
            
        },
        {
            name: 'CELULAR',
            selector: row => row.cellphone?.toUpperCase(),
            sortable: true,
            
            
        },
        {
            name: 'BANCO',
            selector: row => row.bank.toUpperCase(),
            sortable: true,
            
            
        },
        
        
        {
            button: true,
            width: "60px",
            selector: row => (
                <div >
                    <div className="openbtn text-center">
                        <button
                        type="button"
                        className="btn  btn-sm"
                        data-bs-toggle ="modal"
                        data-bs-target= "#myModal"
                        //data-tag="allowRowEvents"
                        onClick={ () => {setTransporterData(row) ; setObservations(row.observations)}}
                        >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#041b5b" class="bi bi-chat-left-dots" viewBox="0 0 16 16">
                            <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                            <path d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                        </svg>   
                                                    
                            
                        </button>
                    </div>
                    
                    <div className='modal modal-lg' tabIndex="-1" id="myModal">
                        <div  className='modal-dialog'>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">OBSERVACIONES</h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        
                                    ></button>
                                </div>
                                <div className='modal-body'>
                                    <div className='border rounded border-5 p-2' >   
                                        <form   onSubmit={onSubmit}>
                                                
                                                <div className='m-2 input-group mx-auto'>
                                                
                                                            
                                                            <textarea 
                                                                type="text"  
                                                                className='col-md-8 form-control'
                                                                name="validation_description"    
                                                                value={observations}
                                                                onChange={(e)=> {setObservations(e.target.value)}}
                                                                
                                                        
                                                            />
                                                </div>
                                               
                                                
                                                <div className='m-2 input-group mx-auto'>
                                            
                                                    <button data-bs-dismiss="modal" className="btn btn-lg btn-secondary" style={{backgroundColor: "navy"}}  type="submit">
                                                        GUARDAR
                                                    </button>
                                                </div>
                                        </form>        
                                                
                                                
                                                
                                            
                                    </div>
                                </div>
                            </div>
    
                        </div>
                    </div>
                </div>
                
                    )
        },
       
        {
            button: true,
            width: "60px",
            selector: row => (
                <div >
                    <div className="openbtn text-center">
                        <button
                        type="button"
                        className="btn  btn-sm"
                        data-bs-toggle ="modal"
                        data-bs-target= "#myModalBank"
                        //data-tag="allowRowEvents"
                        onClick={ () => { setTransporterData(row)}}
                        >
                           
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#041b5b" class="bi bi-bank" viewBox="0 0 16 16">
                            <path d="m8 0 6.61 3h.89a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v7a.5.5 0 0 1 .485.38l.5 2a.498.498 0 0 1-.485.62H.5a.498.498 0 0 1-.485-.62l.5-2A.501.501 0 0 1 1 13V6H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 3h.89L8 0ZM3.777 3h8.447L8 1 3.777 3ZM2 6v7h1V6H2Zm2 0v7h2.5V6H4Zm3.5 0v7h1V6h-1Zm2 0v7H12V6H9.5ZM13 6v7h1V6h-1Zm2-1V4H1v1h14Zm-.39 9H1.39l-.25 1h13.72l-.25-1Z"/>
                            </svg>
                             
                        </button>
                    </div>
                    
                    <div className='modal modal-lg' tabIndex="-1" id="myModalBank">
                        <div  className='modal-dialog'>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">INFORMACIÓN BANCARIA</h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    ></button>
                                </div>
                                <div className='modal-body'>
                                    

                                        <div className='border rounded border-5 p-2 m-2' >   
                                            
                                            <div className='m-2 input-group mx-auto '>
                                                <span className='col-md-4 input-group-text'>BANCO</span>
                                                <input 
                                                type="text"  
                                                
                                                readOnly
                                                className='col-md-8 form-control'
                                                value={  transporterData.bank}
                                          
                                                />
                                            </div>
                                            <div className='m-2 input-group mx-auto'>
                                               
                                                <span className='col-md-4 input-group-text'>AGENCIA</span>
                                                <input 
                                                    type="text"  
                                                
                                                    readOnly
                                                    className='col-md-8 form-control'
                                                    value={transporterData.bank_agency}
                                             
                                                />
                                            </div>
                                            <div className='m-2 input-group mx-auto'>
                                           
                                                <span className='col-md-4 input-group-text'>CUENTA BANCARIA</span>
                                                <input 
                                                type="text"  
                                                
                                                    name='phone'
                                                    readOnly
                                               
                                                className='col-md-8 form-control'
                                                value={transporterData.bank_account}
                                            
                                                />
                                            </div>
                                            <div className='m-2 input-group mx-auto'>
                                               
                                                <span className='col-md-4 input-group-text'>FAVORECIDO</span>
                                                <input 
                                                    type="text"  
                                                    
                                                    readOnly
                                                    className='col-md-8 form-control'
                                                    value= {transporterData.account_name}
                                                
                                                />
                                            </div>
                                            <div className='m-2 input-group mx-auto'>
                                           
                                                <span className='col-md-4 input-group-text'>CNPJ</span>
                                                <input 
                                                type="text"  
                                                
                                                    name='phone'
                                                    readOnly
                                               
                                                className='col-md-8 form-control'
                                                value={transporterData.cnpj}
                                            
                                                />
                                            </div>
                                            <div className='m-2 input-group mx-auto'>
                                               
                                                <span className='col-md-4 input-group-text'>CLAVE PIX</span>
                                                <input 
                                                    type="text"  
                                                    
                                                    readOnly
                                                    className='col-md-8 form-control'
                                                    value= {transporterData.pix}
                                                
                                                />
                                            </div>
                                            
                                           
                                        </div>
                                </div>
                            </div>
    
                        </div>
                    </div>
                    

                        

                    
                    
                </div>
                
                    )
        },
        
        {
            button: true,
            width:"60px",
            cell: row => (
                <div >
                    <div className="openbtn text-center ">
                        <button
                        type="button"
                        className="btn  btn-sm "
                        onClick={() =>{setIDSelected(row); setEditTrans(true);}}
                        //onClick={setIDSelected(row)}
                        
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#041b5b " class="bi bi-pencil-square" viewBox="0 0 16 16">
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                            <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                            </svg>
                        </button>
                    </div>
                   
                </div>
                
                    )
        },
        {
            button: true,
            width: "60px",
            cell: row => (
                <div >
                    <div className="openbtn text-center">
                        <button
                        type="button"
                        className="btn btn-danger btn-sm"
                        data-bs-toggle ="modal"
                        data-bs-target= "#myModalDelete"
                        //data-tag="allowRowEvents"
                        onClick={ () => { setDataRow(row) ;  }}
                        >
                        
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
                            </svg>
                            
                        </button>
                    </div>
                    
                    <div className='modal modal-lg' tabIndex="-1" id="myModalDelete">
                        <div  className='modal-dialog'>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title"> ¿ESTA SEGURO DE ELIMINAR LA TRANSPORTADORA  {  (dataRow) &&(dataRow.name ) + " ?" }</h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    ></button>
                                </div>
                                <div className='modal-body'>
                                    

                                        <form onSubmit={onSubmitDelete}>   
                                            
                                          
                                            
                                            <div className="row m-2 p-2">
                                                <div className="col">
                                                    <button data-bs-dismiss="modal" className="btn btn-danger" type="submit"> ELIMINAR</button>
                                                    
                                                </div>
                                            </div>   
                                        </form>
                                </div>
                            </div>

                        </div>
                    </div>
   
                 </div>
                
                    )
        }
        

       
    ]

    //console.log("agency row",agencyData);

   
   
    const [listTransporters,setlistTranporters] = useState([]);

  


    useEffect(() => {
        const getData = () => {
           
            setIsLoading(true);
        

        UserService.getTransporterAgency().then(trans => {setlistTranporters(trans);  
                    setIsLoading(false)    
        }).catch( () => { setErrorMessage("No se puede obtener los datos del servidor"); setIsLoading(false); }  );
            
           
           
            
            
            
        };
        
        getData();
    }, []);

    const reloadTable = () => {
        setIsLoading(true);

        UserService.getTransporterAgency().then(trans => {setlistTranporters(trans);  
            setIsLoading(false)    
        }).catch( () => { setErrorMessage("No se puede obtener los datos del servidor"); setIsLoading(false); }  );
            
    }

    
 
    const [filterTextReq, setFilterTextReq] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = listTransporters.filter(
        item => ( (( (item.name ) && (item.name ).toLowerCase().includes(nameSearch.toLowerCase())  )
       
        ))
  );
        console.log("filter: ",filteredItems)
    //console.log("search: ",plateSearch);
    //console.log("Search1: ", nameSearch);
    //****************************** */
    //console.log("Search(out usememo):",search);
    //console.log("show",show);
    return (
         
            
           <>
            
           <div className="row w-100">
                <div className="col mb-3 col-12 text-center">
                
                {//console.log(isLoading)
                }
                   { isLoading ? <LoadingSpinner/> :

                    <div>
                        
                        <table className="table table-striped">
                              
                                
                              <DataTable
                              columns={columns1}
                              data={filteredItems}
                              pagination
                              paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                              
                              noDataComponent="No hay registros"
                              persistTableHead
                            customStyles={customStyles}
                              defaultSortFieldId = "1"
                              highlightOnHover
                              paginationPerPage={30}
                              paginationComponentOptions={paginationComponentOptions}
                              /> 
                          

                          
                          
                         
              </table>
                    </div>
                    
                    }
                </div>
            </div>
           </>

           
            
           
        
    );
};

export default DataTableTransporters;