import React,{useEffect,useState} from "react";


import {
    Document,
    Page,
    View,
    Image,
    Text,
    StyleSheet,
    usePDF,
    Svg,
    Line
} from '@react-pdf/renderer';
import Select from 'react-select';
import logoImage from '../../assets/LOGO-BBA-TRANSPARENTE1.png';
import { Alignment } from "react-data-table-component";
//------------------------------------------------

const PDFViewer = ({
    title,
    style,
    className,
    fileName,
    children,
    innerRef,
    ...props
  }) => {
    const [instance, updateInstance] = usePDF({ document: children });
    const [url, set] = useState();
  
    useEffect(() => {
      if (instance.blob) {
        set(
          URL.createObjectURL(
            new File([instance.blob], fileName, {
              type: instance.blob.type
            })
          )
        );
      }
    }, [instance]);
  
    useEffect(updateInstance, [children]);
  
    return (
      <>
        <iframe
          title={title}
          ref={innerRef}
          width="100%"
          height="100%"
          src={url}
          className={className}
          {...props}
        />
      </>
    );
  };



//------------------------------------------

const styles = StyleSheet.create(
    {
        tabla: {
            display : "table",
            borderStyle : "solid",
            borderColor : "#000",
            borderWidth : 1,
            borderRightWidth: 0,
            borderBottomWidth: 0,

        },
        tablaFila : {
            margin : "auto",
            flexDirection: "row",
            height: "20pt"
            
        },
        tablaColumna0: {
            width: "100%",
            borderStyle : "solid",
            borderColor : "#000",
            borderWidth : 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
        },
        tablaColumna1 : {
            width: "16%",
            borderStyle : "solid",
            borderColor : "#000",
            borderWidth : 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
                },
        tablaColumna2 : {
            width: "16%",
            borderStyle : "solid",
            borderColor : "#000",
            borderWidth : 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
        },
        tablaColumna3 : {
            width: "13%",
            borderStyle : "solid",
            borderColor : "#000",
            borderWidth : 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
        },
        tablaColumna4 : {
            width: "5%",
            borderStyle : "solid",
            borderColor : "#000",
            borderWidth : 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
        },
        tablaColumna5 : {
            width: "9%",
            borderStyle : "solid",
            borderColor : "#000",
            borderWidth : 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
        },
        tablaColumna6 : {
            width: "11%",
            borderStyle : "solid",
            borderColor : "#000",
            borderWidth : 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
        },
        tablaColumna7 : {
            width: "12%",
            borderStyle : "solid",
            borderColor : "#000",
            borderWidth : 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
        },
        tablaColumna8 : {
            width: "18%",
            borderStyle : "solid",
            borderColor : "#000",
            borderWidth : 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
        },
        tablaTexto: {
            margin: "auto",
            
            fontSize: 9,
        },  
        
        encabezado: {
            width:"60%",
            borderLeftWidth: 1,
          
        },  
        textoEncabezado: {
            marginLeft: 5,
            marginVertical: 1,
            fontSize: 9,
        },  
        tablaTextoAzul: {
            margin: "auto",
            color: "navy",
            fontSize: 9,
        },
        
        texto: {
            margin: "auto",
            
            fontSize: 9,
            
            
        },
        textoAzul: {
            margin: "auto",
            textAlign:"left",
            fontSize: 9,
            color: "navy",
            alignItems:"flex-start",
        },
       
        borde: {
            borderBottomStyle:"solid",
            borderColor:"#000",
            borderWidth:1
        },
        textoNegritas: {
            fontSize: 9,
            margin : "auto",
            
            fontWeight: "extrabold",
        },
        fila1 : {
            display: "flex",
            flexDirection: "row",
            height : "12px",
            
        },
        columna1 : {
            width: "20%",
            margin: 0,
        },
        columna2 : {
            width: "80%",
            margin: 0,
        },
        
        footerPage :{
            position: 'absolute',
            
            bottom: 20,
            left: 0,
            right: 0,
          
            
        }
    }
);



const ConsolidatedPDF = ({consolidated,details})  => {

    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user : null;


    function formatDate(date){
        const day = date.substring(8,10);
        const month = date.substring(5,7);
        const year = date.substring(0,4);
        return day+"/"+month+"/"+year;
    }

    function floatToString (num = 0){
        
        if(num === null)
        {
            return "";
        }
        else
        {
            var numFormat = num.toLocaleString("es-BO") ;
            return numFormat ? numFormat : ""
           
        }
        }
      

    const datos = consolidated;
    const list_details = details;
    const pdfName = "Coti.pdf" 
    console.log("PDF datos: ",datos);
    console.log("PDF details: ",list_details);

    let totalQuantity = 0;
    let totalVolume = 0.0;
    let totalWeight = 0.0;
    
    console.log("TQ: ",totalQuantity)
    return (

        <>
        <PDFViewer fileName={pdfName}>
        <Document  >
                <Page size = "LETTER" orientation = "landscape" >

                    <View style={[{marginHorizontal: "20%",marginTop: "3%" },styles.borde]} >
                        <View style={[{flexDirection:"row"}]}>
                            <Image  
                                style={[{ width : "40%",marginHorizontal: "5%",marginVertical:"1%"}]}  
                                source={logoImage}
                                        
                            />
                            <View style={[{},styles.encabezado,]}>
                                <Text style={[styles.textoEncabezado,{}]}>
                                    CONSOLIDADO SP - {formatDate(datos.consolidated_date)}
                                </Text>
                                <Text style={[styles.textoEncabezado,{}]}>
                                    TIPO DE VEHICULO : {datos.transport_br}
                                </Text>
                                <Text style={[styles.textoEncabezado,{}]}>
                                    PLACAS : {datos.plate_br}
                                </Text>
                                <Text style={[styles.textoEncabezado,{}]}>
                                    MOTORISTA : {datos.driver_br}
                                </Text>
                                <Text style={[styles.textoEncabezado,{}]}>
                                    CPF : {datos.cpf_br}
                                </Text>
                            </View>
                        </View>
                    </View>
                    <View style={[{height:"3%"}]}>

                    </View>
                    <View style={[{marginHorizontal:"3%"},styles.tabla]}>
                        <View style={[{},styles.tablaFila]}>
                            <View style={[{},styles.tablaColumna1]}>
                                <Text style={[styles.textoNegritas,{}]}>
                                    NOTA FISCAL
                                </Text>
                            </View>
                            <View style={[{},styles.tablaColumna2]}>
                                <Text style={[styles.textoNegritas,{}]}>
                                   EXPORTADOR
                                </Text>
                            </View>
                            <View style={[{},styles.tablaColumna3]}>
                                <Text style={[styles.textoNegritas,{}]}>
                                   IMPORTADOR
                                </Text>
                            </View>
                            <View style={[{},styles.tablaColumna4]}>
                                <Text style={[styles.textoNegritas,{}]}>
                                   QUANT
                                </Text>
                            </View>
                            <View style={[{},styles.tablaColumna5]}>
                                <Text style={[styles.textoNegritas,{}]}>
                                   CUBAGEM
                                </Text>
                            </View>
                            <View style={[{},styles.tablaColumna6]}>
                                <Text style={[styles.textoNegritas,{}]}>
                                  PESO BRUTO 
                                </Text>
                            </View>
                            <View style={[{},styles.tablaColumna7]}>
                                <Text style={[styles.textoNegritas,{}]}>
                                    CRT 
                                </Text>
                            </View>
                            <View style={[{},styles.tablaColumna8]}>
                                <Text style={[styles.tablaTexto,{}]}>
                                    DESPACHANTE
                                </Text>
                            </View>
                        </View>

                        {
                            list_details.map(
                                (item,index) => {
                                    totalQuantity += item.quantity;
                                    totalVolume += item.quote.volume;
                                    totalWeight += item.quote.weight;
                                    return (
                                        <View style={[{},styles.tablaFila]}>
                                            <View style={[{},styles.tablaColumna1]}>
                                                <Text style={[styles.textoNegritas,{}]}>
                                                    {item.fiscal_note}
                                                </Text>
                                            </View>
                                            <View style={[{},styles.tablaColumna2]}>
                                                <Text style={[styles.textoNegritas,{}]}>
                                                {item.quote.contact_supplier.supplier_name}
                                                </Text>
                                            </View>
                                            <View style={[{},styles.tablaColumna3]}>
                                                <Text style={[styles.textoNegritas,{}]}>
                                                    {item.quote.enterprise_name}
                                                </Text>
                                            </View>
                                            <View style={[{},styles.tablaColumna4]}>
                                                <Text style={[styles.textoNegritas,{}]}>
                                                    {item.quantity}
                                                </Text>
                                            </View>
                                            <View style={[{},styles.tablaColumna5]}>
                                                <Text style={[styles.textoNegritas,{}]}>
                                                    {floatToString(item.quote.volume) }
                                                </Text>
                                            </View>
                                            <View style={[{},styles.tablaColumna6]}>
                                                <Text style={[styles.textoNegritas,{}]}>
                                                    { floatToString(item.quote.weight) }
                                                </Text>
                                            </View>
                                            <View style={[{},styles.tablaColumna7]}>
                                                <Text style={[styles.textoNegritas,{}]}>
                                                    {item.crt}
                                                </Text>
                                            </View>
                                            <View style={[{},styles.tablaColumna8]}>
                                                <Text style={[styles.tablaTexto,{}]}>
                                                    {item.quote.contact_supplier.supplier_dispatcher_name}
                                                </Text>
                                            </View>
                                        </View>
                                    )

                                }
                            )
                        }

                        <View style={[{},styles.tablaFila]}>
                            <View style={[{},styles.tablaColumna1]}>
                                <Text style={[styles.textoNegritas,{}]}>
                               
                                </Text>
                            </View>
                            <View style={[{},styles.tablaColumna2]}>
                                <Text style={[styles.textoNegritas,{}]}>
                                 
                                </Text>
                            </View>
                            <View style={[{},styles.tablaColumna3]}>
                                <Text style={[styles.textoNegritas,{}]}>
                                  
                                </Text>
                            </View>
                            <View style={[{},styles.tablaColumna4]}>
                                <Text style={[styles.textoNegritas,{}]}>
                                    {totalQuantity}
                                </Text>
                            </View>
                            <View style={[{},styles.tablaColumna5]}>
                                <Text style={[styles.textoNegritas,{}]}>
                                    {floatToString(totalVolume)}
                                </Text>
                            </View>
                            <View style={[{},styles.tablaColumna6]}>
                                <Text style={[styles.textoNegritas,{}]}>
                                {floatToString(totalWeight)}
                                </Text>
                            </View>
                            <View style={[{},styles.tablaColumna7]}>
                                <Text style={[styles.textoNegritas,{}]}>
                                {list_details.length + " PROCESOS"}
                                   
                                </Text>
                            </View>
                            <View style={[{},styles.tablaColumna8]}>
                                <Text style={[styles.tablaTexto,{}]}>
                                    
                                </Text>
                            </View>
                        </View>


                    </View>
                </Page>

        </Document>
        </PDFViewer>
        
        </>


        


    );
}

export default ConsolidatedPDF;

