import React, { useEffect, useState, useMemo } from "react";

import UserService    from '../../services/user.service';
import CustomMaterialPagination from "../paginationComponent/paginationComponent";

import DataTable from "react-data-table-component"

import LoadingSpinner from "../loading/loadingComponent";
import {Link} from "react-router-dom";
import './dataTableConsolidated.css';
const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',

};
const DataTableConsolidated = ({entSearch,numberSearch,startDateSearch,endDateSearch,setRowSelected,EditOrWatch,watchConsolidated}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [dataRow,setDataRow] = useState(
        {
            consolidated_date : "",
            frontier_date : "",
            getIn_agesa_date : "",
            getOut_agesa_date : "",
            arrive_custom_date : "",
            discharge_custom_date : "",
            observations : ""
        }
    );


    async function onSubmitDelete (e) {
        e.preventDefault();
        try{
           
            const response = await UserService.deleteConsolidated(dataRow.id);
            console.log("response", response);
                //.then((response)=>{if(response.data){console.log("Put Respond: ",response.data);setValidDescription(""); e.target.reset();} else{console.log(response.data);}});
            if(response.status === '200_OK')
            {
                console.log("Delete Respond: ",response);
              
                 reloadTable();

                
            }    
            else{
                console.log(response);
            }
            
        }catch (error){
            console.error(error);
        }

        
       
       
    }


   

    const consolidatedNumberSort = (rowA, rowB) => {
        const a = rowA.consolidate_number.toLowerCase().substring(0,rowA.consolidate_number.toLowerCase().length-5);
        const b = rowB.consolidate_number.toLowerCase().substring(0,rowB.consolidate_number.toLowerCase().length-5);
        const aInt = parseInt(a);
        const bInt = parseInt(b);
        if (aInt > bInt) {
            return -1;
        }
    
        if (bInt > aInt) {
            return 1;
        }
    
        return 0;
    };
    const customStyles = {
       
        headCells: {
            style: {
                background :    'navy',
                fontWeight: 700,
                fontSize : "2.2cap",
                color : 'white',
                opacity: 0.9
            },
        },
        
    };

    const reloadTable = () => {
        setIsLoading(true);

        UserService.getConsolidated().then(cons => {setListConsolidateds(cons);         
            setIsLoading(false)      
        }).catch( () => { setErrorMessage("No se puede obtener los datos del servidor"); setIsLoading(false); }  );
            
    }


    async function onSubmit (e) {
        e.preventDefault();
        try{
            //e.preventDefault();

            // Read the form data
            const form = e.target;
            const formData = new FormData(form);
            const formJson = Object.fromEntries(formData.entries());
        
            console.log("FormData: ",formJson)
            

          
        
            const response = await UserService.patchConsolidatedDates(
                formJson.frontier_date      === "" ? null : formJson.frontier_date,      formJson.getIn_agesa_date      === "" ? null : formJson.getIn_agesa_date,      formJson.getOut_agesa_date  === "" ? null : formJson.getOut_agesa_date,
                formJson.arrive_custom_date === "" ? null : formJson.arrive_custom_date, formJson.discharge_custom_date === "" ? null : formJson.discharge_custom_date ,formJson.observations === "" ? null : formJson.observations,dataRow.id
                );
                //.then((response)=>{if(response.data){console.log("Put Respond: ",response.data);setValidDescription(""); e.target.reset();} else{console.log(response.data);}});
            if(response.data)
            {
                console.log("Put Respond: ",response.data);
                //setValidDescription(""); 
                //e.target.reset();
                //const myModal = new bootstrap.Modal(document.getElementById('myModal'), options);
                //myModal.dispose();
                reloadTable();

            }    
            else{
                console.log(response.data);
            }
            //console.log("Quote:",quoteData);

            

        }catch (error){
            console.error(error);
        }

        
       
       
    }



    const columns1 = [
        {
            name: 'CÓDIGO',
            selector: row => row.consolidate_number.toUpperCase(),
            sortable: true,
            //width: "120px",
            sortFunction: consolidatedNumberSort
        },
        {
            name: 'FECHA',
            selector: row => row.consolidated_date,
            sortable: true,
            
            
            
        },
       
        {
            name: 'CONDUCTOR BR',
            selector: row => row.driver_br.toUpperCase(),
            sortable: true,
            //width: "100px",
            
            
        },
        {
            name: 'CONDUCTOR BO',
            selector: row => row.driver_bo.toUpperCase() ,
            sortable: true,
           
            
        },
        {
            button: true,
            width: "60px",
            cell: row => (
                <div >
                    <div className="openbtn text-center ">
                        <button
                            type="button"
                            className="btn  btn-sm"
                            data-bs-toggle ="modal"
                            data-bs-target= "#myModal"
                           
                            onClick={ () => { setDataRow(row); }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#041b5b" class="bi bi-truck" viewBox="0 0 16 16">
                                <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                                </svg>
                            
                        </button>
                    </div>
                    <div className='modal modal-lg' tabIndex="-1" id="myModal">
                        <div  className='modal-dialog'>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">CONTROL DE CONSOLIDADO</h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    ></button>
                                </div>
                                <div className='modal-body'>
                                    

                                        <div className='border rounded border-5 p-2 m-2' >   
                                            <form onSubmit={onSubmit}>
                                                <div className='m-2 input-group mx-auto '>
                                                    <span className='col-md-5 input-group-text'>Fecha de Consolidado</span>
                                                    <input 
                                                    type="date"  
                                                    
                                                    readOnly
                                                    className='col-md-7 form-control'
                                                    value={dataRow.consolidated_date === null ? "" : dataRow.consolidated_date }
                                            
                                                    />
                                                </div>
                                                <div className='m-2 input-group mx-auto '>
                                                    <span className='col-md-5 input-group-text'>Fecha llegada a Frontera</span>
                                                    <input 
                                                    type="date"  
                                                    name="frontier_date"
                                                    onChange={(e) => ( setDataRow({ ...dataRow,  frontier_date : e.target.value  }) )   }
                                                    className='col-md-7 form-control'
                                                    value={ dataRow.frontier_date === null ? "" : dataRow.frontier_date}
                                            
                                                    />
                                                </div>
                                                <div className='m-2 input-group mx-auto'>
                                                
                                                    <span className='col-md-5 input-group-text'>Fecha entrada a Agesa</span>
                                                    <input 
                                                        type="date"  
                                                        name="getIn_agesa_date"
                                                        onChange={(e) => ( setDataRow({ ...dataRow,  getIn_agesa_date : e.target.value  }) )   }
                                                        className='col-md-7 form-control'
                                                        value={dataRow.getIn_agesa_date === null ? "" : dataRow.getIn_agesa_date}
                                                
                                                    />
                                                </div>
                                                <div className='m-2 input-group mx-auto'>
                                            
                                                    <span className='col-md-5 input-group-text'>Fecha salida de Agesa</span>
                                                    <input 
                                                    type="date"  
                                                    onChange={(e) => ( setDataRow({ ...dataRow,  getOut_agesa_date : e.target.value  }) )   }
                                                    name='getOut_agesa_date'
                                                    
                                                
                                                    className='col-md-7 form-control'
                                                    value={dataRow.getOut_agesa_date === null ? "" : dataRow.getOut_agesa_date}
                                                
                                                    />
                                                </div>
                                                <div className='m-2 input-group mx-auto'>
                                                
                                                    <span className='col-md-5 input-group-text'>Fecha llegada a Albo Santa Cruz</span>
                                                    <input 
                                                        type="date"  
                                                        name="arrive_custom_date"
                                                        onChange={(e) => ( setDataRow({ ...dataRow,  arrive_custom_date : e.target.value  }) )   }
                                                        className='col-md-7 form-control'
                                                        value= {dataRow.arrive_custom_date === null ? "" : dataRow.arrive_custom_date}
                                                    
                                                    />
                                                </div>
                                                <div className='m-2 input-group mx-auto'>
                                                
                                                    <span className='col-md-5 input-group-text'>Fecha descarga en Albo Santa Cruz</span>
                                                    <input 
                                                        type="date"  
                                                        name="discharge_custom_date"
                                                        onChange={(e) => ( setDataRow({ ...dataRow,  discharge_custom_date : e.target.value  }) )   }
                                                        className='col-md-7 form-control'
                                                        value= {dataRow.discharge_custom_date === null ? "" : dataRow.discharge_custom_date}
                                                    
                                                    />
                                                </div>
                                                <div className='m-2 input-group mx-auto'>
                                                
                                                    <span className='col-md-5 input-group-text'>Observaciones</span>
                                                    <textarea 
                                                        type="text"  
                                                        name="observations"
                                                        onChange={(e) => ( setDataRow({ ...dataRow,  observations : e.target.value  }) )   }
                                                        className='col-md-7 form-control'
                                                        value= {dataRow.observations === null ? "" : dataRow.observations}
                                                    
                                                    />
                                                </div>
                                                <div className='m-2 input-group mx-auto'>
                                                
                                                    <button data-bs-dismiss="modal" className="btn btn-lg btn-secondary" style={{backgroundColor: "navy"}}  type="submit">
                                                        CONFIRMAR
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                </div>
                            </div>
    
                        </div>
                    </div>
                    
                </div>
                
                    )
        },
        {
            button: true,
            width: "60px",
            cell: row => (
                <div >
                    <div className="openbtn text-center ">
                        <button
                        type="button"
                        className="btn  btn-sm "
                        //onClick={() =>{setRowSelected(row); watchConsolidated(true);}}
                        onClick={() =>{setRowSelected(row);EditOrWatch(1);}}
                        //onClick={setIDSelected(row)}
                        //onClick={ () => {verCoti(row); console.log("row",row)}}
                        >
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#041b5b " class="bi bi-printer" viewBox="0 0 16 16">
                            <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"/>
                            <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z"/>
                            </svg>
                        </button>
                    </div>
                    
                </div>
                
                    )
        },
      
        {
            button: true,
            width: "60px",
            cell: row => (
                <div >
                    <div className="openbtn text-center ">
                        <button
                        type="button"
                        className="btn  btn-sm "
                        onClick={() =>{setRowSelected(row); EditOrWatch(2);}}
               
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#041b5b " class="bi bi-pencil-square" viewBox="0 0 16 16">
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                            <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                            </svg>
                        </button>
                    </div>
                    
                </div>
                
                    )
        },
        {
            button: true,
            width: "60px",
            cell: row => (
                <div >
                    <div className="openbtn text-center">
                        <button
                        type="button"
                        className="btn btn-danger btn-sm"
                        data-bs-toggle ="modal"
                        data-bs-target= "#myModalDelete"
                        //data-tag="allowRowEvents"
                        onClick={ () => { setDataRow(row) ;  }}
                        >
                        
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
                            </svg>
                            
                        </button>
                    </div>
                    
                    <div className='modal modal-lg' tabIndex="-1" id="myModalDelete">
                        <div  className='modal-dialog'>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title"> ¿ESTA SEGURO DE ELIMINAR EL CONSOLIDADO  {  (dataRow) &&(dataRow.consolidate_number) + " ?" }</h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    ></button>
                                </div>
                                <div className='modal-body'>
                                    

                                        <form onSubmit={onSubmitDelete}>   
                                            
                                          
                                            
                                            <div className="row m-2 p-2">
                                                <div className="col">
                                                    <button data-bs-dismiss="modal" className="btn btn-danger" type="submit"> ELIMINAR</button>
                                                    
                                                </div>
                                            </div>   
                                        </form>
                                </div>
                            </div>

                        </div>
                    </div>
   
                 </div>
                
                    )
        }
        
        

       
    ]




    const [listConsolidateds,setListConsolidateds] = useState([]);

    useEffect(() => {
        const getData = () => {
           
            setIsLoading(true);

     
            UserService.getConsolidated().then(cons => {setListConsolidateds(cons);         
            setIsLoading(false)      
        }).catch( () => { setErrorMessage("No se puede obtener los datos del servidor"); setIsLoading(false); }  );
            
           
           
            
          console.log("List",listConsolidateds);  
            
        };
        
        getData();
    }, []);

    //**************** */
    
    
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = listConsolidateds.filter(
        item => ( ((item.consolidate_number && item.consolidate_number.toLowerCase().includes(entSearch.toLowerCase())  )
        
        && ( (item.consolidated_date !== null ) && (item.consolidated_date >= startDateSearch )) && ( (item.consolidated_date !== null ) && (item.consolidated_date <= endDateSearch))
       
        ))
    );

    console.log("Lista",filteredItems);

    
    //console.log("search: ",entSearch);
    //console.log("Search1: ", nameSearch);
    //****************************** */
    //console.log("Search(out usememo):",search);


    return (
        
           

           
            <div className="row w-100">
                <div className="col mb-3 col-12 text-center">
                {//console.log(isLoading)
                }
                   { isLoading ? <LoadingSpinner/> :
                    <table className="table table-striped">
                              
                                
                                    <DataTable
                                    columns={columns1}
                                    data={listConsolidateds}
                                    pagination
                                    paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                                    
                                    noDataComponent="No hay registros"
                                    persistTableHead
                                    customStyles={customStyles}
                                    defaultSortFieldId = "1"
                                    highlightOnHover
                                    paginationPerPage={30}
                                    paginationComponentOptions={paginationComponentOptions}
                                    //paginationComponent={CustomMaterialPagination}
                                   
                                    /> 
                                

                                
                                
                               
                    </table>
                    }
                </div>
            </div>
           
        
    );
};

export default DataTableConsolidated;