import React, { useEffect, useState, useMemo } from "react";

import UserService    from '../../services/user.service';


import DataTable from "react-data-table-component"

import LoadingSpinner from "../loading/loadingComponent";
import {Link} from "react-router-dom";
//import './dataTableRequest.css'
const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',

};
const DataTableRequest = ({entSearch,nameSearch,startDateSearch,endDateSearch,setIDSelected,setEditReq,setDupliReq}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [rowData,setRowData] = useState();
    const requestNumberSort = (rowA, rowB) => {
        const a = rowA.request_number.toLowerCase().substring(0,rowA.request_number.toLowerCase().length-5);
        const b = rowB.request_number.toLowerCase().substring(0,rowB.request_number.toLowerCase().length-5);
        const aInt = parseInt(a);
        const bInt = parseInt(b);
        if (aInt > bInt) {
            return -1;
        }
    
        if (bInt > aInt) {
            return 1;
        }
    
        return 0;
    };
    const customStyles = {
       
        headCells: {
            style: {
                background :    'navy',
                fontWeight: 700,
                fontSize : "2.2cap",
                color : 'white',
                opacity: 0.9
            },
        },
        
    };
    const reloadTable = () => {
        setIsLoading(true);

     
        UserService.getRequest().then(req => {setlistRequests(req);  
            UserService.getEnterprise().then(ent=>{setlistEnterprises(ent);
                
                    setIsLoading(false);
                    console.log("Reload"); 
                
            }).catch( () => { setErrorMessage("No se puede obtener los datos del servidor"); setIsLoading(false); }  );
        }).catch( () => { setErrorMessage("No se puede obtener los datos del servidor"); setIsLoading(false); }  );
            
           

       
    }
    async function onSubmit (e) {
        e.preventDefault();
        try{
            console.log("Row: ",rowData);
           
            const response = await UserService.deleteRequest(rowData.id)
                //.then((response)=>{if(response.data){console.log("Put Respond: ",response.data);setValidDescription(""); e.target.reset();} else{console.log(response.data);}});
            if(response.status === '200_OK')
            {
                console.log("Delete Respond: ",response);
              
                 reloadTable();

                
            }    
            else{
                console.log(response);
            }
            
        }catch (error){
            console.error(error);
        }

        
       
       
    }

    const columns1 = [
        {
            name: 'CÓDIGO',
            selector: row => row.request_number.toUpperCase(),
            sortable: true,
            width: "120px",
            sortFunction: requestNumberSort
        },
        {
            name: 'EMPRESA',
            selector: row => row.ent_name,
            sortable: true,
            
            
            
        },
        {
            name: 'PROVEEDOR',
            selector: row =>  (row.supplier_selection === true) ?  (row.contact_supplier ? row.contact_supplier.supplier_name.toUpperCase() : 'SELECCIONAR PROVEEDOR') : row.exporter.toUpperCase(),
            sortable: true,
           
            
        },
        {
            name: 'PESO',
            selector: row => row.weight.toLocaleString("es-BO")+ " Kg",
            sortable: true,
            width: "100px",
            
            
        },
        {
            name: 'VOLUMEN',
            selector: row => row.volume ? row.volume.toLocaleString("es-BO")+" m3" : "sin dato",
            sortable: true,
            width: "110px",
            
            
        },
        {
            name: 'VALOR',
            selector: row => "$ "+row.value.toLocaleString("es-BO"),
            sortable: true,
            width: "100px",
            
            
        },
        {
            name: 'ORIGEN',
            selector: row => row.origin.toUpperCase(),
            sortable: true,
            
            
        },
        {
            name: 'DESTINO',
            selector: row => row.destination.toUpperCase(),
            sortable: true,
           
            
        },
       
        {
            button: true,
            width: "60px",
            cell: row => (
                <div >
                    <div className="openbtn text-center ">
                        <button
                        type="button"
                        className="btn  btn-sm "
                        onClick={() =>{setIDSelected(row); setDupliReq(true);}}
                        //onClick={setIDSelected(row)}
                        //onClick={ () => {setEditReq(true)}}
                        >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-clipboard-plus" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M8 7a.5.5 0 0 1 .5.5V9H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V10H6a.5.5 0 0 1 0-1h1.5V7.5A.5.5 0 0 1 8 7z"/>
                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                        <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                        </svg>
                        </button>
                    </div>
                    
                </div>
                
                    )
        },
       
      
        {
            button: true,
            width: "60px",
            cell: row => (
                <div >
                    <div className="openbtn text-center ">
                        <button
                        type="button"
                        className="btn  btn-sm "
                        onClick={() =>{setIDSelected(row); setEditReq(true);}}
                        //onClick={setIDSelected(row)}
                        //onClick={ () => {setEditReq(true)}}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#041b5b " class="bi bi-pencil-square" viewBox="0 0 16 16">
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                            <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                            </svg>
                        </button>
                    </div>
                    
                </div>
                
                    )
        },
        {
            button: true,
            width: "60px",
            selector: row => (
                <div >
                    <div className="openbtn text-center">
                        <button
                        type="button"
                        className="btn btn-danger btn-sm"
                        data-bs-toggle ="modal"
                        data-bs-target= "#myModal"
                        //data-tag="allowRowEvents"
                        onClick={ () => { setRowData(row) ;  }}
                        >
                           
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
                            </svg>
                            
                        </button>
                    </div>
                    
                    <div className='modal modal-lg' tabIndex="-1" id="myModal">
                        <div  className='modal-dialog'>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">¿ESTA SEGURO DE ELIMINAR LA SOLICITUD?</h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    ></button>
                                </div>
                                <div className='modal-body'>
                                    

                                        <form onSubmit={onSubmit}>   
                                            
                                            <span className='row p-2 m-2 '>{(rowData) &&(rowData.request_number.toUpperCase() + " - " + rowData.ent_name.toUpperCase()+ " - " +row.origin.toUpperCase() + " - " + row.destination.toUpperCase())}</span>
                                            <span className='row p-2 m-2'>Se eliminaran todas las cotizaciones que relacionada con esta solicitud</span>
                                            <div className="row m-2 p-2">
                                                <div className="col">
                                                    <button data-bs-dismiss="modal" className="btn btn-danger" type="submit"> ELIMINAR</button>
                                                    
                                                </div>
                                            </div>   
                                        </form>
                                </div>
                            </div>
    
                        </div>
                    </div>
                    

                        

                    
                    
                </div>
                
                    )
        }
        
        

       
    ]



    const [listEnterprises,setlistEnterprises] = useState([]);
   
    const [listRequests,setlistRequests] = useState([]);


    useEffect(() => {
        const getData = () => {
           
            setIsLoading(true);

     
        UserService.getRequest().then(req => {setlistRequests(req);  
            UserService.getEnterprise().then(ent=>{setlistEnterprises(ent);
                
                    setIsLoading(false)    
                
            }).catch( () => { setErrorMessage("No se puede obtener los datos del servidor"); setIsLoading(false); }  );
        }).catch( () => { setErrorMessage("No se puede obtener los datos del servidor"); setIsLoading(false); }  );
            
           
           
            
            
            
        };
        
        getData();
    }, []);


    const enterprisesData = useMemo(() => {
        

        let computedEnterprises = listRequests.map(client => {
            const idEnt = client;
          
            const otherRecord = listEnterprises.find(({id: candidateid}) => (candidateid===idEnt.request_contact.enterprise_id));
           
           
            return otherRecord ? {
                                 ...client,["ent_name"]:otherRecord.name,["ent_id"]:otherRecord.id
                                
                                
                                    } : client;
        });
       
        return computedEnterprises;
    }, [listEnterprises,isLoading]);

    
   
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = enterprisesData.filter(
        item => ( ((item.ent_name && item.ent_name.toLowerCase().includes(entSearch.toLowerCase())  )
        && ( (item.request_number ) && (item.request_number).toLowerCase().includes(nameSearch.toLowerCase())  )
        && ( (item.begin_date !== null ) && (item.begin_date >= startDateSearch )) && ( (item.begin_date !== null ) && (item.begin_date <= endDateSearch))
       
        ))
    );
    const filteredByDate = enterprisesData
    console.log("Lista",filteredItems);

    
    return (
        
           

           
            <div className="row w-100">
                <div className="col mb-3 col-12 text-center">
                {//console.log(isLoading)
                }
                   { isLoading ? <LoadingSpinner/> :
                    <table className="table table-striped">
                              
                                
                                    <DataTable
                                    columns={columns1}
                                    data={filteredItems}
                                    pagination
                                    paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                                    
                                    noDataComponent="No hay registros"
                                    persistTableHead
                                    customStyles={customStyles}
                                    defaultSortFieldId = "1"
                                    highlightOnHover
                                    paginationPerPage={30}
                                    paginationComponentOptions={paginationComponentOptions}
                                    /> 
                                

                                
                                
                               
                    </table>
                    }
                </div>
            </div>
           
        
    );
};

export default DataTableRequest;