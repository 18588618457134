import React, {Component,useState,useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import ServicioPDF from './servicePdf';
import './body_container_servicios.css';
import { useForm ,Controller} from 'react-hook-form';
import { ErrorMessage } from "@hookform/error-message";
import Select from 'react-select';
import FilterComponent from '../FilterComponent/filterComponent';
import UserService    from '../../services/user.service';
import DataTableService from '../data_table_servicios/dataTableServicios';
const TC = "6,96";
const IVA = 0.13;
const IT = 0.03;

function BodyContainerServicios() {


  
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user : null;
    function stringToFloat (num){
        if(num)
        {
            var numString = (num.replace(".","")).replace(",",".");
            var numFloat = parseFloat(numString);
            return numFloat ? numFloat : 0
        }
        else{
            return 0
        }
        
    }
    function floatToString (num = 0){
        if(num === null)
        {
            return "";
        }
        else
        {
            var numFormat = num.toLocaleString("es-BO") ;
            return numFormat ? numFormat : " "
            
        }
        
    }

    function capitalizeString(str = "")
    {
        if(str.length > 0)
        {
            const str2 = str.charAt(0).toUpperCase() + str.slice(1).toLocaleLowerCase();
            console.log("capitalie",str,str2);
            return str2;

        }
        else
        {
            return "";
        }
        
    }

    const [contacts,setContacts] = useState( );
    
    //arrays de las empresas(nombre,direccion) registradas en las base de datos
        //arrays de las empresas(nombre,direccion) registradas en las base de datos
    const [enterprises,setEnterprises] = useState( );
    const [quotes,setQuotes] = useState( );

    const [isLoading,setIsLoading] = useState(false);

    const [newService,setNewService] = useState(false);//estado creacion nueva solicitud
    const [editService,setEditService] = useState(false);//estado de edicion de solicitud

    const [verCotiz,setVerCotiz] = useState(false);
    const [filterTextCode,setFilterTextCode] = useState("");
    const [filterTextEnt, setFilterTextEnt] = useState("");

    const [pdfData,setPdfData] = useState()
    const { reset,getValues,control, watch ,setValue, register, handleSubmit, formState: { errors } } = useForm();

    const newServiceClick = () =>{

        setNewService(true);
        reset();
        var curr = new Date();
        curr.setDate(curr.getDate() );
        var date = curr.toISOString().substring(0,10);
        setValue("doc_date",date);
        setValue("invoice_status",'conFactura');
        setValue("dollar_exchange",TC);
        setValue("quote_parent_status",'conCotizacion');
    }; //click crear nueva solicitud

    const verCotizacion = (datos) =>
    { 
        setVerCotiz(true);
        setNewService(false);
        setEditService(false);
       //console.log("DatosPDF: ",datos);

       setPdfData(datos);

      
    }




    const serviceCodeFilterMemo = React.useMemo(() => {
        return (
            <FilterComponent onFilter={e => setFilterTextCode(e.target.value)}  filterText={filterTextCode}  name = "Codigo"/>
        );
    }, [filterTextCode]);

    const serviceEntFilterMemo = React.useMemo(() => {
        return (
            <FilterComponent onFilter={e => setFilterTextEnt(e.target.value)}  filterText={filterTextEnt}  name = "Empresa"/>
        );
    }, [filterTextEnt]);

    
    //let watchQuote;
    const watchQuote = watch("quote_parent");
    //const watchQuote = 0;
    const watchCost = watch("quotation_cost");
    const watchPricing = watch("quotation_pricing");
    const watchFactura = watch("invoice_status");
    const watchExchange = watch("dollar_exchange");
    const watchQuote_parent_status = watch("quote_parent_status");

    const [editServiceData,setServiceData] = useState();

    function updateEditServ  (servEdit) {
        console.log("Serv edit",servEdit);
        setValue("quote_parent_status",servEdit.quote_parent_status === true ? 'conCotizacion' : 'sinCotizacion'); 
        if(servEdit.quote_parent_status)
        {
            setValue('quote_parent', {label:" ",value:servEdit.quote_parent});
        }
        else
        {
           
            setValue("enterprise_name_wq",servEdit.enterprise_name_wq);
            setValue("enterprise_contact_wq",servEdit.enterprise_contact_wq);
            setValue("exporter_wq",servEdit.exporter_wq);
            setValue("weight_wq", floatToString( servEdit.weight_wq ));
            setValue("volume_wq",  floatToString( servEdit.volume_wq));
        }
        
        
        setValue("doc_date",servEdit.service_date);
        setValue("crane_status",servEdit.crane_status);
        setValue("crane",servEdit.crane);
        setValue("delivery_address",servEdit.delivery_address);
        setValue("estimated_time",floatToString(servEdit.estimated_time));
        setValue("invoice_status",servEdit.invoice_status === true ? 'conFactura' : 'sinFactura');
        setValue("forklift",servEdit.forklift);
        setValue("forklift_status",servEdit.forklift_status);
        setValue("pallet_truck",servEdit.pallet_truck);

        setValue("pallet_truck_status",servEdit.pallet_truck_status);
        setValue("quotation_cost",floatToString(servEdit.quotation_cost));
        setValue("quotation_pricing",floatToString(servEdit.quotation_pricing));
        setValue("service1_amount",servEdit.service1_amount);
        setValue("service1_descrip",servEdit.service1_descrip);
        setValue("service1_status",servEdit.service1_status);
        setValue("service2_amount",servEdit.service2_amount);
        setValue("service2_descrip",servEdit.service2_descrip);
        setValue("service2_status",servEdit.service2_status);

        setValue("stevedores",servEdit.stevedores);
        setValue("stevedores_status",servEdit.stevedores_status);
        setValue("id",servEdit.id);
        setValue("user",servEdit.user);
        setValue("dollar_exchange",floatToString(servEdit.dollar_exchange));
        setValue("observations",servEdit.observations);
        

        
       

    }
    const onError = (errors, e) => 
    {
        toast.warning("Debe llenar los campos obligatorios de rojo");

    }
    const  onSubmit = data => {
        var auxDate = getValues("doc_date").toISOString().substring(0,10);
        data.doc_date = auxDate;
        var year = auxDate.substring(0,4);
        
        //console.log("datos: ",data);
        
        if(newService)
        {
            
                UserService.getIdRequest(3).then(id => {
                    UserService.postServiceQuotation(
                                        user.id,id.id_number+ "/"+year,auxDate,data.quote_parent.value,data.forklift_status,parseInt(data.forklift),data.crane_status,parseInt(data.crane),data.stevedores_status,
                                        parseInt(data.stevedores),data.pallet_truck_status,parseInt(data.pallet_truck),data.service1_status,capitalizeString(data.service1_descrip),parseInt(data.service1_amount),data.service2_status,
                                        capitalizeString(data.service2_descrip),parseInt(data.service2_amount),stringToFloat(data.estimated_time),data.delivery_address,stringToFloat(data.quotation_pricing),stringToFloat(data.quotation_cost),stringToFloat(data.dollar_exchange),data.invoice_status === 'conFactura' ? true : false ,data.observations,
                                        data.quote_parent_status === 'conCotizacion' ? true : false,data.enterprise_name_wq,data.enterprise_contact_wq,data.exporter_wq,stringToFloat(data.weight_wq),stringToFloat(data.volume_wq)
                                        ).then((response)=>{if(response.status == 201){console.log("Post Respond: ",response.data); setNewService(false);reset();  toast.success("Cotizacion Creada Exitosamente!"); } else{console.log(response)} }).catch(  error =>{   toast.error("Error al Crear la Cotizacion "); console.log("Error: ",error)}    );
                                       
                                        } );
                
           
            
            

                                    
        }
        if(editService)
        {
                if(data.quote_parent_status === 'conCotizacion')
                {
                    UserService.putServiceQuotation(
                        data.user,data.service_number,data.service_date,data.quote_parent?.value,data.forklift_status,parseInt(data.forklift),data.crane_status,parseInt(data.crane),data.stevedores_status,
                        parseInt(data.stevedores),data.pallet_truck_status,parseInt(data.pallet_truck),data.service1_status,capitalizeString(data.service1_descrip),parseInt(data.service1_amount),data.service2_status,
                        capitalizeString(data.service2_descrip),parseInt(data.service2_amount),stringToFloat(data.estimated_time),data.delivery_address,stringToFloat(data.quotation_pricing),stringToFloat(data.quotation_cost),stringToFloat(data.dollar_exchange),data.invoice_status === 'conFactura' ? true : false , data.observations,
                        data.quote_parent_status === 'conCotizacion' ? true : false,data.enterprise_name_wq,data.enterprise_contact_wq,data.exporter_wq,stringToFloat(data.weight_wq),stringToFloat(data.volume_wq),data.id
                        ).then((response)=>{if(response.status == 200){console.log("Post Respond: ",response.data); setEditService(false);reset();toast.success("Cotizacion Editada Exitosamente!");} }).catch(  error =>{   toast.error("Error al editar la Cotizacion "); console.log("Error: ",error)}    );
                    
                }
                else
                {
                    UserService.putServiceQuotation(
                        data.user,data.service_number,data.service_date,null,data.forklift_status,parseInt(data.forklift),data.crane_status,parseInt(data.crane),data.stevedores_status,
                        parseInt(data.stevedores),data.pallet_truck_status,parseInt(data.pallet_truck),data.service1_status,capitalizeString(data.service1_descrip),parseInt(data.service1_amount),data.service2_status,
                        capitalizeString(data.service2_descrip),parseInt(data.service2_amount),stringToFloat(data.estimated_time),data.delivery_address,stringToFloat(data.quotation_pricing),stringToFloat(data.quotation_cost),stringToFloat(data.dollar_exchange),data.invoice_status === 'conFactura' ? true : false , data.observations,
                        data.quote_parent_status === 'conCotizacion' ? true : false,data.enterprise_name_wq,data.enterprise_contact_wq,data.exporter_wq,stringToFloat(data.weight_wq),stringToFloat(data.volume_wq),data.id
                        ).then((response)=>{if(response.status == 200){console.log("Post Respond: ",response.data); setEditService(false);reset();toast.success("Cotizacion Editada Exitosamente!");} }).catch(  error =>{   toast.error("Error al editar la Cotizacion "); console.log("Error: ",error)}    );
                    
               
                }
                
            
            
               

        }
       
        
    }
    console.log("quotes",quotes);
    var quoTemp;
    
    if(   watchQuote && !quoTemp )
    {
        console.log("actulizado",watchQuote);
        (quoTemp =  quotes.find(ob => {return ob.id === watchQuote.value}) );
        console.log("actulizado",quoTemp);
        setValue("enterprise",contacts.find(ob =>{return ob.id === quoTemp.request_parent.request_contact.contact_id} ).enterprise.enterprise_name );

        setValue("enterprise_contact",quoTemp.request_parent.request_contact.name + " "+ quoTemp.request_parent.request_contact.last_name);
       
        setValue("exporter",quoTemp.request_parent.contact_supplier ? quoTemp.request_parent.contact_supplier.supplier_name.toUpperCase() : 'SELECCIONAR PROVEEDOR' );
        setValue("weight",floatToString(quoTemp.request_parent.weight));
        setValue("volume",floatToString(quoTemp.request_parent.volume));


    }

    /*
    React.useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            //console.log("watch: ",value, name, type); 
          
        });
       
        return () => subscription.unsubscribe();
      }, [watch]);
    */
    
    

      const [errorMessage,setErrorMessage] = useState([]);
 





  

    let costoDol = 0;
    let comisionDol = 0;
    let impuestosDol = 0;
    var filterContacts = [];
    //( watchEnterp)&&( filterContacts = contacts.filter(item => (item.enterprise.enterprise_id === watchEnterp.value)));

    (newService || editService) &&( watchCost || watchExchange )&&( 
        costoDol =( Math.round(100* (stringToFloat( getValues("quotation_cost"))/ stringToFloat(getValues("dollar_exchange")))  )/100.0  )
    );

    (newService || editService) &&( watchCost || watchPricing)&&( 
        impuestosDol = (watchFactura === 'conFactura') ? ( Math.round(100*(stringToFloat( getValues("quotation_pricing"))* (IT+IVA) -   costoDol*(IVA)    ) )/100.0 ) :  ( Math.round(100*(stringToFloat( getValues("quotation_pricing"))* (IT+IVA)   ) )/100.0 )
    );


    (newService || editService) &&( watchCost || watchPricing)&&( 
        comisionDol =  ( Math.round(100* (stringToFloat( getValues("quotation_pricing")) - (impuestosDol + costoDol) ) )/100.0  )
    );


    const [startDate, setStartDate] = useState("2000-01-01");
    const [endDate, setEndDate] = useState("3000-01-01");
    const onChangeStartDate = (e) =>{
        setStartDate(e.target.value);
    }
    const onChangeEndDate = (e) =>{
        setEndDate(e.target.value);
    }
    console.log("errors",errors);
   
    const pdfName = pdfData ?   ("Coti" + pdfData.service_number.substring(0,pdfData.service_number.length-5)+"-"+ pdfData.service_number.substring(pdfData.service_number.length-4) +"-"+   
    pdfData.quote_parent_status ? (pdfData.enterprise_contact ? pdfData.enterprise_contact.enterprise.enterprise_name : " "): pdfData.enterprise_name_wq  +"-"+
                                pdfData.quote_parent_status ? (pdfData.contact_supplier ? pdfData.contact_supplier.supplier_name.toUpperCase() : 'SELECCIONAR PROVEEDOR' ) :pdfData.exporter_wq  +"-"+
                                pdfData.quote_parent_status ? (floatToString(pdfData.weight)) : (floatToString(pdfData.weight_wq))   +"Kgs-"+
                                pdfData.quote_parent_status ? (floatToString(pdfData.volume)) : (floatToString(pdfData.volume_wq))  +"M3-"+
                                pdfData.delivery_address  +".pdf")  : "Mal"                    ;
    
    console.log("pdfName: ",pdfName);
    useEffect(()=>{ 
        const getData = () => {
        setIsLoading(true);    
        UserService.getContact().then(cont => {setContacts(cont); }).catch( () => {setErrorMessage("No se puede obtener los datos del servidor");} );
        //UserService.getEnterprise().then(ent => {setEnterprises(ent);}).catch( () => {setErrorMessage("No se puede obtener los datos del servidor");} );        
        UserService.getQuotes().then(quo => {setQuotes(quo); setIsLoading(false);}).catch( () => {setErrorMessage("No se puede obtener los datos del servidor");} );   
       
    };
    getData();    
    },[]); 

    console.log("editServiceData: ",editServiceData);
    React.useEffect( () => {
        console.log("UseEffect Edit");
        return (
            editServiceData && (updateEditServ(editServiceData))
        );
    },[editServiceData]
    );

    return ( 
       
        <div  className='BodyContainer container-fluid vh-100'>
            
            <div className='row'>

                <div id= 'sidebarMenu' className='col-2 bg-light vh-100 '>

                    <div className="mt-5 vh-100" >
                        
                       {/* <p>{counter}</p>*/  }  
                       <div className="p-3">
                            {serviceCodeFilterMemo}
                        </div>
                        <div className="p-3">
                            {serviceEntFilterMemo}
                        </div>
                        
                        <div className="p-3">
                            <label for="">Fecha de Inicio</label>
                            <input 
                                type="date" 
                                className="form-control" 
                                onChange={onChangeStartDate} 
                            />
                        </div>
                        <div className="p-3">
                            <label for="">Fecha de Fin</label>
                            <input 
                                type="date" 
                                className="form-control" 
                                placeholder="Fecha Fin" 
                                onChange={onChangeEndDate}
                                />
                        </div>
                        
                        
                    </div>
                </div>

                <div className='col-10'>
                    <div>
                    <ToastContainer  
                        position="bottom-center"
                        autoClose={2500}
                        theme= "light"
                    />
                    </div>

                    { !(newService || editService || verCotiz)  && (

                        <div className='container-fluid h-100'>
                            <div className='row'>

                                <div className="col-11 p-3">
                                    
                                    <h2 className="text-center">LISTA DE COTIZACIONES DE SERVICIO</h2>
                                    
                                </div>
                                <div className="col-1">
                                    <div className="m-2 ">
                                        <button type="button" className="btn " disabled = {isLoading} onClick={newServiceClick}>
                                            
                                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                            </svg>

                                        </button>
                                    </div>
                                    
                                </div>
                            </div>

                            <div className='row h-100' >
                            {
                                
                                <DataTableService
                                    entSearch = {filterTextEnt}
                                    numberSearch = {filterTextCode}
                                    startDateSearch={startDate}
                                    endDateSearch={endDate}
                                    setIDSelected = {setServiceData}
                                    setEditServ = {setEditService}
                                    verCoti={verCotizacion}
                                /> 
                         
                            }

                             
                              
                            
                           
                            
                            
                            </div>
                            

                        </div>
                        )
                    }

                    {
                        (verCotiz) && (
                            <div className='row ' style={{ height : "100%"}}>
                                <div className='row '>

                                    <div className='col'>
                                        <button className='btn' type = "button" onClick={ () => {setVerCotiz(false);}}>
                                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.5 280.6l192 160c20.6 17.2 52.5 2.8 52.5-24.6V96c0-27.4-31.9-41.8-52.5-24.6l-192 160c-15.3 12.8-15.3 36.4 0 49.2zm256 0l192 160c20.6 17.2 52.5 2.8 52.5-24.6V96c0-27.4-31.9-41.8-52.5-24.6l-192 160c-15.3 12.8-15.3 36.4 0 49.2z"></path>
                                            </svg>
                                        </button>
                                    </div>
                                    <div className='col-10'>
                                       
                                    </div>
                                </div>
                                <div className='row' >
                                    <div className='m-1' >
                                                <button className="btn btn-lg btn-secondary" style={{backgroundColor: "navy"}} onClick={() => {navigator.clipboard.writeText(pdfName ); toast.info("Nombre Copiado en el Portapeles ")}}>
                                                    GENERAR NOMBRE DEL DOCUMENTO 
                                                </button>
                                            </div>
                                </div>
                                <div className='row justify-content-md-center 'style={{ height : "100%"}} >
                                    <ServicioPDF
                                        quote = {pdfData}
                                    />
                                </div>
                                
                                
                            </div>
                        )
                    }
                          
                    { (newService || editService) && (
                            <div>

                                <div className='m-4 row '>
                                    <div className='col'> 
                                        <button className='btn' type = "button"  onClick={()=>{setNewService(false); setEditService(false)}}>
                                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.5 280.6l192 160c20.6 17.2 52.5 2.8 52.5-24.6V96c0-27.4-31.9-41.8-52.5-24.6l-192 160c-15.3 12.8-15.3 36.4 0 49.2zm256 0l192 160c20.6 17.2 52.5 2.8 52.5-24.6V96c0-27.4-31.9-41.8-52.5-24.6l-192 160c-15.3 12.8-15.3 36.4 0 49.2z"></path>
                                            </svg>
                                        </button>   
                                    </div>
                                    {(newService) &&
                                        <div  className='col-10'>
                                            <h1 >
                                                INGRESE UNA NUEVA COTIZACION DE SERVICIO
                                            </h1>
                                        </div>}
                                    {(editService) &&
                                        <div  className='col-10'>
                                            <h1 >
                                                MODIFICAR COTIZACION DE SERVICIO
                                            </h1>
                                        </div>}
                                        
                                    <div className='col-1'>
                                            <button type="button" className="btn " >
                                                
                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill="none" stroke="#000" stroke-width="2" d="M1.7507,16.0022 C3.3517,20.0982 7.3367,23.0002 11.9997,23.0002 C18.0747,23.0002 22.9997,18.0752 22.9997,12.0002 M22.2497,7.9982 C20.6487,3.9012 16.6627,1.0002 11.9997,1.0002 C5.9247,1.0002 0.9997,5.9252 0.9997,12.0002 M8.9997,16.0002 L0.9997,16.0002 L0.9997,24.0002 M22.9997,0.0002 L22.9997,8.0002 L14.9997,8.0002">
                                                        </path>
                                                </svg>
            
            
                                            </button>
                                    </div>

                                    
                                </div>
                                
                                <div >
                                <form className='m-5 row justify-content-md-center' onSubmit={ handleSubmit(onSubmit,onError)}>

                                        
                                        <div className='row justify-content-md-cemter'>
                                            <div className='row '>
                                                {
                                                    (newService === true && (watchQuote_parent_status === 'conCotizacion')) && (
                                                        
                                                        <Controller
                                                        name="quote_parent"
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                options = {(quotes.length !== 0)?  quotes.map((option) => ({ label : option.quote_number + " | "+ 
                                                                                                                                        contacts.find(ob =>{return ob.id === option.request_parent.request_contact.contact_id} ).enterprise.enterprise_name + " | " +
                                                                                                                                        (option.request_parent.contact_supplier ? option.request_parent.contact_supplier.supplier_name.toUpperCase() : 'SELECCIONAR PROVEEDOR')+ " | "+
                                                                                                                                        option.request_parent.weight + "Kgs | "+
                                                                                                                                        option.request_parent.volume + "m3 | "+
                                                                                                                                        option.request_parent.value + "$ | "
                                                                                                                                        
                                                                                                                                        , value: option.id })) : ([])}
                                                                className = "col m-3 "
                                                               
                                                            />
                                                        )}
                                                        control={control}
                                                        defaultValue=""
                                                       
                                                        rules={{ required: true }}
                                                        />
                                                            )
                                                }
                                                
                                                
                                            </div>
                                        </div>
                                        <div className='row  justify-content-md-center'>
                                            

                                            

                                            <div className='col col-5 '>

                                                {
                                                    (newService) && (

                                                    <div className=' m-2 input-group'>
                                                        <div className='input-group-text  '>
                                                            <input 
                                                                className="form-check-input mt-0"
                                                                {...register("quote_parent_status", { required: false })} 
                                                                type="radio"  
                                                                value={'conCotizacion'}
                                                            />
                                                        </div>
                                                        <span className=' input-group-text col-5' > CON COTIZACION PREVIA</span>
                                                        
                                                        <div className='input-group-text  '>
                                                            <input 
                                                                className="form-check-input mt-0" 
                                                                {...register("quote_parent_status", { required: false })} 
                                                                type="radio"  
                                                                value={'sinCotizacion'}
                                                            />
                                                        </div>
                                                        <span className=' input-group-text col-5' > SIN COTIZACION PREVIA</span>
                                            
                                                    </div>
                                                    )
                                                }
                                                



                                                <div  className='m-2 input-group'>
                                                    <span className=' input-group-text col-md-3' > FECHA</span>
                                                    <input type="date"   className='col-md-7 form-control' placeholder='Fecha' {...register("doc_date",{required: true,valueAsDate: true,})}  />
                                                </div>
                                            
                                                {
                                                    /*
                                                    <div className='m-2 input-group'>
                                                        <span className=' input-group-text col-md-3' > EMPRESA</span>
                                                        <Controller
                                                            name="enterprise"
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    options = {(enterprises.length !== 0)?  enterprises.map((option) => ({ label : option.name, value: option.id })) : ([])}
                                                        
                                                                    className = "col"
                                                                
                                                                />
                                                            )}
                                                            control={control}
                                                            defaultValue=""
                                                            rules={{ required: true }}
                                                        />
                                                    </div>
                                                    */ 
                                                }
                                                <div  className='m-2 input-group'>
                                                    <span className=' input-group-text col-md-3' > EMPRESA</span>
                                                    {
                                                        (watchQuote_parent_status === 'conCotizacion') && ( <input type="text"   className='col-md-7 form-control '  disabled placeholder='' {...register("enterprise",{required: true})}  /> )
                                                    }
                                                    {
                                                        (watchQuote_parent_status === 'sinCotizacion') && ( <input type="text"   className='col-md-7 form-control '   placeholder='' {...register("enterprise_name_wq",{required: true})}  /> )
                                                    }
                                                </div>
                                                
                                                {
                                                    /*
                                                    <div className='m-2 input-group'>
                                                        
                                                        <span className=' input-group-text col-md-4 text-wrap' >CLIENTE</span>
                                                        <Controller
                                                            name="enterprise_contact"
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    options = {(filterContacts.length !== 0)?  filterContacts.map((option) => ( ({ label : option.name_contact + " " + option.last_name_contact  , value: option.id })   )) : ([])}
                                                                    
                                                                    className = "col "
                                                                
                                                                />
                                                            )}
                                                            control={control}
                                                            defaultValue=""
                                                            rules={{ required: true }}
                                                        />
                                                    </div>
                                                     */
                                                }
                                                <div  className='m-2 input-group'>
                                                    <span className=' input-group-text col-md-3' > CLIENTE</span>
                                                    {
                                                        (watchQuote_parent_status === 'conCotizacion') && ( <input type="text"   className='col-md-7 form-control ' disabled placeholder='' {...register("enterprise_contact",{required: true})}  /> )
                                                    }
                                                    {
                                                        (watchQuote_parent_status === 'sinCotizacion') && ( <input type="text"   className='col-md-7 form-control '  placeholder='' {...register("enterprise_contact_wq",{required: true})}  /> )
                                                    }
                                                    
                                                </div>
                                                
                                                <div  className='m-2 input-group'>
                                                    <span className=' input-group-text col-md-3' > PROVEEDOR</span>
                                                    {
                                                        (watchQuote_parent_status === 'conCotizacion') && ( <input type="text"   className='col-md-7 form-control ' disabled placeholder='' {...register("exporter",{required: true})}  />)
                                                    }
                                                    {
                                                        (watchQuote_parent_status === 'sinCotizacion') && ( <input type="text"   className='col-md-7 form-control '  placeholder='' {...register("exporter_wq",{required: true})}  />)
                                                    }
                                                    
                                                </div>

                                                <div  className='m-2 input-group'>
                                                    <span className=' input-group-text col-md-3' > PESO</span>
                                                    {
                                                        (watchQuote_parent_status === 'conCotizacion') && ( <input type="text"  className='form-control ' disabled placeholder='' {...register("weight",{required: true})}  />)
                                                    }
                                                    {
                                                        (watchQuote_parent_status === 'sinCotizacion') && ( <input type="text"  className='form-control '  placeholder='' {...register("weight_wq",{required: true})}  />)
                                                    }
                                                    
                                                    <span className=' input-group-text ' > KG</span>
                                                    {/*
                                                        <select className='input-group-text' {...register("requestUnitWeight",{required: true})}> 
                                                                <option value="KG">KG</option>
                                                                <option defaultValue="TON">TON</option>
                                                                <option value="LIB">LIB</option>
                                                        </select>*/
                                                    }
                                                    
                                                </div>

                                                <div  className='m-2 input-group'>
                                                    <span className=' input-group-text col-md-3' > VOLUMEN</span>
                                                    {
                                                        (watchQuote_parent_status === 'conCotizacion') && ( <input type="text"   className='form-control' disabled placeholder='' {...register("volume",{required: true})}  />)
                                                    }
                                                    {
                                                        (watchQuote_parent_status === 'sinCotizacion') && ( <input type="text"   className='form-control'  placeholder='' {...register("volume_wq",{required: true})}  />)
                                                    }
                                                    
                                                    <span className=' input-group-text' > m3</span>
                                                    {/*
                                                    <select className='input-group-text' {...register("requestUnitVolume",{required: true})}> 
                                                            <option defaultValue="m3">m3</option>
                                                            <option value="cm3">cm3</option>
                                                            <option value="pulg3">pulg3</option>
                                                    </select>*/
                                                    }
                                                </div>
                                                

                                                <div className='m-2 input-group'>
                                                        <div className='input-group-text  '>
                                                            <input 
                                                                className="form-check-input mt-0" 
                                                                {...register("forklift_status", { required: false })} 
                                                                type="checkbox"  
                                                            />
                                                        </div>
                                                        <span className=' input-group-text col-9' > MONTACARGA</span>
                                                        <input 
                                                            type="text"   
                                                            className='form-control' 
                                                            placeholder='' 
                                                            {...register("forklift",{required: false})}  
                                                            defaultValue={0}
                                                        />
                                            
                                                </div>

                                                <div className='m-2 input-group'>
                                                        <div className='input-group-text  '>
                                                            <input 
                                                                className="form-check-input mt-0" 
                                                                {...register("crane_status", { required: false })} 
                                                                type="checkbox"  
                                                            />
                                                        </div>
                                                        <span className=' input-group-text col-9' > GRÚA</span>
                                                        <input 
                                                            type="text"   
                                                            className='form-control ' 
                                                            placeholder='' 
                                                            {...register("crane",{required: false})}  
                                                            defaultValue={0}
                                                        />
                                            
                                                </div>

                                                <div className='m-2 input-group'>
                                                        <div className='input-group-text  '>
                                                            <input 
                                                                className="form-check-input mt-0" 
                                                                {...register("stevedores_status", { required: false })} 
                                                                type="checkbox"  
                                                            />
                                                        </div>
                                                        <span className=' input-group-text col-9' > ESTIBADORES</span>
                                                        <input 
                                                            type="text"   
                                                            className='form-control ' 
                                                            placeholder='' 
                                                            {...register("stevedores",{required: false})}  
                                                            defaultValue={0}
                                                        />
                                            
                                                </div>

                                                <div className='m-2 input-group'>
                                                        <div className='input-group-text  '>
                                                            <input 
                                                                className="form-check-input mt-0" 
                                                                {...register("pallet_truck_status", { required: false })} 
                                                                type="checkbox"  
                                                            />
                                                        </div>
                                                        <span className=' input-group-text col-9' > CARRO HIDRAULICO</span>
                                                        <input 
                                                            type="text"   
                                                            className='form-control ' 
                                                            placeholder='' 
                                                            {...register("pallet_truck",{required: false})}  
                                                            defaultValue={0}
                                                        />
                                            
                                                </div>


                                                <div className='m-2 input-group'>
                                                        <div className='input-group-text  '>
                                                            <input 
                                                                className="form-check-input mt-0" 
                                                                {...register("service1_status", { required: false })} 
                                                                type="checkbox"  
                                                            />
                                                        </div>
                                                        <input 
                                                            type="text"   
                                                            style={{textAlign : "left"}}
                                                            className='input-group-text col-9 ' 
                                                            placeholder='' 
                                                            {...register("service1_descrip",{required: false})}  
                                                            defaultValue={" "}
                                                        />
                                                        <input 
                                                            type="text"   
                                                            className='form-control ' 
                                                            placeholder='' 
                                                            {...register("service1_amount",{required: false})}  
                                                            defaultValue={0}
                                                        />
                                            
                                                </div>

                                                <div className='m-2 input-group'>
                                                        <div className='input-group-text  '>
                                                            <input 
                                                                className="form-check-input mt-0" 
                                                                {...register("service2_status", { required: false })} 
                                                                type="checkbox"  
                                                            />
                                                        </div>
                                                        <input 
                                                            type="text"   
                                                            style={{textAlign : "left"}}
                                                            className='input-group-text col-9 '  
                                                            placeholder='' 
                                                            {...register("service2_descrip",{required: false})}  
                                                            defaultValue={" "}
                                                        />
                                                        <input 
                                                            type="text"   
                                                            className='form-control ' 
                                                            placeholder='' 
                                                            {...register("service2_amount",{required: false})}  
                                                            defaultValue={0}
                                                        />
                                            
                                                </div>

                                                <div  className='m-2 input-group'>
                                                    <span className=' input-group-text col-md-3' > TIEMPO ESTIMADO</span>
                                                    <input type="text"   className='form-control ' placeholder='' style={{backgroundColor: errors.estimated_time ? '#f5dce2' : '' }} {...register("estimated_time",{required: "Este dato es obligatorio"})} aria-invalid={errors.estimated_time ? "true" : "false" }  />
                                                    <span className=' input-group-text '>Hrs.</span>
                                                </div>
                                               

                                                <div  className='m-2 input-group'>
                                                    <span className=' input-group-text col-md-3' > DIRECCION</span>
                                                    <input type="text"   className='col-md-7 form-control ' placeholder='' style={{backgroundColor: errors.delivery_address ? '#f5dce2' : '' }} {...register("delivery_address",{required: true})}  />
                                                </div>

                                                <div  className='m-2 input-group'>
                                                    <span className=' input-group-text col-md-3' > OBSERVACIONES</span>
                                                    <input type="text"   className='col-md-7 form-control ' placeholder='' {...register("observations",{required: false})}  />
                                                </div>

                                                <div className='m-2 input-group'>
                                                        <div className='input-group-text  '>
                                                            <input 
                                                                className="form-check-input mt-0"
                                                                {...register("invoice_status", { required: false })} 
                                                                type="radio"  
                                                                value={'conFactura'}
                                                            />
                                                        </div>
                                                        <span className=' input-group-text col-5' > CON FACTURA</span>
                                                        
                                                        <div className='input-group-text  '>
                                                            <input 
                                                                className="form-check-input mt-0" 
                                                                {...register("invoice_status", { required: false })} 
                                                                type="radio"  
                                                                value={'sinFactura'}
                                                            />
                                                        </div>
                                                        <span className=' input-group-text col-5' > SIN FACTURA</span>
                                            
                                                </div>

                                                <div  className='m-2 input-group'>
                                                    <span className=' input-group-text col-md-3' > COSTO</span>
                                                    <input type="text"   className='form-control ' placeholder='' style={{backgroundColor: errors.quotation_cost ? '#f5dce2' : '' }} {...register("quotation_cost",{required: true})}  />
                                                    <span className=' input-group-text '>Bs</span>
                                                </div>


                                                <div  className='m-2 input-group'>
                                                    <span className=' input-group-text col-md-3' > PRECIO</span>
                                                    <input type="text"   className='form-control ' placeholder='' style={{backgroundColor: errors.quotation_pricing ? '#f5dce2' : '' }} {...register("quotation_pricing",{required: true})}  />
                                                    <span className=' input-group-text '>$us</span>
                                                </div>

                                                <div  className='m-2 input-group'>
                                                    <span className=' input-group-text col-md-3' > TIPO  DE CAMBIO</span>
                                                    <input type="text"   className='form-control ' placeholder='' style={{backgroundColor: errors.dollar_exchange ? '#f5dce2' : '' }} {...register("dollar_exchange",{required: true})}  />
                                                    <span className=' input-group-text '>Bs./$us</span>
                                                </div>

                                                <div  className='m-2 input-group'>
                                                    <span className=' input-group-text col-md-3' > COSTO</span>
                                                    <input type="text"   className='form-control ' placeholder='' readOnly value={floatToString(costoDol)} />
                                                    <span className=' input-group-text '>$us</span>
                                                </div>
                                                
                                                <div  className='m-2 input-group'>
                                                    <span className=' input-group-text col-md-3' > IMPUESTOS</span>
                                                    <input type="text"   className='form-control ' placeholder='' readOnly value = {floatToString(impuestosDol)}/>
                                                    <span className=' input-group-text '>$us</span>
                                                </div>

                                                <div  className='m-2 input-group'>
                                                    <span className=' input-group-text col-md-3' > COMISIÓN</span>
                                                    <input type="text"   className='form-control ' placeholder='' readOnly value= {floatToString(comisionDol)} />
                                                    <span className=' input-group-text '>$us</span>
                                                </div>

                                                {
                                                    (newService) && <button className='m-2 col-md-12'  type="submit">CREAR COTIZACION DE SERVICIO</button>
                                                }
                                                {
                                                    (editService) && <button className='m-2 col-md-12' type="submit">ACTUALIZAR COTIZACION DE SERVICIO</button>
                                                }
                                            </div>
                                        </div>
                                   
                                    
                                    
                                    
                                    

                                    
                                       
                                        
                                    
                                        
                                    
                                </form>

                                </div>

                            </div>
                        )
                    }


                </div>

            </div>
            
        </div>

    );
}
export default BodyContainerServicios
