import logo from './logo.svg';
import './App.css';
import LoginForm from './views/login/login_container';
import BarContainer from './components/bar/bar_container';
import BodyContainer from './components/body/body_container';
import BodyContainerCotizaciones from './components/container_cotizaciones/body_container_cotizacion';
import BodyContainerClientes from './components/container_clientes/body_container_clientes';
import BodyContainerServicios from './components/container_servicios/body_container_servicios';
import BodyContainerSolicitudes from './components/container_solicitudes/body_container_solicitudes';
import BodyContainerOtros from './components/container_otros/body_container_otros';
import BodyContainerDrivers from './components/container_drivers/body_container_drivers';
import BodyContainerTransporter from './components/container_transporters/body_container_transporters';
import AuthService from './services/auth.service';
import BodyContainerConsolidados from './components/container_consolidados/body_container_consolidados';
import React, {useState} from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import BodyContainerContactsSuppliers from './components/container_proveedores/body_container_proveedores';




const  App =() => {
  //authHeader.getCurrentUser();
  let us = AuthService.getCurrentUser();
  const [isLogin, setIsLogin] = useState(false);
  if(us.hasOwnProperty("groups") === false)
  {
    us= false;
    console.log("Sin grupos")
  }

  if(!us) {return  <LoginForm />;}
 
  console.log("User", us);
  
  return (
    
    <div className="App vh-100">
        
        <header>
          <BarContainer      />

        </header>
        
        <main>
          
           
            <Routes>
              {
                 ( us.groups.find(obj => (obj === 1 || obj === 2 || obj === 3) )) && (  <Route exact path="/" element={<BodyContainer/>}/> )
                
              }
              {
                (us.groups.find(obj => (obj === 1 || obj === 3))) && ( <Route exact path="/cotizaciones" element={<BodyContainerCotizaciones/>}/> )
                
              }
              {
                (us.groups.find(obj => (obj === 1 || obj === 3))) && ( <Route exact path="/consolidados" element={<BodyContainerConsolidados/>}/> )
                
              }
              {
                (us.groups.find(obj => (obj === 1 || obj === 3))) && ( <Route exact path="/clientes" element={<BodyContainerClientes />}/>  )
                
              }
              {
               (us.groups.find(obj => (obj === 1 || obj === 3))) && ( <Route exact path="/servicios" element={<BodyContainerServicios />}/> )
                
              }
              {
               (us.groups.find(obj => (obj === 1 || obj === 3))) && ( <Route exact path="/solicitudes" element={<BodyContainerSolicitudes />}/>     )
                
              }
              {
                (us.groups.find(obj => (obj === 1))) && ( <Route exact path="/otros" element={<BodyContainerOtros />}/> )
                
              }
              {
                (us.groups.find(obj => (obj === 1 || obj === 2))) && ( <Route exact path="/proveedores" element={<BodyContainerContactsSuppliers />}/> )
                
              }
              {
                (us.groups.find(obj => (obj === 1 || obj === 2))) && ( <Route exact path="/driver" element={<BodyContainerDrivers />}/> )
                
              }
              {
                (us.groups.find(obj => (obj === 1 || obj === 2))) && ( <Route exact path="/transporter" element={<BodyContainerTransporter />}/> )
                
              }
              
              
              
              
              
              
              
            </Routes>
          
        </main>
        


      
      
      
       
        
    </div>

    
  );
}

export default App;
