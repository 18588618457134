import React, { useEffect, useState, useMemo } from "react";

import UserService    from '../../services/user.service';
import CustomMaterialPagination from "../paginationComponent/paginationComponent";

import DataTable from "react-data-table-component"

import LoadingSpinner from "../loading/loadingComponent";
import {Link} from "react-router-dom";
import './dataTableServicios.css';
const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',

};
const DataTableService = ({entSearch,numberSearch,startDateSearch,endDateSearch,setIDSelected,setEditServ,verCoti}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    
    const serviceNumberSort = (rowA, rowB) => {
        const a = rowA.service_number.toLowerCase().substring(0,rowA.service_number.toLowerCase().length-5);
        const b = rowB.service_number.toLowerCase().substring(0,rowB.service_number.toLowerCase().length-5);
        const aInt = parseInt(a);
        const bInt = parseInt(b);
        if (aInt > bInt) {
            return -1;
        }
    
        if (bInt > aInt) {
            return 1;
        }
    
        return 0;
    };
    const customStyles = {
       
        headCells: {
            style: {
                background :    'navy',
                fontWeight: 700,
                fontSize : "2.2cap",
                color : 'white',
                opacity: 0.9
            },
        },
        
    };
    const columns1 = [
        {
            name: 'CÓDIGO',
            selector: row => row.service_number.toUpperCase(),
            sortable: true,
            width: "120px",
            sortFunction: serviceNumberSort
        },
        {
            name: 'EMPRESA',
            selector: row => row.quote_parent_status ? row.enterprise_contact?.enterprise.enterprise_name.toUpperCase() : row.enterprise_name_wq?.toUpperCase(),
            sortable: true,
            
            
            
        },
        {
            name: 'PROVEEDOR',
            selector: row =>  row.quote_parent_status ? (row.contact_supplier ? row.contact_supplier.supplier_name.toUpperCase() : 'SELECCIONAR PROVEEDOR') : row.exporter_wq?.toUpperCase(),
            sortable: true,
           
            
        },
        {
            name: 'PESO',
            selector: row => row.quote_parent_status ? (row.weight.toLocaleString("es-BO") ) : row.weight_wq?.toLocaleString("es-BO")  + " Kg",
            sortable: true,
            width: "100px",
            
            
        },
        {
            name: 'VOLUMEN',
            selector: row => row.quote_parent_status ?  (row.volume ? row.volume.toLocaleString("es-BO") +" m3" : "sin dato" ) : row.volume_wq?.toLocaleString("es-BO") +" m3",
            sortable: true,
            width: "110px",
            
            
        },
        {
            name: 'PRECIO',
            selector: row => "$us "+row.quotation_pricing.toLocaleString("es-BO"),
            sortable: true,
            width: "100px",
            
            
        },
        {
            name: 'DIRECCION',
            selector: row => row.delivery_address.toUpperCase(),
            sortable: true,
            
            
        },
       
        {
            button: true,
            width: "60px",
            cell: row => (
                <div >
                    <div className="openbtn text-center ">
                        <button
                        type="button"
                        className="btn  btn-sm "
                        //onClick={() =>{setIDSelected(row); verCoti(true);}}
                        //onClick={setIDSelected(row)}
                        onClick={ () => {verCoti(row); console.log("row",row)}}
                        >
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#041b5b " class="bi bi-printer" viewBox="0 0 16 16">
                            <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"/>
                            <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z"/>
                            </svg>
                        </button>
                    </div>
                    
                </div>
                
                    )
        },
      
        {
            button: true,
            width: "60px",
            cell: row => (
                <div >
                    <div className="openbtn text-center ">
                        <button
                        type="button"
                        className="btn  btn-sm "
                        onClick={() =>{setIDSelected(row); setEditServ(true);}}
               
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#041b5b " class="bi bi-pencil-square" viewBox="0 0 16 16">
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                            <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                            </svg>
                        </button>
                    </div>
                    
                </div>
                
                    )
        },
        {
            button: true,
            width: "60px",
            cell: row => (
                <div >
                    <div className="openbtn text-center ">
                        <button
                        type="button"
                        className="btn btn-danger btn-sm"
                        //onClick={() =>{setIDSelected(row); setEditReq(true);}}
                        //onClick={setIDSelected(row)}
                        //onClick={ () => {setEditReq(true)}}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
                            </svg>
                        </button>
                    </div>
                    
                </div>
                
                    )
        }
        
        

       
    ]




    const [listServices,setListServices] = useState([]);

    useEffect(() => {
        const getData = () => {
           
            setIsLoading(true);

     
        UserService.getServiceQuotation().then(serv => {setListServices(serv);         
            setIsLoading(false)      
        }).catch( () => { setErrorMessage("No se puede obtener los datos del servidor"); setIsLoading(false); }  );
            
           
           
            
            
            
        };
        
        getData();
    }, []);

    //**************** */
    
    //Arreglar wl filtro ára cuando no tiene cotizacion
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    /* 
    const filteredItems =  listServices.filter(
        item => (

             ((item.enterprise_contact.enterprise.enterprise_name && item.enterprise_contact.enterprise.enterprise_name.toLowerCase().includes(entSearch.toLowerCase())  )
        && ( (item.service_number ) && (item.service_number).toLowerCase().includes(numberSearch.toLowerCase())  )
        && ( (item.service_date !== null ) && (item.service_date >= startDateSearch )) && ( (item.service_date !== null ) && (item.service_date <= endDateSearch))  
        )
        )
    );
    */
    

    //console.log("Lista",filteredItems);

    
    //console.log("search: ",entSearch);
    //console.log("Search1: ", nameSearch);
    //****************************** */
    //console.log("Search(out usememo):",search);


    return (
        
           

           
            <div className="row w-100">
                <div className="col mb-3 col-12 text-center">
                {//console.log(isLoading)
                }
                   { isLoading ? <LoadingSpinner/> :
                    <table className="table table-striped">
                              
                                
                                    <DataTable
                                    columns={columns1}
                                    data={listServices}
                                    pagination
                                    paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                                    
                                    noDataComponent="No hay registros"
                                    persistTableHead
                                    customStyles={customStyles}
                                    defaultSortFieldId = "1"
                                    highlightOnHover
                                    paginationPerPage={30}
                                    paginationComponentOptions={paginationComponentOptions}
                                    //paginationComponent={CustomMaterialPagination}
                                   
                                    /> 
                                

                                
                                
                               
                    </table>
                    }
                </div>
            </div>
           
        
    );
};

export default DataTableService;