import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import 'bootstrap/dist/js/bootstrap.bundle.min';

import './body_container.css';
import FiltersContainer from '../filters/filters_container';
import FormContainer from '../form/form_container';


function BodyContainer() {
    return ( 
        <div  className='BodyContainer container-fluid vh-100'>

            <div className='row'>

                
                <div className='col-12'>


                    <div className='container-fluid'>

                    
                    
                    </div>








                </div>

            </div>
            
        </div>
    );
}
export default BodyContainer
