
import axios from "axios";
//const API_URL = "http://localhost:8000/";
const API_URL = "https://bbapi.pythonanywhere.com/";


const register = (username, email, password) => {
    return axios.post(API_URL + "signup", {
      username,
      email,
      password,
    });
  };

const login = (username, password) => {
  
  return axios.post(API_URL + "login/", {
      username,
      password,     
    })
    .then((response) => {
      //console.log("response");
     // console.log(response.data.username);
      if (response.data.user) {
        localStorage.setItem("user", JSON.stringify(response.data));
        //console.log("local storage");
      }
      //console.log(response.data.user);
      //console.log(JSON.stringify(response.data));
      return response.data;
    });
};
const logout = () => {
  //localStorage.removeItem("user");
  //console.log("logout");
  return axios.post(API_URL + "logout").then((response) => {
    return response.data;
  });
};
const getCurrentUser = () => {
 // console.log("get user");
 let us = localStorage.getItem("user");
 //console.log("us",us);
 if (us !== null ){
  return JSON.parse(us).user;
 }
 else{
  return false; 
 }
  
};
const AuthService = {
 register,
  login,
  logout,
  getCurrentUser,
}
export default AuthService;
