import React, {Component,useState,useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { useLocation } from "react-router-dom";

import './body_container_consolidados.css';
import { useForm ,Controller} from 'react-hook-form';
import { ErrorMessage } from "@hookform/error-message";
import Select from 'react-select';
import FilterComponent from '../FilterComponent/filterComponent';
import UserService    from '../../services/user.service';
import DataTableConsolidated from '../data_table_consolidated/dataTableConsolidated';
import ConsolidatedPDF from './consolidatedPdf';
import LoadingSpinner from "../loading/loadingComponent";

function BodyContainerConsolidados(props) {

    const [loading,setLoading] = useState(false);
    const [newConsolidated,setNewConsolidated] = useState(false);
    const [editConsolidated,setEditConsolidated] = useState(false);
    const [watchConsolidated,setWatchConsolidated] = useState(false);
    const { reset,getValues,control, watch ,setValue, register, handleSubmit, formState: { errors } } = useForm(
       
    );
  
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user : null;
    const [quotes,setQuotes] = useState();
    const [consolidateSelected,setConsolidateSelected] = useState();
    const [detailsConsolidated,setDetailsConsolidated]  = useState([]);
    const [editOrWatch,setEditOrWatch] = useState(0);
    const [rerender,setRerender] = useState(false);
    const [quotesPdf,setQuotesPdf] = useState();
    const [listQuotes,setListQuotes] = useState([]);
    const [listEnterprises,setListEnterprises] = useState([])
    const [detailToDelete,setDetailToDelete] = useState([]);
    const [detailToCreate,setDetailToCreate]= useState([]);
    const [cotiSelected,setCotiSelected]= useState();
    //const [consolidateDetailSelected,setConsolidateDetailSelected] = useState();
    console.log("Quotes: ",quotes);
    const location = useLocation();
    //let quotes ;
    //console.log("quotes",quotes);
    //console.log(location?.state, " useLocation Hook");
    
    let total_volume = 0.0;
    let total_weight = 0.0;
    let total_quantities = 0.0;
    let quantities = [];
    
    
    const transport_types = [
        { name: 'CARRETA BAUL', id: 2 },
        { name: 'CARRETA SAIDER', id: 3 },   
        { name: 'CARRETA ABIERTA', id: 1 }
      ];
     

    function  deleteCoti(index)
    {
        console.log("Eliminado: ",index);
    }

    const onChangeSelectCoti = (Selected) =>
    {
        console.log("Coti Selected: ",Selected);
        setCotiSelected(Selected);
    }
    useEffect(()=>{ 
        const getData = () => {
            if(location.state !== null)
            {
                setNewConsolidated(true);
                setQuotes(location.state.quotes);
                var curr = new Date();
                curr.setDate(curr.getDate() );
                var date = curr.toISOString().substring(0,10);
                setValue("consolidated_date",date)
                //console.log("Fecha: ",date)
                //quotes = location.state.quotes ;
                //console.log("if true: ",location.state.quotes);
            }
            console.log("useeffect: ",location);
            
    };
    getData();    
    },[location.state]);
    console.log("consolidateSelected: ",consolidateSelected);
    useEffect(()=>{ 
        async function getData  () {
            if(consolidateSelected !== null && consolidateSelected !== undefined)
            {

                try
                {
                   
                    var responseDetail = await UserService.getConsolidatedDetail(consolidateSelected.id);
                    setDetailsConsolidated(responseDetail);

                    if(editOrWatch === 1)//watch
                    {
                        setWatchConsolidated(true);
                    }
                    if(editOrWatch === 2)
                    {
                        setEditConsolidated(true);


                        var quotations = await UserService.getQuotes();
                        setListQuotes(quotations.filter(item => ( (item.validation_status === "aprobada") && ('contact_supplier' in  item.request_parent )  )));
                        var enterp =await UserService.getEnterprise();
                        setListEnterprises(enterp);

                        Object.keys(consolidateSelected).forEach((key, index) => {setValue(key,consolidateSelected[key])}   );
                        setValue("vehicle_type",{ label : transport_types.find( (item) => (item.id === consolidateSelected.transport_bo ) ).name  , value: consolidateSelected.transport_bo });
                        
                        
                    }
                    
                }
                catch(error)
                {
                    toast.error("Ocurrio un error al recuperar los datos");
                    console.log("Error",errors);
                    console.log("Error catch",error)
                }
                

                //console.log("consolidateSelected: ",consolidateSelected);
                
            }
           
            
    };
    getData();    
    },[consolidateSelected]);
    console.log("Detailsss: ",detailsConsolidated);
    const onError = (errors, e) => 
    {
        toast.warning("Debe llenar los campos obligatorios de rojo");
        console.log("Error",errors)
    }


    function onSubmitDelete (e){
        
            console.log("Data",e.target.id);
            let detail =   detailsConsolidated.splice(e.target.id,1);
            console.log("Eliminado:",detail[0] );
            setDetailToDelete(current => [...current,detail[0]])
            //let response = await UserService.deleteConsolidatedDetail(detail.id)
            //console.log("response: ",response);
            setRerender(!rerender);
     
    
      
    }
    function onSubmitAdd (){
        if(cotiSelected !== undefined && cotiSelected !== null)
        {
            var coti = listQuotes.find( cot => {return cot.id === cotiSelected.value} )
            //
            if(coti !== undefined)
            {
                
               
                //coti["ent_name"]  = listEnterprises.find( (obj) => {return obj.id === coti.request_parent.request_contact.enterprise_id}  ).name;
               
                setDetailToCreate(current => [...current,coti]);
                setDetailsConsolidated(current => [...current,
            {
                id: 0,
                detail_number : detailsConsolidated.length,
                consolidated : consolidateSelected.id ,
                fiscal_note :  "",
                quantity  : "" ,
                crt :  "",
                quote: {
                        id: coti.id,
                        enterprise_id: listEnterprises.find( (obj) => {return obj.id === coti.request_parent.request_contact.enterprise_id}  ).id ,
                        enterprise_name: listEnterprises.find( (obj) => {return obj.id === coti.request_parent.request_contact.enterprise_id}  ).name ,
                        exporter: coti.request_parent.exporter,
                        contact_supplier: {
                                    supplier_id : coti.request_parent.contact_supplier.supplier_id,
                                    supplier_name: coti.request_parent.contact_supplier?.supplier_name ,
                                    supplier_dispatcher_name : coti.request_parent.contact_supplier.supplier_dispatcher_name ,
                                    supplier_dispatcher_contact :coti.request_parent.contact_supplier.supplier_dispatcher_contact  ,
                        },
                       supplier_selection :coti.request_parent.supplier_selection ,
                               
                        weight: coti.request_parent.weight ,
                             
                        volume: coti.request_parent.volume,
                     
                }
    
            }
            
            ]);
            }
            else{
                toast.warning("No existe esa cotizacion");
            }
            //setRerender(!rerender);
        }
       
 

  
    }
     async function onSubmit ( data)  {
        
        
        try{
            if(newConsolidated === true && editConsolidated === false)
            {
                setLoading(true);
                console.log("Data: ",data);
                var consolidated = await UserService.postConsolidated("1",data.consolidated_date,data.driver_br,data.plate_br,data.cpf_br,data.vehicle_type.value,data.driver_bo,data.plate_bo,data.ci_bo,data.vehicle_type.value,user.id)
                console.log("Post: ",consolidated)
                
                for (var i=0; i<quotes.length;i++)
                    var res = await UserService.postConsolidatedDetail(i,eval("data.NotaFiscal"+i),eval("data.quant"+i),eval("data.crt"+i),consolidated.data.consolidated.id,quotes[i].id);
                   
                    
                
                   
                setNewConsolidated(false);
                
                window.history.replaceState({}, document.title)
                
                reset();
                setQuotes([]);
                toast.success("Consolidado creado Exitosamente") 
                setLoading(false);
            }
            else if(editConsolidated === true)
            {
                setLoading(true);
                console.log("Data: ",data);
                var consolidated = await UserService.patchConsolidated(data.consolidate_number,data.consolidated_date,data.driver_br,data.plate_br,data.cpf_br,data.vehicle_type.value,data.driver_bo,data.plate_bo,data.ci_bo,data.vehicle_type.value,user.id,data.id)
                console.log("Patch: ",consolidated)
                
                for (var i=0; i<detailsConsolidated.length;i++)
                {
                    if(detailsConsolidated[i].id === 0)
                    {
                        var res = await UserService.postConsolidatedDetail(i,eval("data.NotaFiscal"+i),eval("data.quant"+i),eval("data.crt"+i),data.id,detailsConsolidated[i].quote.id);
                   
                    }
                    else if(detailsConsolidated[i].id > 0)
                    {
                        var res = await UserService.putConsolidatedDetail(i,eval("data.NotaFiscal"+i),eval("data.quant"+i),eval("data.crt"+i),data.id,detailsConsolidated[i].quote.id,detailsConsolidated[i].id);
                    }
                    console.log("Detail:"+i+" res:",res);
                   
                }
                for (var i=0; i<detailToDelete.length;i++)
                {
                  
                    var res = await UserService.deleteConsolidatedDetail(detailToDelete[i].id);
                   
                    console.log("Detail:"+i+" res:",res);
                   
                }  
                
                   
                setEditConsolidated(false);
            
                
                reset();
                setDetailsConsolidated([]);
                toast.success("Consolidado creado Exitosamente")
                setLoading(false); 
            }
        }
        catch(error)
        {
            console.log("Erros",error);
            toast.error("Ocurrio un error al Registrar los datos")
            setLoading(false);
        }
    }
    /*
    location.state?.quotes.map( (item,index) => (
        console.log("item: ",item, "  index: " ,index)
    ));
    */
    //console.log("Conslidates Selected: ",consolidateSelected);
   
    console.log("Quantities:",quantities);
 //
    console.log("To delete: ",detailToDelete);
    console.log("To Create: ",detailToCreate)
    console.log("list quotes: ",listQuotes);
    return ( 
       
        <div  className='BodyContainer container-fluid vh-100'>
            
            <div className='row'>

                <div id= 'sidebarMenu' className='col-2 bg-light vh-100 '>

                    <div className="mt-5 vh-100" >
                        
                       
                        
                        
                    </div>
                </div>

                <div className='col-10'>
                    <div>
                    <ToastContainer  
                        position="bottom-center"
                        autoClose={2500}
                        theme= "light"
                    />
                    </div>

                    { !(newConsolidated || editConsolidated || watchConsolidated )  && (

                        <div className='container-fluid h-100'>
                            <div className='row'>

                                <div className="col-11 p-3">
                                    
                                    <h2 className="text-center">LISTA DE CONSOLIDADOS</h2>
                                    
                                </div>
                                
                            </div>

                            <div className='row h-100' >
                                { /*
                                <div className='col'>
                                    <button type='button' className='btn btn-primary' onClick={() => {setNewConsolidated(true)}}>
                                        NUEVO
                                    </button>
                                    <button type='button' className='btn btn-primary' onClick={() => {setEditConsolidated(true)}}>
                                        EDITAR
                                    </button>
                                    <button type='button' className='btn btn-primary' onClick={() => {setWatchConsolidated(true)}}>
                                        VER
                                    </button>
                                </div>*/
                                }

                                <DataTableConsolidated
                                    entSearch = {""}
                                    numberSearch = {""}
                                    startDateSearch={"1/1/2000"}
                                    endDateSearch={"1/1/2030"}
                                    setRowSelected = {setConsolidateSelected}
                                    EditOrWatch = {setEditOrWatch}
                                    //watchConsolidated={setWatchConsolidated}
                                    
                                />  
                            
                           
                            
                            
                            </div>
                            

                        </div>
                        )
                    }

                    {
                        (watchConsolidated) && (
                            <div className='row ' style={{ height : "100%"}}>
                                <div className='row '>

                                    <div className='col'>
                                        <button className='btn' type = "button" onClick={ () => {setWatchConsolidated(false);setEditConsolidated(false);}}>
                                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.5 280.6l192 160c20.6 17.2 52.5 2.8 52.5-24.6V96c0-27.4-31.9-41.8-52.5-24.6l-192 160c-15.3 12.8-15.3 36.4 0 49.2zm256 0l192 160c20.6 17.2 52.5 2.8 52.5-24.6V96c0-27.4-31.9-41.8-52.5-24.6l-192 160c-15.3 12.8-15.3 36.4 0 49.2z"></path>
                                            </svg>
                                        </button>
                                    </div>
                                    <div className='col-10'>
                                        
                                    </div>
                                </div>
                                <div className='row' >
                                    <div className='m-1' >
                                                <button className="btn btn-lg btn-secondary" style={{backgroundColor: "navy"}} onClick={() => {}}>
                                                    GENERAR NOMBRE DEL DOCUMENTO 
                                                </button>
                                            </div>
                                </div>
                                <div className='row justify-content-md-center 'style={{ height : "100%"}} >
                                    <ConsolidatedPDF
                                        details={detailsConsolidated}
                                        consolidated={consolidateSelected}
                                    />
                                </div>
                                
                            </div>
                        )
                    }
                    {
                        ( (newConsolidated || editConsolidated ) && loading === true) && (
                            <div >
                                <LoadingSpinner/>
                            </div>
                        )
                    }      
                    { ( (newConsolidated || editConsolidated ) && loading === false) && (
                            <div>

                                <div className='m-4 row '>
                                    <div className='col'> 
                                        <button className='btn' type = "button"  onClick={()=>{setNewConsolidated(false); setEditConsolidated(false) ;setWatchConsolidated(false);}}>
                                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.5 280.6l192 160c20.6 17.2 52.5 2.8 52.5-24.6V96c0-27.4-31.9-41.8-52.5-24.6l-192 160c-15.3 12.8-15.3 36.4 0 49.2zm256 0l192 160c20.6 17.2 52.5 2.8 52.5-24.6V96c0-27.4-31.9-41.8-52.5-24.6l-192 160c-15.3 12.8-15.3 36.4 0 49.2z"></path>
                                            </svg>
                                        </button>   
                                    </div>
                                    {(newConsolidated) &&
                                        <div  className='col-10'>
                                            <h1 >
                                                INGRESE UNA NUEVO CONSOLIDADO
                                            </h1>
                                        </div>}
                                    {(editConsolidated) &&
                                        <div  className='col-10'>
                                            <h1 >
                                                MODIFICAR CONSOLIDADO
                                            </h1>
                                        </div>}
                                        
                                    <div className='col-1'>
                                            <button type="button" className="btn " >
                                                
                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill="none" stroke="#000" stroke-width="2" d="M1.7507,16.0022 C3.3517,20.0982 7.3367,23.0002 11.9997,23.0002 C18.0747,23.0002 22.9997,18.0752 22.9997,12.0002 M22.2497,7.9982 C20.6487,3.9012 16.6627,1.0002 11.9997,1.0002 C5.9247,1.0002 0.9997,5.9252 0.9997,12.0002 M8.9997,16.0002 L0.9997,16.0002 L0.9997,24.0002 M22.9997,0.0002 L22.9997,8.0002 L14.9997,8.0002">
                                                        </path>
                                                </svg>
            
            
                                            </button>
                                    </div>

                                    
                                </div>
                                
                                <div >
                                    <form className='m-5 row justify-content-md-center' onSubmit={ handleSubmit(onSubmit,onError)}>
                                    
                                            <div className='row justify-content-md-center m-2'>
                                                <div className='row'>
                                                    <div  className=' input-group m-2 col  '>
                                                        <span className=' input-group-text col-md-3' > FECHA</span>
                                                        <input type="date"   className=' form-control' placeholder='' style={{backgroundColor: errors.quote_date ? '#f5dce2' : '' }} {...register("consolidated_date",{required: true})}  />
                                                    </div>
                                                    <div  className=' input-group  m-2 col'>
                                                        <span className=' input-group-text ' > TIPO DE VEHICULO</span>
                                                        <Controller
                                                            name="vehicle_type"
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                options = {(transport_types.length !== 0)?  transport_types.map((option) => ({ label : option.name, value: option.id })) : ([])}
                                                        
                                                                    className = "col"
                                                                
                                                                />
                                                            )}
                                                            control={control}
                                                            defaultValue=""
                                                            rules={{ required: true }}
                                                        />
                                                    </div>
                                                    <div  className='m-2 input-group col'>
                                                        
                                                    </div>
                                                </div>
                                               
                                                <div className='row border rounded border-5 p-2 m-1'>
                                                    <label className='row'> BRASIL</label>
                                                    <div  className='m-2 input-group col'>
                                                       
                                                        <span className=' input-group-text col-md-3' >NOMBRE MOTORISTA </span>
                                                        <input type="text"   className='form-control ' placeholder='' {...register("driver_br",{required: true})}  />
                                                    </div>
                                                    <div  className='m-2 input-group col'>
                                                        <span className=' input-group-text col-md-3' > CPF</span>
                                                        <input type="text"   className='form-control ' placeholder='' {...register("cpf_br",{required: true})}  />
                                                    </div>
                                                    <div  className='m-2 input-group col'>
                                                        <span className=' input-group-text col-md-3' > PLACAS </span>
                                                        <input type="text"   className='form-control ' placeholder='' {...register("plate_br",{required: true})}  />
                                                    </div>
                                                    
                                                </div>
                                                <div className='row border rounded border-5 p-2 m-1'>
                                                    <label className='row'> BOLIVIA</label>
                                                    <div  className='m-2 input-group col'>
                                                        
                                                        <span className=' input-group-text col-md-3' > NOMBRE MOTORISTA</span>
                                                        <input type="text"   className='form-control ' placeholder='' {...register("driver_bo",{required: true})}  />
                                                    </div>
                                                    
                                                    <div  className='m-2 input-group col'>
                                                        <span className=' input-group-text col-md-3' > CI</span>
                                                        <input type="text"   className='form-control ' placeholder='' {...register("ci_bo",{required: true})}  />
                                                    </div>
                                                    <div  className='m-2 input-group col'>
                                                        <span className=' input-group-text col-md-3' > PLACAS </span>
                                                        <input type="text"   className='form-control ' placeholder='' {...register("plate_bo",{required: true})}  />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row  justify-content-md-center'>
                                                <div className='row'>
                                                    <div className='col'>
                                                        <div className='m-0 input-group input-group-lg'>
                                                            <span className=' input-group-text col-2 headTable text-center' > NOTA FISCAL</span>   
                                                            <span className=' input-group-text col-2 headTable' > EXPORTADOR</span>
                                                            <span className=' input-group-text col-1 headTable' > IMPORTADOR</span>   
                                                            <span className=' input-group-text col-1 headTable' > QUANT</span>
                                                            <span className=' input-group-text col-1 headTable' > CUBAGEM</span>
                                                            <span className=' input-group-text col-1 headTable' > PESO BRUTO</span>
                                                            <span className=' input-group-text col-1 headTable' > CRT</span>
                                                            <span className=' input-group-text col-2 headTable' > DESPACHANTE</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                 ( newConsolidated ) &&(quotes?.map( (item,index) => (
                                                    
                                                    <div className='row'>
                                                        
                                                        <div className='col'>
                                                            <div className='m-0 input-group input-group-lg'>
                                                                
                                                                <input 
                                                                    type="text"   
                                                                    className='input-group-text col-2' 
                                                                    placeholder='' 
                                                                    {...register( "NotaFiscal" + index   ,{required: true})}  
                                                                    style={{backgroundColor : "white"}}
                                                                   
                                                                    
                                                                />
                                                        
                                                                <input 
                                                                    type="text"   
                                                                    className='input-group-text col-2' 
                                                                    placeholder='' 
                                                                     
                                                                    readOnly
                                                                    value={item.request_parent.contact_supplier?.supplier_name}
                                                                />
                                                                <input 
                                                                    type="text"   
                                                                    className='input-group-text col-1' 
                                                                    placeholder='' 
                                                                    
                                                                    readOnly
                                                                    value={item.ent_name}
                                                                />
                                                                <input 
                                                                    type="text"   
                                                                    className='input-group-text col-1' 
                                                                    placeholder='' 
                                                                    {...register("quant" + index ,{required: true})}  
                                                                    style={{backgroundColor : "white"}}
                                                                    {...quantities.push("quant-" + index )}
                                                                />
                                                                
                                                                <input 
                                                                    type="text"   
                                                                    className='input-group-text col-1' 
                                                                    placeholder='' 
                                                                // {...register("crane",{required: false})}  
                                                                    readOnly
                                                                    value={item.request_parent.volume}
                                                                    {...total_volume += item.request_parent.volume}
                                                                />
                                                                <input 
                                                                    type="text"   
                                                                    className='input-group-text col-1' 
                                                                    placeholder='' 
                                                                    
                                                                    readOnly
                                                                    value={item.request_parent.weight}
                                                                    {...total_weight += item.request_parent.weight}
                                                                />
                                                                <input 
                                                                    type="text"   
                                                                    className='input-group-text col-1' 
                                                                    placeholder='' 
                                                                    {...register("crt" + index,{required: true})}  
                                                                    style={{backgroundColor : "white"}}
                                                                />
                                                                <input 
                                                                    type="text"   
                                                                    className='input-group-text col-2' 
                                                                    placeholder='' 
                                                                    
                                                                    readOnly
                                                                    value ={item.request_parent.contact_supplier?.supplier_dispatcher_name + "-"+item.request_parent.contact_supplier?.supplier_dispatcher_contact}
                                                                />
                                                    
                                                            </div>
                                                        </div>
                                                    </div>

                                                  ) ) )
                                                }

{
                                                 ( editConsolidated ) &&(detailsConsolidated?.map( (item,index) => (
                                                    
                                                    <div className='row'>
                                                        
                                                        <div className='col'>
                                                            <div className='m-0 input-group input-group-lg'>
                                                                
                                                                <input 
                                                                    type="text"   
                                                                    className='input-group-text col-2' 
                                                                    placeholder='' 
                                                                    {...register( "NotaFiscal" + index   ,{required: true})}  
                                                                    style={{backgroundColor : "white"}}
                                                                    //onChange={(value) => (setValue("NotaFiscal" + index ,value))}
                                                                    //defaultValue={item.fiscal_note}
                                                                    {...setValue("NotaFiscal" + index ,item.fiscal_note)}
                                                                />
                                                        
                                                                <input 
                                                                    type="text"   
                                                                    className='input-group-text col-2' 
                                                                    placeholder='' 
                                                                     
                                                                    readOnly
                                                                    value={item.quote.contact_supplier?.supplier_name}
                                                                />
                                                                <input 
                                                                    type="text"   
                                                                    className='input-group-text col-1' 
                                                                    placeholder='' 
                                                                    
                                                                    readOnly
                                                                    value={item.quote.enterprise_name}
                                                                />
                                                                <input 
                                                                    type="text"   
                                                                    className='input-group-text col-1' 
                                                                    placeholder='' 
                                                                    {...register("quant" + index ,{required: true})}  
                                                                    style={{backgroundColor : "white"}}
                                                                    {...setValue("quant" + index ,item.quantity)}
                                                                    //{...quantities.push("quant-" + index )}
                                                                />
                                                                
                                                                <input 
                                                                    type="text"   
                                                                    className='input-group-text col-1' 
                                                                    placeholder='' 
                                                                 //{...register("crane",{required: false})}  
                                                                    readOnly
                                                                    value={item.quote.volume}
                                                                    //{...total_volume += item.request_parent.volume}
                                                                />
                                                                <input 
                                                                    type="text"   
                                                                    className='input-group-text col-1' 
                                                                    placeholder='' 
                                                                    
                                                                    readOnly
                                                                    value={item.quote.weight}
                                                                    //{...total_weight += item.request_parent.weight}
                                                                />
                                                                <input 
                                                                    type="text"   
                                                                    className='input-group-text col-1' 
                                                                    placeholder='' 
                                                                    {...register("crt" + index,{required: true})}  
                                                                    style={{backgroundColor : "white"}}
                                                                    {...setValue("crt" + index ,item.crt)}
                                                                />
                                                                <input 
                                                                    type="text"   
                                                                    className='input-group-text col-2' 
                                                                    placeholder='' 
                                                                    
                                                                    readOnly
                                                                    value ={item.quote.contact_supplier?.supplier_dispatcher_name + "-"+item.quote.contact_supplier?.supplier_dispatcher_contact}
                                                                    
                                                                />
                                                                <div className="openbtn text-center ">
                                                                    <button
                                                                    type="button"
                                                                    className="btn btn-danger btn-sm "
                                                                   
                                                                  
                                                                    data-bs-toggle ="modal"
                                                                    data-bs-target= {"#deleteModal-"+index}
                                                                
                                                                  
                                                                    >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                                                                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
                                                                    </svg>
                                                                    </button>

                                                                    <div className='modal modal-lg' tabIndex="-1" id={"deleteModal-"+index}>
                                                                        <div  className='modal-dialog'>
                                                                            <div className="modal-content">
                                                                                <div className="modal-header">
                                                                                    <h5 className="modal-title">¿ESTA SEGURO DE ELIMINAR LA SOLICITUD?</h5>
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn-close"
                                                                                        data-bs-dismiss="modal"
                                                                                        aria-label="Close"
                                                                                    ></button>
                                                                                </div>
                                                                                <div className='modal-body'>
                                                                                    

                                                                                        
                                                                                            
                                                                                            <div className="row m-2 p-2">
                                                                                                <div className="col">
                                                                                                    <button 
                                                                                                        id={index}
                                                                                                        data-bs-dismiss="modal" 
                                                                                                        className="btn btn-danger" 
                                                                                                        type="button" 
                                                                                                        onClick={(e) =>{onSubmitDelete(e)}}> ELIMINAR
                                                                                                    </button>
                                                                                                    
                                                                                                </div>
                                                                                            </div>   
                                                                                       
                                                                                </div>
                                                                            </div>
                                                    
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                    
                                                            </div>
                                                        </div>
                                                    </div>

                                                  ) ) )
                                                }
                                                {

                                                }
                                                {
                                                    /*
                                                    <div className='row'>
                                                        <div className='col'>
                                                            <div className='m-0 input-group input-group-lg'>
                                                                
                                                                <input 
                                                                    type="text"   
                                                                    className='input-group-text col-2' 
                                                                    placeholder='' 
                                                                    readOnly
                                                                    
                                                                />
                                                                <input 
                                                                    type="text"   
                                                                    className='input-group-text col-2' 
                                                                    placeholder='' 
                                                                    
                                                                    readOnly
                                                                    
                                                                />
                                                                <input 
                                                                    type="text"   
                                                                    className='input-group-text col-2' 
                                                                    placeholder='' 
                                                                    
                                                                    readOnly
                                                                
                                                                />
                                                                <input 
                                                                    type="text"   
                                                                    className='input-group-text col-1' 
                                                                    placeholder='' 
                                                                    readOnly
                                                                />
                                                                <input 
                                                                    type="text"   
                                                                    className='input-group-text col-1' 
                                                                    placeholder='' 
                                                                    value={total_volume}
                                                                    readOnly
                                                                
                                                                />
                                                                <input 
                                                                    type="text"   
                                                                    className='input-group-text col-1' 
                                                                    placeholder='' 
                                                                    value={total_weight}
                                                                    readOnly
                                                                    
                                                                />
                                                                <input 
                                                                    type="text"   
                                                                    className='input-group-text col-1' 
                                                                    placeholder='' 
                                                                    readOnly
                                                                />
                                                                <input 
                                                                    type="text"   
                                                                    className='input-group-text col-2' 
                                                                    placeholder='' 
                                                                    
                                                                    readOnly
                                                                    
                                                                />
                                                    
                                                            </div>
                                                        </div>
                                                    </div>
                                                    */
                                                }
                                                    

                                                
                                                
                                                

                                            
                                            </div>
                                            {
                                                (editConsolidated) && (
                                                    <div className='row m-3 '>
                                                        <div className='m-2 input-group'>
                                                            <span className=' input-group-text col-md-1'>COTIZACION: </span>
                                                                                
                                                            <Select
                                                                                            
                                                                className = 'col-3'
                                                                placeholder="Num. Cotizacion - Proveedor " 
                                                                onChange= {(a) => {onChangeSelectCoti(a) ;}}
                                                                //defaultValue={{ label : condPago[condPagoSelect].name, value: condPago[condPagoSelect].id }}                            
                                                                options = {(listQuotes.length !== 0)?  listQuotes.map((option) => ({ label : option.quote_number + " - " + option.request_parent.contact_supplier?.supplier_name   , value: option.id })) : ([])}
                                                                                            
                                                                                        
                                                            />
                                                            <button type='button'  className='btn btn-primary' onClick={() => {onSubmitAdd()}}>
                                                                    AGREGAR
                                                            </button>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                           
                                            <div className='row m-3 '>
                                                <div className='col '> 
                                                    <button type='submit' className='btn btn-primary'>
                                                        CONSOLIDAR
                                                    </button>
                                                </div>
                                            </div>
                                        
                                        
                                        

                                        
                                        
                                            
                                        
                                            
                                        
                                    </form>

                                </div>

                            </div>
                        )
                    }


                </div>

            </div>
            
        </div>

    );
}
export default BodyContainerConsolidados
