import React,{useEffect,useState} from "react";


import {
    Document,
    Page,
    View,
    Image,
    Text,
    StyleSheet,
    usePDF,
    Svg,
    Line
} from '@react-pdf/renderer';
import Select from 'react-select';
import logoImage from '../../assets/LOGO-BBA-TRANSPARENTE1.png';
import { Alignment } from "react-data-table-component";
//------------------------------------------------

const PDFViewer = ({
    title,
    style,
    className,
    fileName,
    children,
    innerRef,
    ...props
  }) => {
    const [instance, updateInstance] = usePDF({ document: children });
    const [url, set] = useState();
  
    useEffect(() => {
      if (instance.blob) {
        set(
          URL.createObjectURL(
            new File([instance.blob], fileName, {
              type: instance.blob.type
            })
          )
        );
      }
    }, [instance]);
  
    useEffect(updateInstance, [children]);
  
    return (
      <>
        <iframe
          title={title}
          ref={innerRef}
          width="100%"
          height="100%"
          src={url}
          className={className}
          {...props}
        />
      </>
    );
  };



//------------------------------------------

const styles = StyleSheet.create(
    {
        tabla: {
            display : "table",
            borderStyle : "solid",
            borderColor : "#000",
            borderWidth : 1,
            borderRightWidth: 0,
            borderBottomWidth: 0,

        },
        tablaFila : {
            margin : "auto",
            flexDirection: "row",
            height: "12px"
            
        },
        tablaColumna0: {
            width: "100%",
            borderStyle : "solid",
            borderColor : "#000",
            borderWidth : 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
        },
        tablaColumna1 : {
            width: "10%",
            borderStyle : "solid",
            borderColor : "#000",
            borderWidth : 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
                },
        tablaColumna2 : {
            width: "40%",
            borderStyle : "solid",
            borderColor : "#000",
            borderWidth : 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
        },
        tablaTexto: {
            margin: "auto",
            
            fontSize: 9,
        },  
        tablaTextoAzul: {
            margin: "auto",
            color: "navy",
            fontSize: 9,
        },
        tablaColumna3 : {
            width: "15%",
            borderStyle : "solid",
            borderColor : "#000",
            borderWidth : 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
        },
        tablaColumna4 : {
            width: "75%",
            borderStyle : "solid",
            borderColor : "#000",
            borderWidth : 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
        },
        texto: {
            margin: "auto",
           
            fontSize: 9,
            
            
        },
        textoAzul: {
            margin: "auto",
            textAlign:"left",
            fontSize: 9,
            color: "navy",
            alignItems:"flex-start",
        },
       
        borde: {
            borderBottomStyle:"solid",
            borderColor:"#000",
            borderWidth:1
        },
        textoNegritas: {
            fontSize: 9,
            margin : "auto",
            textAlign:"left",
            alignItems: "flex-start",
            fontWeight: "bold",
        },
        fila1 : {
            display: "flex",
            flexDirection: "row",
            height : "12px",
            
        },
        columna1 : {
            width: "20%",
            margin: 0,
        },
        columna2 : {
            width: "80%",
            margin: 0,
        },
        tablaColumna5 : {
            width: "13%",
            borderStyle : "solid",
            borderColor : "#000",
            borderWidth : 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
        },
        tablaColumna6 : {
            width: "61%",
            borderStyle : "solid",
            borderColor : "#000",
            borderWidth : 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
        },
        footerPage :{
            position: 'absolute',
            
            bottom: 20,
            left: 0,
            right: 0,
          
            
        }
    }
);



const CotizacionPDF = ({quote,servs,servsStatus,cond,condSel,vtotal})  => {

    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user : null;
    function floatToString (num = 0){
        
        if(num === null)
        {
            return "";
        }
        else
        {
            var numFormat = num.toLocaleString("es-BO") ;
            return numFormat ? numFormat : ""
           
        }
        }
       
    var total = 0;
    
    console.log("servic",servs)
 

    function formatDate(date){
        const day = date.substring(8,10);
        const month = date.substring(5,7);
        const year = date.substring(0,4);
        return day+"-"+month+"-"+year;
    }
    
    
    console.log("Quote:",quote);
    const datos = quote;
    const descRutas = [
        {id:1,desc1:", CON REEMBARQUE EN CORUMBA.",desc2:" Brasil a Bolivia "},
        {id:2,desc1:".",desc2:" Argentina a Bolivia "},
        
    ]
    const idRutas = {
        brasilBolvia: 1,
        argentinaBolivia: 2
    }
    const pdfName = "Coti" +datos.quote_number.substring(0,datos.quote_number.length-5)+"-"+datos.quote_number.substring(datos.quote_number.length-4)+"-"+datos.ent_name+"-"+( datos.request_parent.supplier_selection ?  (datos.request_parent.contact_supplier ? datos.request_parent.contact_supplier.supplier_name.toUpperCase() : 'SELECCIONAR PROVEEDOR') : datos.request_parent.exporter )+"-"+
                    floatToString(datos.request_parent.weight)+"-"+floatToString(datos.request_parent.volume)+"-"+datos.request_parent.origin+"-"+datos.request_parent.destination +".pdf" 

    
    return (

        <>
        <PDFViewer fileName={pdfName}>
        <Document  >
                <Page size = "LETTER" wrap={false} >
                    <View style={{padding : "60px",paddingTop: "20px" }} >
                        <View>
                            <Image  
                                style={{ width : "30%"}}  
                                source={logoImage}
                            />
                        </View>

                        <View style={{display: "flex",flexDirection: "row"}} >
                            <View style={{width : "66%"}}>

                            </View>
                            <Text style={{borderStyle: "solid", borderColor: "red", borderWidth: 2,textAlign: "center",fontSize: "12",padding:"3px", color: "red"}}>
                            OFERTA VÁLIDA POR 15 DÍAS
                            </Text>
                            
                        </View>
                        <View style={{marginTop: "3px"}}> 

                        </View>

                        <View style={styles.tabla}>
                            <View style={styles.tablaFila}>
                                <View style={styles.tablaColumna0}>
                                    <Text style={{fontSize: "8",fontWeight: "bold",margin: "auto"}}>COTIZACIÓN DE TRANSPORTE Y SERVICIOS</Text>
                                </View>
                            </View>
                            <View style={styles.tablaFila}>  
                                <View style={ styles.tablaColumna1}>
                                    <Text style={styles.tablaTexto}>EMPRESA :</Text>
                                </View>
                                <View style={ styles.tablaColumna2}>
                                    <Text style={styles.tablaTextoAzul}>{datos.ent_name}</Text>
                                </View>
                                <View style={ styles.tablaColumna1}>
                                    <Text style={styles.tablaTexto}>ATN :</Text>
                                </View>
                                <View style={ styles.tablaColumna2}>
                                    <Text style={styles.tablaTextoAzul}>{datos.request_parent.request_contact.name +" " +datos.request_parent.request_contact.last_name}</Text>
                                </View>
                            </View>
                            <View style={styles.tablaFila}>  
                                <View style={ styles.tablaColumna1}>
                                    <Text style={styles.tablaTexto}>CIUDAD :</Text>
                                </View>
                                <View style={ styles.tablaColumna2}>
                                    <Text style={styles.tablaTextoAzul}>{datos.ent_city}</Text>
                                </View>
                                <View style={ styles.tablaColumna1}>
                                    <Text style={styles.tablaTexto}>TELF :</Text>
                                </View>
                                <View style={ styles.tablaColumna3}>
                                    <Text style={styles.tablaTextoAzul}>{datos.request_parent.request_contact.phone}</Text>
                                </View>
                                <View style={ styles.tablaColumna1}>
                                    <Text style={styles.tablaTexto}>FECHA :</Text>
                                </View>
                                <View style={ styles.tablaColumna3}>
                                    <Text style={styles.tablaTextoAzul}>{formatDate(datos.quote_date)}</Text>
                                </View>
                            </View>
                            <View style={styles.tablaFila}>  
                                <View style={ styles.tablaColumna4}>
                                    <Text style={styles.tablaTexto}>SANTA CRUZ - BOLIVIA</Text>
                                </View>
                                
                                <View style={ styles.tablaColumna1}>
                                    <Text style={styles.tablaTexto}>N° :</Text>
                                </View>
                                <View style={ styles.tablaColumna3}>
                                    <Text style={styles.tablaTextoAzul}>{datos.quote_number}</Text>
                                </View>
                            </View>
                        </View>

                        <View style={[{height:"60px"}]}>  
                            <Text style={[styles.texto,{marginLeft: "0"}]}>
                            Distinguidos Señores:     
                            </Text>
                            <Text style={[styles.texto,{textIndent:25,marginLeft:0}]}>
                                Mediante la presente, exponemos nuestros costos de transporte desde 
                                {datos.request_parent.request_route.route_id === idRutas.brasilBolvia && descRutas[0].desc2} 
                                {datos.request_parent.request_route.route_id === idRutas.argentinaBolivia && descRutas[1].desc2} 
                                para su requerimiento conforme detalle a continuación:
                            </Text>

                        </View>
                        <View style={[]}>
                            <View style={[styles.fila1]}>
                                <Text style={[styles.textoNegritas,styles.columna1]}>
                                   Importador : 
                                </Text>
                                <Text style={[styles.textoAzul,styles.columna2]}>
                                    {datos.ent_name}
                                </Text>
                            </View>
                            <View style={[styles.fila1]}>
                                <Text style={[styles.textoNegritas,styles.columna1]}>
                                   Proveedor : 
                                </Text>
                                <Text style={[styles.textoAzul,styles.columna2]}>
                                    { datos.request_parent.supplier_selection ?  (datos.request_parent.contact_supplier ? datos.request_parent.contact_supplier.supplier_name.toUpperCase() : 'SELECCIONAR PROVEEDOR') : datos.request_parent.exporter }
                                </Text>
                            </View>
                            <View style={[styles.fila1]}>
                                <Text style={[styles.textoNegritas,styles.columna1]}>
                                   Mercaderia : 
                                </Text>
                                <Text style={[styles.textoAzul,styles.columna2]}>
                                    {datos.request_parent.merchandise}
                                </Text>
                            </View>
                            <View style={[styles.fila1]}>
                                <Text style={[styles.textoNegritas,styles.columna1]}>
                                   Peso : 
                                </Text>
                                <Text style={[styles.textoAzul,styles.columna2]}>
                                    {floatToString(datos.request_parent.weight) + " Kgs."}
                                </Text>
                            </View>
                            <View style={[styles.fila1]}>
                                <Text style={[styles.textoNegritas,styles.columna1]}>
                                   Volumen(M3) : 
                                </Text>
                                <Text style={[styles.textoAzul,styles.columna2]}>
                                {floatToString(datos.request_parent.volume)}
                                </Text>
                            </View>
                            <View style={[styles.fila1]}>
                                <Text style={[styles.textoNegritas,styles.columna1]}>
                                   Valor($) : 
                                </Text>
                                <Text style={[styles.textoAzul,styles.columna2]}>
                                {floatToString(datos.request_parent.value)}
                                </Text>
                            </View>
                            <View style={[styles.fila1]}>
                                <Text style={[styles.textoNegritas,styles.columna1]}>
                                   Origen : 
                                </Text>
                                <Text style={[styles.textoAzul,styles.columna2]}>
                                {datos.request_parent.origin}
                                </Text>
                            </View>
                            <View style={[styles.fila1]}>
                                <Text style={[styles.textoNegritas,styles.columna1]}>
                                   Destino : 
                                </Text>
                                <Text style={[styles.textoAzul,styles.columna2]}>
                                {datos.request_parent.destination}
                                </Text>
                            </View>
                            <View style={[styles.fila1]}>
                                <Text style={[styles.textoNegritas,styles.columna1]}>
                                   Aduana : 
                                </Text>
                                <Text style={[styles.textoAzul,styles.columna2]}>
                                {datos.request_parent.request_custom.custom + " " + datos.request_parent.request_custom.city}
                                </Text>
                            </View>
                        </View>
                        <View style={[{height: "40px"}]}>
                            <Text style={[styles.texto,{color : "red",textAlign: "center",fontSize:8}]}>
                            CONSIDERAMOS TRANSPORTE {datos.request_parent.transport_type === true 
                                                    ? ("EXCLUSIVO :" )
                                                    : ("CONSOLIDADO : CARRETA ABIERTA ENLONADA CON MERCADERÍA DE OTROS IMPORTADORES")}
                                                    
                                                    
                                                    { (datos.request_parent.transport_type && datos.request_parent.carreta_abierta_enlon_status)&&(datos.request_parent.carreta_abier_cant +  " CARRETA ABIERTA ENLONADA")}
                                                    { (datos.request_parent.transport_type && datos.request_parent.carreta_baul_status)&&("," +datos.request_parent.carreta_baul_cant +" CARRETA BAUL")}
                                                    { (datos.request_parent.transport_type && datos.request_parent.carreta_saider_status)&&("," +datos.request_parent.carreta_saider_cant +" CARRETA SAIDER")}
                                                    { (datos.request_parent.transport_type && datos.request_parent.truck_abierto_status)&&("," +datos.request_parent.truck_abierto_cant +" TRUCK ABIERTO")}
                                                    { (datos.request_parent.transport_type && datos.request_parent.truck_baul_status)&&("," +datos.request_parent.truck_baul_cant +" TRUCK BAUL")}
                                                    { (datos.request_parent.transport_type && datos.request_parent.vagon_status)&&("," +datos.request_parent.vagon_cant +" VAGON")}
                                                    
                                                     
                                                     {(datos.request_parent.request_route.route_id === idRutas.brasilBolvia ) && descRutas[0].desc1 }
                                                     {(datos.request_parent.request_route.route_id === idRutas.argentinaBolivia ) && descRutas[1].desc1 }
                                                     {" "+datos.request_parent.observations.toUpperCase()}
                            </Text>
                        </View>

                        <View style={[{}]}>
                            <View style={[styles.tabla]}>
                                {
                                    datos.concept1_status && (
                                        
                                        <View style={[styles.tablaFila,]}>
                                            {total = total + datos.amount1}
                                            <View style={[styles.tablaColumna6]}>
                                                <Text style={[styles.texto,{ marginLeft: '1%' }]}>
                                                {datos.concept1}
                                                </Text>
                                            </View>
                                            <View style={[styles.tablaColumna5]}>
                                                <Text style={[styles.texto]}>
                                                $us
                                                </Text>
                                            </View>
                                            <View style={[styles.tablaColumna5]}>
                                                <Text style={[styles.textoAzul]}>
                                                {floatToString(datos.amount1)}
                                                </Text>
                                            </View>
                                            <View style={[styles.tablaColumna5]}>
                                                <Text style={[styles.texto]}>
                                                {datos.description1}
                                                </Text>
                                            </View>
                                            
                                        </View>
                                    )
                                }
                                

                                {
                                    (datos.concept2_status) && (
                                        
                                        <View style={[styles.tablaFila]}>
                                            {total = total + datos.amount2}
                                            <View style={[styles.tablaColumna6]}>
                                                <Text style={[styles.texto,{ marginLeft: '1%' }]}>
                                                {datos.concept2}
                                                </Text>
                                            </View>
                                            <View style={[styles.tablaColumna5]}>
                                                <Text style={[styles.texto]}>
                                                $us
                                                </Text>
                                            </View>
                                            <View style={[styles.tablaColumna5]}>
                                                <Text style={[styles.textoAzul]}>
                                                {floatToString(datos.amount2)}
                                                </Text>
                                            </View>
                                            <View style={[styles.tablaColumna5]}>
                                                <Text style={[styles.texto]}>
                                                {datos.description2}
                                                </Text>
                                            </View>
                                            
                                        </View>
                                    )
                                }
                                {
                                    (datos.concept3_status) && (
                                        
                                        <View style={[styles.tablaFila]}>
                                            {total = total + datos.amount3}
                                            <View style={[styles.tablaColumna6]}>
                                                <Text style={[styles.texto,{ marginLeft: '1%' }]}>
                                                {datos.concept3}
                                                </Text>
                                            </View>
                                            <View style={[styles.tablaColumna5]}>
                                                <Text style={[styles.texto]}>
                                                $us
                                                </Text>
                                            </View>
                                            <View style={[styles.tablaColumna5]}>
                                                <Text style={[styles.textoAzul]}>
                                                {floatToString(datos.amount3)}
                                                </Text>
                                            </View>
                                            <View style={[styles.tablaColumna5]}>
                                                <Text style={[styles.texto]}>
                                                {datos.description3}
                                                </Text>
                                            </View>
                                            
                                        </View>
                                    )
                                }
                                {
                                    (datos.concept4_status) && (
                                        <View style={[styles.tablaFila]}>
                                            {total = total + datos.amount4}
                                            <View style={[styles.tablaColumna6]}>
                                                <Text style={[styles.texto,{ marginLeft: '1%' }]}>
                                                {datos.concept4}
                                                </Text>
                                            </View>
                                            <View style={[styles.tablaColumna5]}>
                                                <Text style={[styles.texto]}>
                                                $us
                                                </Text>
                                            </View>
                                            <View style={[styles.tablaColumna5]}>
                                                <Text style={[styles.textoAzul]}>
                                                {floatToString(datos.amount4)}
                                                </Text>
                                            </View>
                                            <View style={[styles.tablaColumna5]}>
                                                <Text style={[styles.texto]}>
                                                {datos.description4}
                                                </Text>
                                            </View>
                                            
                                        </View>
                                    )
                                }
                                {
                                    (datos.concept5_status) &&(
                                        <View style={[styles.tablaFila]}>
                                            {total = total + datos.amount5}
                                            <View style={[styles.tablaColumna6]}>
                                                <Text style={[styles.texto,{ marginLeft: '1%' }]}>
                                                {datos.concept5}
                                                </Text>
                                            </View>
                                            <View style={[styles.tablaColumna5]}>
                                                <Text style={[styles.texto]}>
                                                $us
                                                </Text>
                                            </View>
                                            <View style={[styles.tablaColumna5]}>
                                                <Text style={[styles.textoAzul]}>
                                                {floatToString(datos.amount5)}
                                                </Text>
                                            </View>
                                            <View style={[styles.tablaColumna5]}>
                                                <Text style={[styles.texto]}>
                                                {datos.description5}
                                                </Text>
                                            </View>
                                            
                                        </View>
                                    )
                                }
                                
                            </View>
                            <View style={[styles.tablaFila,{}]}>
                                    <View style={[styles.tablaColumna6,{borderBottomWidth: 0}]}>
                                        <Text style={[styles.texto,{ marginLeft: '1%' }]}>
                                        
                                        </Text>
                                    </View>
                                    <View style={[styles.tablaColumna5,{}]}>
                                        <Text style={[styles.texto]}>
                                        $us
                                        </Text>
                                    </View>
                                    <View style={[styles.tablaColumna5]}>
                                        <Text style={[styles.textoAzul]}>
                                        {//floatToString(total)
                                        floatToString(datos.totalAmount)
                                        }
                                        </Text>
                                    </View>
                                    <View style={[styles.tablaColumna5]}>
                                        <Text style={[styles.texto]}>
                                        TOTAL
                                        </Text>
                                    </View>
                                    
                            </View>

                        </View>

                        <View  style={[]} >
                            <View style={[{height: "10px"},styles.fila1]}>  

                            </View>
                            <View style={[styles.fila1]}>
                                <Text style={[styles.textoNegritas,{fontSize: 8, width: "17%", margin: 0,}]}>
                                   Condición de Pago : 
                                </Text>
                                <Text style={[styles.texto,{fontSize: 7.3,margin: 0}]}>
                                    {cond.find( (item) => (item.id === condSel)).name}
                                </Text>
                            </View >
                            <View style={[styles.borde,{minHeight:"70px"}]} >
                                <Text style={[{width:"100%",padding:"1px"},styles.texto]}>
                                    Incluye los siguientes servicios:
                                </Text>
                                {
                                    servs.map( (item,index) => ( 
                                        (servsStatus[index]) && (
                                            <Text style={[styles.texto, {width:"100%",padding:"1px"}]}>
                                            {" * "}  {item.name}
                                            </Text>
                                        )
                                    )  )
                                }
                                
                                
                               
                            </View>
                            {
                                (!datos.insurance_status) && (
                                    <View>
                                        <Text style={[styles.texto,{padding:"2px"}]}>
                                        OPCIONAL: Seguro todo riesgo 0.35% sobre el valor de la mercadería (Franquicia deducible 1% en caso de activación
                                         del seguro por cualquier valor). Si el importador cuenta con su propio seguro debe INCLUIR CLAÚSULA DE 
                                         NO REPETICION AL TRANSPORTISTA.
                                        </Text>
                                    </View>
                                )
                            }

                                    <View style={[{padding:"2px"}]}>
                                        {
                                            (datos.request_parent.transport_type === true) && (
                                                <Text style={[{fontSize:8,color : "red",textAlign: "center"}]}>
                                                    Plazo de 72hrs para liberación en frontera y 24hrs para descarga en destino final.
                                                </Text>
                                            )
                                        }
                                        
                                        <Text style={[{fontSize:8,color : "red",textAlign: "center"}]}>
                                            Si se dan demoras ajenas al transporte habrá costos de estadía de $us 150 p/ día 
                                            {
                                                (datos.request_parent.transport_type === true) && ( " p/ camión ")
                                            }
                                            
                                           , asumidas por el importador.
                                        </Text>
                                        <Text style={[{fontSize:8,color : "red",textAlign: "center"}]}>
                                            Si el sorteo de canal es diferente a CANAL VERDE en agesa, los costos por almacenaje serán asumidos por el importador.
                                        </Text>
                                    </View>
                           
                            <View>
                               
                                <Text style={[styles.texto,{padding:"2px"}]}>
                                IMPORTANTE: Los datos del importador deben ser idénticos al NIT en todos los documentos. Cualquier divergencia la aduana
                                 aplicará multa al importador. (tener actualizado su padrón ante la aduana)
                                
                                </Text>
                            </View>
                            <View>
                                
                                <Text style={[styles.textoNegritas,{padding:"2px",fontSize:8}]}>
                                EN CASO DE ACEPTACIÓN, FAVOR ENVIAR AUTORIZACIÓN POR ESCRITO, NIT Y SEPREC.
                               
                                
                                </Text>
                            </View>
                        </View>
                        <View style={[{height:"10px"}]}>

                        </View>
                        <View style={[]}>
                            <Text style={[{fontSize:8,color : "red",textAlign: "center"}]}>
                            NOTA: SI ALGÚN DATO DE LA MERCADERÍA CAMBIA, ESTA COTIZACIÓN TAMBIÉN CAMBIA.
                            </Text>
                            <Text style={[{fontSize:10,color : "red",textAlign: "center",textDecoration:"underline",}]}>
                            LAS FACTURAS DE TRANSPORTE SON COMPUTARIZADAS Y SERÁN ENVIADAS VÍA CORREO.
                            </Text>
                        </View>
                        <View style={[{height:"40px",alignItems:"center"}]}>
                            <View style={[{height:"10px"}]}>

                            </View>
                            <Text style={[{fontSize:9}]}>
                            Sin otro particular, lo saludamos cordialmente
                            </Text>
                        </View>
                        <View>
                            <Text style={[{fontSize:9,textAlign:"right"}]}>
                                {
                                    user.name.toUpperCase() + " " + user.last_name.toUpperCase()
                                }
                        
                            </Text>
                            <Text style={[{fontSize:9,textAlign:"right"}]}>
                              
                                {
                                    user.area.toUpperCase()
                                }
                            </Text>
                        </View>
                        <View style={[{height:"10px"}]}>

                        </View>

                        <Svg height="2" width="100%">
                            <Line
                            x1="0"
                            y1="0"
                            x2="600"
                            y2="0"
                            strokeWidth={3}
                            stroke="black"
                            opacity={0.8}
                            />
                        </Svg>
                        <View style={[styles.footerPage]}>
                            <View style={[{ display: "flex",flexDirection: "row",}]}>
                                <Text style={[{fontSize:8,textAlign:"center",opacity:0.9,width:"50%"}]}>  
                                    BBA Transporte Internacional S.R. L
                                </Text>
                                <Text style={[{fontSize:8,textAlign:"center",opacity:0.9,width:"50%"}]}>  
                                    BBA Transporte Internacional LTDA.
                                </Text>
                            </View>
                            <View style={[{ display: "flex",flexDirection: "row",}]}>
                                <Text style={[{fontSize:8,textAlign:"center",opacity:0.9,width:"50%"}]}>  
                                    Km. 9 al norte Cond. Las Tacuaras 
                                </Text>
                                <Text style={[{fontSize:8,textAlign:"center",opacity:0.9,width:"50%"}]}>  
                                    Av. Dr. Alberto Jackson Byington # 3055
                                </Text>
                            </View>
                            <View style={[{ display: "flex",flexDirection: "row",}]}>
                                <Text style={[{fontSize:8,textAlign:"center",opacity:0.9,width:"50%"}]}>  
                                    Telf.: 3-3411418 - 62009281 
                                </Text>
                                <Text style={[{fontSize:8,textAlign:"center",opacity:0.9,width:"50%"}]}>  
                                    Cel.: +55 11 948129459
                                </Text>
                            </View>
                            <View style={[{ display: "flex",flexDirection: "row",}]}>
                                <Text style={[{fontSize:8,textAlign:"center",opacity:0.9,width:"50%"}]}>  
                                    Santa Cruz - Bolívia     
                                </Text>
                                <Text style={[{fontSize:8,textAlign:"center",opacity:0.9,width:"50%"}]}>  
                                    Osasco - São Paulo - Brasil
                                </Text>
                            </View>

                            
                            
                            
                        </View>
                    </View>
                </Page>

        </Document>
        </PDFViewer>
        
        </>


        


    );
}

export default CotizacionPDF;

