
import React, { useEffect, useState, useMemo } from "react";
import userService from "../../services/user.service";
import UserService    from '../../services/user.service';
import { TableHeader, Search,Pagination} from "../DataTable";

import DataTable from "react-data-table-component"

import FormContainer from '../form/form_container';
import LoadingSpinner from "../loading/loadingComponent";


const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',

};

const DataTable1 = ({entSearch,nameSearch,setIDSelected,setEditEnt}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [agencyData,setAgencyData] = useState ({});
    const [show,setShow] = useState(false);
    const customStyles = {
       
        headCells: {
            style: {
                background :    'navy',
                fontWeight: 700,
                fontSize : "2.2cap",
                color : 'white',
                opacity: 0.9,
                textAlign : 'center'
            },
        },
        
    };
    const columns1 = [
        {
            name: 'EMPRESA',
            selector: row => row.ent_name.toUpperCase(),
            sortable: true,
            
            
        },
        {
            name: 'NOMBRE',
            selector: row => row.name_contact.toUpperCase(),
            sortable: true,
            
            
        },
        {
            name: 'APELLIDO',
            selector: row => row.last_name_contact.toUpperCase(),
            sortable: true,
            
            
        },
        {
            name: 'CORREO',
            selector: row => row.email.toLowerCase(),
            sortable: true,
            
            
        },
        {
            name: 'CELULAR',
            selector: row => row.cellphone.toUpperCase(),
            sortable: true,
            
            
        },
        {
            name: 'AGENCIA',
            selector: row => row.agency.agency_name.toUpperCase() + " - " +row.agency.agency_contact_name.toUpperCase(),
            sortable: true,
            
            
        },
        {
            button: true,
            width: "100px",
            selector: row => (
                <div >
                    <div className="openbtn text-center">
                        <button
                        
                        type="button"
                        className="btn  btn-sm"
                        data-bs-toggle ="modal"
                        data-bs-target= "#myModal"
                        //data-tag="allowRowEvents"
                        onClick={ () => {setAgencyData(row.agency) }}
                        >
                            
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#041b5b" class="bi bi-search" viewBox="0 0 16 16">
                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                            </svg>
                             Agencia
                        </button>
                    </div>
                    
                    <div className='modal modal-lg' tabIndex="-1" id="myModal">
                        <div  className='modal-dialog'>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Agencia</h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    ></button>
                                </div>
                                <div className='modal-body'>
                                <div className='border rounded border-5 p-2' >   
                                            
                                            <div className='m-2 input-group mx-auto '>
                                                <span className='col-md-4 input-group-text'>NOMBRE DE LA AGENCIA</span>
                                                <input 
                                                type="text"  
                                                
                                                readOnly
                                                className='col-md-8 form-control'
                                                value={agencyData.agency_name}
                                          
                                                />
                                            </div>
                                            <div className='m-2 input-group mx-auto'>
                                               
                                                <span className='col-md-4 input-group-text'>NOMBRE DEL CONTACTO</span>
                                                <input 
                                                    type="text"  
                                                
                                                    readOnly
                                                    className='col-md-8 form-control'
                                                    value={agencyData.agency_contact_name}
                                             
                                                />
                                            </div>
                                            <div className='m-2 input-group mx-auto'>
                                           
                                                <span className='col-md-4 input-group-text'>TELEFONO</span>
                                                <input 
                                                type="text"  
                                                
                                                    name='phone'
                                                    readOnly
                                               
                                                className='col-md-8 form-control'
                                                value={agencyData.agency_phone}
                                            
                                                />
                                            </div>
                                            <div className='m-2 input-group mx-auto'>
                                               
                                                <span className='col-md-4 input-group-text'>CORREO</span>
                                                <input 
                                                    type="text"  
                                                    
                                                    readOnly
                                                    className='col-md-8 form-control'
                                                    value= {agencyData.agency_email}
                                                
                                                />
                                            </div>
                                            
                                           
                                        </div>
                                </div>
                            </div>
    
                        </div>
                    </div>
                    

                        

                    
                    
                </div>
                
                    )
        },
      
        {
            button: true,
            width:"60px",
            cell: row => (
                <div >
                    <div className="openbtn text-center ">
                        <button
                        type="button"
                        className="btn  btn-sm "
                        onClick={() =>{setIDSelected(row); setEditEnt(true);}}
                        //onClick={setIDSelected(row)}
                        
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#041b5b " class="bi bi-pencil-square" viewBox="0 0 16 16">
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                            <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                            </svg>
                        </button>
                    </div>
                   
                </div>
                
                    )
        },
        {
            button: true,
            width: "60px",
            cell: row => (
                <div >
                    <div className="openbtn text-center ">
                        <button
                        type="button"
                        className="btn btn-danger btn-sm"
                        //onClick={() =>{setIDSelected(row); setEditReq(true);}}
                        //onClick={setIDSelected(row)}
                        //onClick={ () => {UserService.deleteContact(row.id)}}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
                            </svg>
                        </button>
                    </div>
                    
                </div>
                
                    )
        }
        

       
    ]

    console.log("agency row",agencyData);

    const [listEnterprises,setlistEnterprises] = useState([]);
    const [listCities,setlistCities] = useState([]);
    const [listContact,setlistContact] = useState([]);
    const [listEmail,setlistEmail] = useState([]);
    const [listPhones,setlistPhones] = useState([]);
    const [listCitiEnt,setlistCitiEnt] = useState([]);
    const [listAgencies,setlistAgencies] = useState([]);


    useEffect(() => {
        const getData = () => {
           
            setIsLoading(true);
            /*
            UserService.getCitys().then(ent1=>{setlistCities(ent1); 
                UserService.getEnterprise().then(ent=>{setlistEnterprises(ent);
                    UserService.getCityEnt().then(ent=>{setlistCitiEnt(ent);
                        UserService.getContact().then(ent=>{setlistContact(ent);
                            UserService.getEmails().then(ent=>{setlistEmail(ent);
                                UserService.getPhones().then(ent=>{setlistPhones(ent);
                                    setIsLoading(false)    
                                }).catch( () => { setErrorMessage("No se puede obtener los datos del servidor"); setIsLoading(false); }  );
                            }).catch( () => { setErrorMessage("No se puede obtener los datos del servidor"); setIsLoading(false); }  );
                        }).catch( () => { setErrorMessage("No se puede obtener los datos del servidor"); setIsLoading(false); }  );
                    }).catch( () => { setErrorMessage("No se puede obtener los datos del servidor"); setIsLoading(false); }  );
                }).catch( () => { setErrorMessage("No se puede obtener los datos del servidor"); setIsLoading(false); }  );
            }).catch( () => { setErrorMessage("No se puede obtener los datos del servidor"); setIsLoading(false); }  );
            
           */
     
        UserService.getContact().then(cont => {setlistContact(cont);  
            UserService.getEnterprise().then(ent=>{setlistEnterprises(ent);
                UserService.getAgencies().then(agen=>{setlistAgencies(agen);
                    setIsLoading(false)    
                }).catch( () => { setErrorMessage("No se puede obtener los datos del servidor"); setIsLoading(false); }  );
            }).catch( () => { setErrorMessage("No se puede obtener los datos del servidor"); setIsLoading(false); }  );
        }).catch( () => { setErrorMessage("No se puede obtener los datos del servidor"); setIsLoading(false); }  );
            
           
           
            
            
            
        };
        
        getData();
    }, []);
   // console.log(listCities);
   //console.log("empresas");
//console.log(listEnterprises);
//console.log("Contactos");
//console.log(listContact);
//console.log("Agencias", listAgencies);


    const enterprisesData = useMemo(() => {
        

        let computedEnterprises = listContact.map(client => {
            const idEnt = client;
           
            const otherRecord = listEnterprises.find(({id: candidateid}) => (candidateid===idEnt.enterprise.enterprise_id));
           
           
            return otherRecord ? {
                                 ...client,["ent_name"]:otherRecord.name,
                                
                                
                                    } : client;
        });
        /*
        console.log("Contacts:  ",{listContact});
        let computedEnterprises = listContact.map(client => {
            const idEnt = client;
            console.log("id contact ",{idEnt});
            const otherRecord = listEmail.find(({owner: candidateid}) => (candidateid===idEnt.id));
           
            console.log("EmailFound  ",{otherRecord});
            return otherRecord ? {
                                 ...client,["email"]:otherRecord.email,
                                 ...client,["id_mail"]:otherRecord.id,
                                
                                    } : client;
        });
       
        computedEnterprises = computedEnterprises.map(client => {
            const idEnt = client;
            console.log("unido ",{idEnt});
            const otherRecord = listPhones.find(({owner: candidateid}) => (candidateid===idEnt.id));
            console.log("phones  ",{otherRecord});
            return otherRecord ? {
                                 ...client,["id_phone"]:otherRecord.id,
                                 ...client,["phone"]:otherRecord.phone,
                                 ...client,["intern"]:otherRecord.intern,
                                    } : client;
        });
        //----------------------------------------------------------------------------------

        computedEnterprises = computedEnterprises.map(client => {
            const idEnt = client;
            console.log("unido1 ",{idEnt});
            const otherRecord = listCitiEnt.find(({id: candidateid}) => (candidateid===idEnt.city_enterprise));
            console.log("enterprise  ",{otherRecord});
            return otherRecord ? {
                                 ...client,["id_ent"]:otherRecord.enterprise,
                                 ...client,["id_city"]:otherRecord.city,
                                 

                                    } : client;
        });


        computedEnterprises = computedEnterprises.map(client => {
            const idEnt = client;
            console.log("unido1 ",{idEnt});
            const otherRecord = listEnterprises.find(({id: candidateid}) => (candidateid===idEnt.id_ent));
            console.log("enterprise  ",{otherRecord});
            return otherRecord ? {
                                 ...client,["name_ent"]:otherRecord.name,
                                 ...client,["address"]:otherRecord.address,
                                    } : client;
        });

        computedEnterprises = computedEnterprises.map(client => {
            const idEnt = client;
            console.log("unido4 ",{idEnt});
            const otherRecord = listCities.find(({id: candidateid}) => (candidateid===idEnt.id_city));
            console.log("city  ",{listCities});
            return otherRecord ? {
                                 ...client,["city"]:otherRecord.city_name,
                                 ...client,["country"]:otherRecord.country,
                                 ...client,["city_state"]:otherRecord.city_state,
                              
                                    } : client;
        });


        console.log("unido Total");
        console.log(computedEnterprises);
        
      
        */
        //Current Page slice
        return computedEnterprises;
    }, [listEnterprises,isLoading]);


    
    //**************** */
    
    const [filterTextReq, setFilterTextReq] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = enterprisesData.filter(
        item => ( ((item.ent_name && item.ent_name.toLowerCase().includes(entSearch.toLowerCase())  )
        && ( (item.name_contact + " " + item.last_name_contact) && (item.name_contact + " " + item.last_name_contact).toLowerCase().includes(nameSearch.toLowerCase())  )
       
        ))
  );
        console.log("filter: ",filteredItems)
    //console.log("search: ",entSearch);
    //console.log("Search1: ", nameSearch);
    //****************************** */
    //console.log("Search(out usememo):",search);
    console.log("show",show);
    return (
         
            
           <>
            
           <div className="row w-100">
                <div className="col mb-3 col-12 text-center">
                
                {//console.log(isLoading)
                }
                   { isLoading ? <LoadingSpinner/> :

                    <div>
                        
                        <table className="table table-striped">
                              
                                
                              <DataTable
                              columns={columns1}
                              data={filteredItems}
                              pagination
                              paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                              
                              noDataComponent="No hay registros"
                              persistTableHead
                            customStyles={customStyles}
                              defaultSortFieldId = "1"
                              highlightOnHover
                              paginationPerPage={30}
                              paginationComponentOptions={paginationComponentOptions}
                              /> 
                          

                          
                          
                         
              </table>
                    </div>
                    
                    }
                </div>
            </div>
           </>

           
            
           
        
    );
};

export default DataTable1;