import axios from 'axios';
import authHeader from './auth-header';
//const API_URL = "http://localhost:8000/";
const API_URL = "https://bbapi.pythonanywhere.com/";

class UserService {

  postEnterprise(ename, enit,ecity,ecountry,estate) {
    return axios.post(API_URL + "enterprises/enterprises/",
    {
      state: true,
      name: ename,
      nit: enit,
      city_name: ecity,
      country: ecountry,
      city_state: estate
    },
      { headers: authHeader() },
    );
  }

  putEnterprise(ename, enit,ecity,ecountry,estate, id) {
    return axios.put(API_URL + "enterprises/enterprises/" + id + "/",
      {
        state: true,
        name: ename,
        nit: enit,
        city_name: ecity,
        country: ecountry,
        city_state: estate
      },
      { headers: authHeader() },
    );
  }

  postCity( ecity,edepartment) {
    return axios.post(API_URL + "enterprises/city/",
      {
        state: true,
        city_name: ecity,
        department: edepartment,
        
      },
      { headers: authHeader() },
    );
  }

  putCity(ecity, edepartment, id) {
    return axios.put(API_URL + "enterprises/city/" + id + "/",
      {
        state: true,
        city_name: ecity,
        department: edepartment,
      },
      { headers: authHeader() },
    );
  }

  postDepartment(edepartment, ecountry) {
    return axios.post(API_URL + "enterprises/department/",
      {
        state: true,
        name: edepartment,
        country: ecountry,
        
      },
      { headers: authHeader() },
    );
  }
  putDepartment(edepartment, ecountry, id) {
    return axios.put(API_URL + "enterprises/department/" + id + "/",
      {
        state: true,
        name: edepartment,
        country: ecountry,
      },
      { headers: authHeader() },
    );
  }
  postCountry(ecountry) {
    return axios.post(API_URL + "enterprises/country/",
      {
        state: true,
        name: ecountry,

      },
      { headers: authHeader() },
    );
  }
  putCountry(ecountry, id) {
    return axios.patch(API_URL + "enterprises/country/" + id + "/",
      {
        state: true,
        name: ecountry,
      },
      { headers: authHeader() },
    );
  } 
  postContact(ename, elastName, ephone,ecellphone,eintern,eemail,eagen,eent) {
    return axios.post(API_URL + "enterprises/contact/",
    {
      id: 0,
      state: true,
      name: ename,
      last_name: elastName,
      phone: ephone,
      cellphone: ecellphone,
      intern: eintern,
      email: eemail,
      agency : eagen,
      enterprise: eent
    },
      { headers: authHeader() },
    );
  }
  putContact(ename, elastName, ephone,ecellphone,eintern,eemail,eagen,eent, id) {
    return axios.put(API_URL + "enterprises/contact/" + id + "/",
    {
      id: 0,
      state: true,
      name: ename,
      last_name: elastName,
      phone: ephone,
      cellphone: ecellphone,
      intern: eintern,
      email: eemail,
      agency : eagen,
      enterprise: eent
    },
      { headers: authHeader() },
    );
  }
  postEmail(eemail,idOwner) {
    return axios.post(API_URL + "enterprises/email/",
      {
        state: true,
        email: eemail,
        contact: idOwner,
      },
      { headers: authHeader() },
    );
  }
  putEmail(idOwner, eemail, id) {
    return axios.put(API_URL + "enterprises/emails/" + id + "/",
      {
        state: true,
        email: eemail,
        owner: idOwner,
      },
      { headers: authHeader() },
    );
  }
  postPhone(ephone,ecellphone,eintern, idOwner) {
    return axios.post(API_URL + "enterprises/phone/",
      {
        state: true,
        phone: ephone,
        cellphone : ecellphone,
        intern: eintern,
        contact: idOwner,
      },
      { headers: authHeader() },
    );
  }
  putPhone(ephone,ecellphone, eintern, idOwner, id) {
    return axios.put(API_URL + "enterprises/phone/" + id + "/",
      {
        state: true,
        phone: ephone,
        cellphone : ecellphone,
        intern: eintern,
        contact: idOwner,
      },
      { headers: authHeader() },
    );
  }
  postAgency(enameagen,ename,ephone,eemail) {
    return axios.post(API_URL + "enterprises/agency/",
    {
      state: true,
      name_agency:enameagen,
      name: ename,
      phone: ephone,
      email: eemail
    },
      { headers: authHeader() },
    );
  }
  putAgency(enameagen,ename,ephone,eemail, id) {
    return axios.put(API_URL + "enterprises/agency/" + id + "/",
    {
      state: true,
      name_agency: enameagen,
      name: ename,
      phone: ephone,
      email: eemail
    },
      { headers: authHeader() },
    );
  }
  postSucursal(eaddress,eenterp,ecity) {
    return axios.post(API_URL + "enterprises/sucursal/",
      {
        state: true,
        address: eaddress,
        enterprise : eenterp,
        city: ecity,
        
      },
      { headers: authHeader() },
    );
  }
  putSucursal(eaddress,eenterp, ecity, id) {
    return axios.put(API_URL + "enterprises/sucursal/" + id + "/",
      {
        state: true,
        address: eaddress,
        enterprise : eenterp,
        city: ecity,
      },
      { headers: authHeader() },
    );
  }
   postRequest(req_num,req_dat,req_supp,req_mer,req_wei,req_val,req_vol,req_ori,req_des,req_obs,req_tt,req_cae,req_caes,req_cb,req_cbs,
              req_cs,req_css,req_tae,req_taes,req_tb,req_tbs,req_v,req_vs,req_use,req_con,req_rou,req_cus,req_sup_sel,req_exp)
  {
  
    return axios.post(API_URL + "request/request/" ,
    {
      
        state: true,
        request_number: req_num,
        begin_date: req_dat,
        contact_supplier: req_supp,
        merchandise: req_mer,
        weight: req_wei,
        value: req_val,
        volume: req_vol,
        origin: req_ori,
        destination: req_des,
        observations: req_obs,
        transport_type: req_tt,
        carreta_abierta_enlon: req_cae,
        carreta_abierta_enlon_status: req_caes,
        carreta_baul: req_cb,
        carreta_baul_status: req_cbs,
        carreta_saider: req_cs,
        carreta_saider_status: req_css,
        truck_abierto_enlon:req_tae,
        truck_abierto_enlon_status: req_taes,
        truck_baul: req_tb,
        truck_baul_status: req_tbs,
        vagon: req_v,
        vagon_status: req_vs,
        status1: "",
        user: req_use,
        request_contact: req_con,
        request_route: req_rou,
        request_custom: req_cus,
        supplier_selection : req_sup_sel,
        exporter : req_exp
    },
      { headers: authHeader() },
    );
    
  }

  putRequest(req_num,req_dat,req_supp,req_mer,req_wei,req_val,req_vol,req_ori,req_des,req_obs,req_tt,req_cae,req_caes,req_cb,req_cbs,
    req_cs,req_css,req_tae,req_taes,req_tb,req_tbs,req_v,req_vs,req_use,req_con,req_rou,req_cus,req_id,req_sup_sel,req_exp)
{

return axios.put(API_URL + "request/request/"+req_id + "/" ,
{

state: true,
request_number: req_num,
begin_date: req_dat,
contact_supplier: req_supp,
merchandise: req_mer,
weight: req_wei,
value: req_val,
volume: req_vol,
origin: req_ori,
destination: req_des,
observations: req_obs,
transport_type: req_tt,
carreta_abierta_enlon: req_cae,
carreta_abierta_enlon_status: req_caes,
carreta_baul: req_cb,
carreta_baul_status: req_cbs,
carreta_saider: req_cs,
carreta_saider_status: req_css,
truck_abierto_enlon:req_tae,
truck_abierto_enlon_status: req_taes,
truck_baul: req_tb,
truck_baul_status: req_tbs,
vagon: req_v,
vagon_status: req_vs,
status1: "",
user: req_use,
request_contact: req_con,
request_route: req_rou,
request_custom: req_cus,
supplier_selection : req_sup_sel,
exporter : req_exp
},
{ headers: authHeader() },
);

  }
  postQuote(q_number,q_date,q_nsur_sta,q_insur_val,q_validi,q_valida_sta,q_valida_desc,q_concept1,q_amount1,q_descrip1,q_concept2,
            q_amount2,q_descrip2,q_concept3,q_amount3,q_descrip3,q_concept4,q_amount4,q_descrip4,q_concept5,q_amount5,q_descrip5,q_user,q_request,
            q_con1Stat,q_con2Stat,q_con3Stat,q_con4Stat,q_con5Stat,q_total) {
    return axios.post(API_URL + "quotes/quotes/",
    {
      state: true,
      quote_number: q_number,
      quote_date: q_date,
      insurance_status: q_nsur_sta,
      insurance_value: q_insur_val,
      validity: q_validi,
      validation_status: q_valida_sta,
      validation_description: q_valida_desc,
      concept1_status : q_con1Stat,
      concept1: q_concept1,
      amount1: q_amount1,
      description1: q_descrip1,
      concept2_status : q_con2Stat,
      concept2: q_concept2,
      amount2: q_amount2,
      description2: q_descrip2,
      concept3_status : q_con3Stat,
      concept3: q_concept3,
      amount3: q_amount3,
      description3: q_descrip3,
      concept4_status : q_con4Stat,
      concept4: q_concept4,
      amount4: q_amount4,
      description4: q_descrip4,
      concept5_status : q_con5Stat,
      concept5: q_concept5,
      amount5: q_amount5,
      description5: q_descrip5,
      user: q_user,
      request_parent: q_request,
      totalAmount : q_total
      
    },
      { headers: authHeader() },
    );
  }
  putQuote(q_number,q_date,q_nsur_sta,q_insur_val,q_validi,q_valida_sta,q_valida_desc,q_concept1,q_amount1,q_descrip1,q_concept2,
    q_amount2,q_descrip2,q_concept3,q_amount3,q_descrip3,q_concept4,q_amount4,q_descrip4,q_concept5,q_amount5,q_descrip5,q_user,q_request,
    q_con1Stat,q_con2Stat,q_con3Stat,q_con4Stat,q_con5Stat,q_total,q_id) {
  return axios.put(API_URL + "quotes/quotes/"+q_id+"/",
  {
  state: true,
  quote_number: q_number,
  quote_date: q_date,
  insurance_status: q_nsur_sta,
  insurance_value: q_insur_val,
  validity: q_validi,
  validation_status: q_valida_sta,
  validation_description: q_valida_desc,
  concept1_status : q_con1Stat,
  concept1: q_concept1,
  amount1: q_amount1,
  description1: q_descrip1,
  concept2_status : q_con2Stat,
  concept2: q_concept2,
  amount2: q_amount2,
  description2: q_descrip2,
  concept3_status : q_con3Stat,
  concept3: q_concept3,
  amount3: q_amount3,
  description3: q_descrip3,
  concept4_status : q_con4Stat,
  concept4: q_concept4,
  amount4: q_amount4,
  description4: q_descrip4,
  concept5_status : q_con5Stat,
  concept5: q_concept5,
  amount5: q_amount5,
  description5: q_descrip5,
  user: q_user,
  request_parent: q_request,
  totalAmount : q_total

  },
  { headers: authHeader() },
  );
  }
  postSupplier(sup_name,sup_cnpj,sup_city,sup_country,sup_state) {
    return axios.post(API_URL + "supplier/supplier/",
    {
      state: true,
      name : sup_name,
      cnpj : sup_cnpj,
      city_name : sup_city,
      country : sup_country,
      city_state :sup_state 

    },
      { headers: authHeader() },
    );
  }
  putSupplier(sup_name,sup_cnpj,sup_city,sup_country,sup_state,sup_id) {
    return axios.put(API_URL + "supplier/supplier/"+sup_id+"/",
    {
      state: true,
      name : sup_name,
      cnpj : sup_cnpj,
      city_name : sup_city,
      country : sup_country,
      city_state :sup_state 

    },
      { headers: authHeader() },
    );
  }
  postFrontierAgency(front_agency,front_name,front_phone,front_email) {
    return axios.post(API_URL + "supplier/frontierAgency/",
    {
      state: true,
      name_agency :front_agency,
      name :front_name,
      phone :front_phone,
      email : front_email
    },
      { headers: authHeader() },
    );
  }
  putFrontierAgency(front_agency,front_name,front_phone,front_email,front_id) {
    return axios.put(API_URL + "supplier/frontierAgency/"+front_id+"/",
    {
      state: true,
      name_agency :front_agency,
      name :front_name,
      phone :front_phone,
      email : front_email
    },
      { headers: authHeader() },
    );
  }
  postDispatcher(disp_agency,disp_name,dips_fullname,disp_phone,disp_email) {
    return axios.post(API_URL + "supplier/dispatcher/",
    {
      state: true,
      frontier_agency : disp_agency ,
      name_dispatcher : disp_name,
      full_name: dips_fullname,
      phone : disp_phone,
      email : disp_email
    },
      { headers: authHeader() },
    );
  }
  putDispatcher(disp_agency,disp_name,dips_fullname,disp_phone,disp_email,disp_id) {
    return axios.put(API_URL + "supplier/dispatcher/"+disp_id+"/",
    {
      state: true,
      frontier_agency : disp_agency ,
      name_dispatcher : disp_name,
      full_name: dips_fullname,
      phone : disp_phone,
      email : disp_email
    },
      { headers: authHeader() },
    );
  }

  postContactSupplier(cont_supplier,cont_frontier,cont_name,cont_lastName,cont_cellphone,cont_email) {
    return axios.post(API_URL + "supplier/contactSupplier/",
    {
      state: true,
      supplier_parent : cont_supplier, 
      frontier_agency : cont_frontier,
      name: cont_name,
      last_name : cont_lastName,
      cellphone : cont_cellphone,
      email : cont_email
    },
      { headers: authHeader() },
    );
  }
  putContactSupplier(cont_supplier,cont_frontier,cont_name,cont_lastName,cont_cellphone,cont_email,cont_id) {
    return axios.put(API_URL + "supplier/contactSupplier/"+cont_id+"/",
    {
      state: true,
      supplier_parent : cont_supplier, 
      frontier_agency : cont_frontier,
      name: cont_name,
      last_name : cont_lastName,
      cellphone : cont_cellphone,
      email : cont_email
    },
      { headers: authHeader() },
    );
  }




  postServiceQuotation(s_user,s_number,s_date,s_quote,s_forklift_st,s_forklift,s_crane_st,s_crane,s_steve_st,s_steve,s_pallet_st,s_pallet,s_service1_st,s_service1_desc,s_service1_am,s_service2_st,
    s_service2_desc,s_service2_am,s_time,s_address,s_pricing,s_cost,s_exchange,s_invoice,s_obs,s_wq_status, s_wq_enterprise,s_wq_contact,s_wq_exporter, s_wq_weight, s_wq_volume ) {
    return axios.post(API_URL + "service/service/",
    {
      state: true,
      user: s_user,
      service_number: s_number,
      service_date: s_date,
      quote_parent : s_quote,
      forklift_status: s_forklift_st,
      forklift: s_forklift,
      crane_status: s_crane_st,
      crane: s_crane,
      stevedores_status: s_steve_st,
      stevedores: s_steve,
      pallet_truck_status: s_pallet_st,
      pallet_truck: s_pallet,
      service1_status: s_service1_st,
      service1_descrip: s_service1_desc,
      service1_amount: s_service1_am,
      service2_status: s_service2_st,
      service2_descrip: s_service2_desc,
      service2_amount: s_service2_am,
      estimated_time: s_time,
      delivery_adress: s_address,
      quotation_pricing: s_pricing,
      quotation_cost: s_cost,
      dollar_exchange : s_exchange,
      invoice_status : s_invoice,
      observations : s_obs,
      quote_parent_status : s_wq_status ,
      enterprise_name : s_wq_enterprise ,
      enterprise_contact : s_wq_contact,  
      exporter : s_wq_exporter ,
      weight : s_wq_weight ,
      volume : s_wq_volume
    },
    { headers: authHeader() },
    );
}



putServiceQuotation(s_user,s_number,s_date,s_quote,s_forklift_st,s_forklift,s_crane_st,s_crane,s_steve_st,s_steve,s_pallet_st,s_pallet,s_service1_st,s_service1_desc,s_service1_am,s_service2_st,
  s_service2_desc,s_service2_am,s_time,s_address,s_pricing,s_cost,s_exchange,s_invoice,s_obs,s_wq_status, s_wq_enterprise,s_wq_contact,s_wq_exporter, s_wq_weight, s_wq_volume,s_id) {
return axios.put(API_URL + "service/service/"+s_id+"/",
{
  state: true,
  user: s_user,
  service_number: s_number,
  service_date: s_date,
  quote_parent : s_quote,
  forklift_status: s_forklift_st,
  forklift: s_forklift,
  crane_status: s_crane_st,
  crane: s_crane,
  stevedores_status: s_steve_st,
  stevedores: s_steve,
  pallet_truck_status: s_pallet_st,
  pallet_truck: s_pallet,
  service1_status: s_service1_st,
  service1_descrip: s_service1_desc,
  service1_amount: s_service1_am,
  service2_status: s_service2_st,
  service2_descrip: s_service2_desc,
  service2_amount: s_service2_am,
  estimated_time: s_time,
  delivery_adress: s_address,
  quotation_pricing: s_pricing,
  quotation_cost: s_cost,
  dollar_exchange : s_exchange,
  invoice_status : s_invoice,
  observations : s_obs,
  quote_parent_status : s_wq_status ,
  enterprise_name : s_wq_enterprise ,
  enterprise_contact : s_wq_contact,  
  exporter: s_wq_exporter ,
  weight : s_wq_weight ,
  volume : s_wq_volume
},
{ headers: authHeader() },
);
}

postConsolidated( c_consolidate_number,c_consolidate_date,c_driver_br,c_plate_br,c_cpf_br,c_transport_br,c_driver_bo,c_plate_bo,c_ci_bo,c_transport_bo,c_user) {
  return axios.post(API_URL + "consolidated/consolidated/",
  {
    state: true,
    consolidate_number: c_consolidate_number,
    consolidate_date: c_consolidate_date,
    driver_br: c_driver_br,
    plate_br: c_plate_br,
    cpf_br: c_cpf_br,
    transport_br: c_transport_br,
    driver_bo: c_driver_bo,
    plate_bo: c_plate_bo,
    ci_bo: c_ci_bo,
    transport_bo: c_transport_bo,
    frontier_date: null,
    getIn_agesa_date: null,
    getOut_agesa_date: null,
    arrive_custom_date: null,
    discharge_custom_date: null,
    user: c_user
  },
  { headers: authHeader() },
  );
}

patchConsolidated( c_consolidate_number,c_consolidate_date,c_driver_br,c_plate_br,c_cpf_br,c_transport_br,c_driver_bo,c_plate_bo,c_ci_bo,c_transport_bo,c_user,c_id) {
  return axios.patch(API_URL + "consolidated/consolidated/"+c_id+"/",
  {
    state: true,
    consolidate_number: c_consolidate_number,
    consolidate_date: c_consolidate_date,
    driver_br: c_driver_br,
    plate_br: c_plate_br,
    cpf_br: c_cpf_br,
    transport_br: c_transport_br,
    driver_bo: c_driver_bo,
    plate_bo: c_plate_bo,
    ci_bo: c_ci_bo,
    transport_bo: c_transport_bo,
   
    user: c_user
  },
  { headers: authHeader() },
  );
}
patchConsolidatedDates(c_front,c_agesaIn,c_agesaOut,c_arrive,c_discharge ,c_observations,c_id) {
  return axios.patch(API_URL + "consolidated/consolidated/"+c_id+"/",
  {
    
    frontier_date: c_front,
    getIn_agesa_date: c_agesaIn,
    getOut_agesa_date: c_agesaOut,
    arrive_custom_date: c_arrive,
    discharge_custom_date: c_discharge,
    observations: c_observations,
  },
  { headers: authHeader() },
  );
}

postConsolidatedDetail( c_detail_number,c_fiscal_note,c_quantity,c_crt,c_consolidated,c_quote) {
  return axios.post(API_URL + "consolidated/consolidated_detail/",
  {
    state: true,
    detail_number: c_detail_number,
    fiscal_note: c_fiscal_note,
    quantity: c_quantity,
    crt: c_crt,
    consolidated: c_consolidated,
    quote: c_quote
  },
  { headers: authHeader() },
  );
}

putConsolidatedDetail( c_detail_number,c_fiscal_note,c_quantity,c_crt,c_consolidated,c_quote,c_id) {
  return axios.put(API_URL + "consolidated/consolidated_detail/"+c_id+"/",
  {
    state: true,
    detail_number: c_detail_number,
    fiscal_note: c_fiscal_note,
    quantity: c_quantity,
    crt: c_crt,
    consolidated: c_consolidated,
    quote: c_quote
  },
  { headers: authHeader() },
  );
}

postDriver(driv_name,driv_last,driv_cpf,driv_plate,driv_zone,driv_cellphone,driv_bank,driv_bank_agency,driv_bank_acco,driv_acco_name,driv_cpf_cnpj,driv_pix) {
  return axios.post(API_URL + "driver/driver/",
  {
    state: true,
    name: driv_name,
    last_name: driv_last,
    cpf: driv_cpf,
    plate: driv_plate,
    zone: driv_zone,
    cellphone: driv_cellphone,
    bank: driv_bank,
    bank_agency: driv_bank_agency,
    bank_account: driv_bank_acco,
    account_name: driv_acco_name,
    cpf_cnpj:driv_cpf_cnpj,
    pix: driv_pix,

  },
    { headers: authHeader() },
  );
}
putDriver(driv_name,driv_last,driv_cpf,driv_plate,driv_zone,driv_cellphone,driv_bank,driv_bank_agency,driv_bank_acco,driv_acco_name,driv_cpf_cnpj,driv_pix,driv_id) {
  return axios.put(API_URL + "driver/driver/"+driv_id+"/",
  {
   
    state: true,
    name: driv_name,
    last_name: driv_last,
    cpf: driv_cpf,
    plate: driv_plate,
    zone: driv_zone,
    cellphone: driv_cellphone,
    bank: driv_bank,
    bank_agency: driv_bank_agency,
    bank_account: driv_bank_acco,
    account_name: driv_acco_name,
    cpf_cnpj:driv_cpf_cnpj,
    pix: driv_pix,

  },
    { headers: authHeader() },
  );
}

postTransporterAgency( trans_name,trans_bank,trans_bank_agency,trans_bank_acco,trans_acco_name,trans_cnpj,trans_cellphone,trans_pix,trans_zone ) {
  return axios.post(API_URL + "transporter/transporterAgency/",
  {
    state: true,
    name: trans_name,
    bank: trans_bank,
    bank_agency: trans_bank_agency,
    bank_account: trans_bank_acco,
    account_name: trans_acco_name,
    cnpj: trans_cnpj,
    cellphone: trans_cellphone,
    pix : trans_pix,
    zone: trans_zone,
  },
    { headers: authHeader() },
  );
}
putTransporterAgency( trans_name,trans_bank,trans_bank_agency,trans_bank_acco,trans_acco_name,trans_cnpj ,driv_cellphone,trans_pix,trans_zone,trans_id) {
  return axios.put(API_URL + "transporter/transporterAgency/"+trans_id+"/",
  {
   
    state: true,
    name: trans_name,
    bank: trans_bank,
    bank_agency: trans_bank_agency,
    bank_account: trans_bank_acco,
    account_name: trans_acco_name,
    cnpj: trans_cnpj,
    cellphone: driv_cellphone,
    pix : trans_pix,
    zone: trans_zone,
  },
    { headers: authHeader() },
  );
}
patchObserDriver(driv_observations,driv_id) {
  return axios.patch(API_URL + "driver/driver/"+driv_id+"/",
  {
    observations: driv_observations,
  },
  { headers: authHeader() },
  );
}


patchObserTrans(trans_observations,trnas_id) {
  return axios.patch(API_URL + "transporter/transporterAgency/"+trnas_id+"/",
  {
    observations: trans_observations,
  },
  { headers: authHeader() },
  );
}

  getIdRequest(id){
    return axios.get(API_URL + "request/idcounter/"+id+"/", { headers: authHeader() }).then((response) => { if (response.data) { return response.data; } else { console.log("error getIdRequest"); } });
  }
  getEnterprise() {
    return axios.get(API_URL + "enterprises/enterprises/", { headers: authHeader() }).then((response) => { if (response.data) { return response.data; } else { console.log("error getEmpresa"); } });
  }
  getCitys() {
    return axios.get(API_URL + "enterprises/city/", { headers: authHeader() }).then((response) => { if (response.data) { return response.data; } else { console.log("error getCitys"); } });
  }
  getCountry() {
    return axios.get(API_URL + "enterprises/country/", { headers: authHeader() }).then((response) => { if (response.data) { return response.data; } else { console.log("error getCitys"); } });
  }
  getDepartment() {
    return axios.get(API_URL + "enterprises/department/", { headers: authHeader() }).then((response) => { if (response.data) { return response.data; } else { console.log("error getCitys"); } });
  }
  getContact() {
    return axios.get(API_URL + "enterprises/contact/", { headers: authHeader() }).then((response) => { if (response.data) { return response.data; } else { console.log("error getContact"); } });
  }

  getEmails() {
    return axios.get(API_URL + "enterprises/email/", { headers: authHeader() }).then((response) => { if (response.data) { return response.data; } else { console.log("error getEmail"); } });
  }
  getPhones() {
    return axios.get(API_URL + "enterprises/phone/", { headers: authHeader() }).then((response) => { if (response.data) { return response.data; } else { console.log("error getPhones"); } });
  }
  getAgencies() {
    return axios.get(API_URL + "enterprises/agency/", { headers: authHeader() }).then((response) => { if (response.data) { return response.data; } else { console.log("error getAgencies"); } });
  }

  getSucursal() {
    return axios.get(API_URL + "enterprises/sucursal/", { headers: authHeader() }).then((response) => { if (response.data) { return response.data; } else { console.log("error getPhones"); } });
  }

  getCustom(){
    return axios.get(API_URL + "customs/customs/", { headers: authHeader() }).then((response) => { if (response.data) { return response.data; } else { console.log("error getPhones"); } });
 
  }
  getRoute(){
    return axios.get(API_URL + "routes/routes/", { headers: authHeader() }).then((response) => { if (response.data) { return response.data; } else { console.log("error getPhones"); } });
 
  }
  getRequest(){
    return axios.get(API_URL + "request/request/", { headers: authHeader() }).then((response) => { if (response.data) { return response.data; } else { console.log("error getRequest"); } });
 
  }
  getQuotes(){
    return axios.get(API_URL + "quotes/quotes/", { headers: authHeader() }).then((response) => { if (response.data) { return response.data; } else { console.log("error getQuote"); } });
 
  }
  getSupplier(){
    return axios.get(API_URL + "supplier/supplier/", { headers: authHeader() }).then((response) => { if (response.data) { return response.data; } else { console.log("error getSupplier"); } });
 
  }
  getFrontierAgency(){
    return axios.get(API_URL + "supplier/frontierAgency/", { headers: authHeader() }).then((response) => { if (response.data) { return response.data; } else { console.log("error getFrontierAgency"); } });
 
  }
  getDispatcher(){
    return axios.get(API_URL + "supplier/dispatcher/", { headers: authHeader() }).then((response) => { if (response.data) { return response.data; } else { console.log("error getDispatcher"); } });
 
  }
  getContactSupplier(){
    return axios.get(API_URL + "supplier/contactSupplier/", { headers: authHeader() }).then((response) => { if (response.data) { return response.data; } else { console.log("error getContactSupplier"); } });
 
  }
  getServiceQuotation(){
    return axios.get(API_URL + "service/service/", { headers: authHeader() }).then((response) => { if (response.data) { return response.data; } else { console.log("error getServiceQuotation"); } });
 
  }
  getConsolidated(){
    return axios.get(API_URL + "consolidated/consolidated/", { headers: authHeader() }).then((response) => { if (response.data) { return response.data; } else { console.log("error getConsolidated"); } });
 
  }
  getConsolidatedDetail(id_consolidated){
    return axios.get(API_URL + "consolidated/consolidated_detail/"+id_consolidated+"/list_by_consolidated/", { headers: authHeader() }).then((response) => { if (response.data) { return response.data; } else { console.log("error getConsolidatedDetails"); } });
 
  }
  getDriver(){
    return axios.get(API_URL + "driver/driver/", { headers: authHeader() }).then((response) => { if (response.data) { return response.data; } else { console.log("error getDrivers"); } });
 
  }

  getTransporterAgency(){
    return axios.get(API_URL + "transporter/transporterAgency/", { headers: authHeader() }).then((response) => { if (response.data) { return response.data; } else { console.log("error getTransportAgency"); } });
 
  }

  deleteContact(id){
    return axios.delete(API_URL + "enterprises/contact/" + id+ "/", { headers: authHeader() }).then((response) => { if (response.data) { return response.data; } else { console.log("error deleteContact"); } });
 
  }
  deleteEnterprise(id){
    return axios.delete(API_URL + "enterprises/enterprises/" + id+ "/", { headers: authHeader() }).then((response) => { if (response.data) { return response.data; } else { console.log("error deleteEnterprises"); } });
 
  }

  deleteAgency(id){
    return axios.delete(API_URL + "enterprises/agency/" + id+ "/", { headers: authHeader() }).then((response) => { if (response.data) { return response.data; } else { console.log("error deleteAgency"); } });
 
  }
  deleteQuote(id){
    return axios.delete(API_URL + "quotes/quotes/" + id+ "/", { headers: authHeader() }).then((response) => { if (response.data) { return response.data; } else { console.log("error deleteQuote"); } });
 
  }
  deleteRequest(id){
    return axios.delete(API_URL + "request/request/" + id+ "/", { headers: authHeader() }).then((response) => { if (response.data) { return response.data; } else { console.log("error deleteRequest"); } });
 
  }
  deleteRoute(id){
    return axios.delete(API_URL + "routes/routes/" + id+ "/", { headers: authHeader() }).then((response) => { if (response.data) { return response.data; } else { console.log("error deleteRoute"); } });
 
  }
  deleteUser(id){
    return axios.delete(API_URL + "users/users/" + id+ "/", { headers: authHeader() }).then((response) => { if (response.data) { return response.data; } else { console.log("error deleteUser"); } });
 
  }
  deleteConsolidated(id){
    return axios.delete(API_URL + "consolidated/consolidated/" + id+ "/", { headers: authHeader() }).then((response) => { if (response.data) { return response.data; } else { console.log("error deleteConsolidated"); } });
 
  }
  deleteConsolidatedDetail(id){
    return axios.delete(API_URL + "consolidated/consolidated_detail/" + id+ "/", { headers: authHeader() }).then((response) => { if (response.data) { return response.data; } else { console.log("error deleteConsolidatedDetail"); } });
 
  }
  deleteDriver(id){
    return axios.delete(API_URL + "driver/driver/" + id+ "/", { headers: authHeader() }).then((response) => { if (response.data) { return response.data; } else { console.log("error deleteDriver"); } });
 
  }
  deleteTransporter(id){
    return axios.delete(API_URL + "transporter/transporterAgency/" + id+ "/", { headers: authHeader() }).then((response) => { if (response.data) { return response.data; } else { console.log("error deleteTransporter"); } });
 
  }
}
export default new UserService();
