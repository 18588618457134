
import React, {Component} from 'react';


import 'bootstrap/dist/css/bootstrap.min.css';

import 'bootstrap/dist/js/bootstrap.bundle.min';
import { useEffect, useState } from 'react';
//import './body_container_clientes.css';
import {BsFillPlusCircleFill} from 'react-icons/bs'
import {BiEdit} from 'react-icons/bi'
import Select from 'react-select';
import UserService    from '../../services/user.service';
import { useForm } from 'react-hook-form';
import DataTableContactSupplier from '../data_table_proveedores/data_table_proveedores';
import userService from '../../services/user.service';
import FilterComponent from '../FilterComponent/filterComponent';
import LoadingSpinner from '../loading/loadingComponent';



const BodyContainerContactsSuppliers = () => {


    const emptyOptions =[ {}] ;

    const [newContactSupplier,setNewContactSupplier] = useState(false);
    
    const [editContactSupplier,setEditContactSupplier] = useState(false);

    const [update,setUpdate] = useState(false);

     //funcion que se llama al apretar el boton para crear nuevo contacto
     const newContactSupplierClick = () => { 
       
        setNewContactSupplier(true); 
        setEditContactSupplier(false); 
        cleanSup();
        cleanSupTemp();
        cleanContactSupplier();
      
    };
   
    //actualiza la tabla de datos
    async function updateTable  ()  {
        
        try{
            setUpdate(true);
            var responseSuppliers = await UserService.getSupplier();
            if(responseSuppliers)
            {
                setSuppliers(responseSuppliers)
            }
            else
            {
                console.log(responseSuppliers);
            }
            
            
            var responseFrontierAgency = await UserService.getFrontierAgency();
            if(responseFrontierAgency)
            {
                setFrontierAgencies(responseFrontierAgency)
            }
            {
                console.log(responseFrontierAgency);
            }
            setUpdate(false);

        }
        catch(error){
            console.error(error);
            setUpdate(false);

        }
        
        
    }

    //estructura de datos que recibe los datos de un contacto de proveedor a editar
    const [contactEdit,setcontactEdit] = useState(
        {
            id: 0,
            name_contact: "",
            last_name_contact: "",
            cellphone: "",
            email: "",
            supplier_parent: {
                supplier_id: 0,
                supplier_name: "",
                supplier_cnpj: "",
                supplier_city: "",
                supplier_country: "",
                supplier_city_state: ""
            },
            frontier_agency: {
                agency_id : 0,
                agency_name_agency: "" ,
                agency_name : "" ,
                agency_phone :"" ,
                agency_email : "" ,
                
            },
           
          }
    );
    const cleanContactSupplierEdit = () => {
        setcontactEdit(
            {
                id: 0,
                name_contact: "",
                last_name_contact: "",
                cellphone: "",
                email: "",
                supplier_parent: {
                    supplier_id: 0,
                    supplier_name: "",
                    supplier_cnpj: "",
                    supplier_city: "",
                    supplier_country: "",
                    supplier_city_state: ""
                },
                frontier_agency: {
                    agency_id : 0,
                    agency_name_agency: "" ,
                    agency_name : "" ,
                    agency_phone :"" ,
                    agency_email : "" ,
                    
                },
              }
        );
    }

    //arrays de los proveedores registradas en las base de datos
    const [suppliers,setSuppliers] = useState(
        [ 
           
        ]
        
    );
       //estructura para guardar una nueva empresa
   const [sup,setSup] = useState( 
    {
        id: 0,
        name: "",
        cnpj: "",
        city_name: "",
        country: "BRASIL",
        city_state: ""
        
    }   
);
    const cleanSup = () => {
        setSup(
            {
                id: 0,
                name: "",
                cnpj: "",
                city_name: "",
                country: "BRASIL",
                city_state: ""
                
            }   
        );
    }

const [supTemp,setSupTemp] = useState( 
    {
        id: 0,
        name: "",
        cnpj: "",
        city_name: "",
        country: "",
        city_state: ""
       
    }   
);

const cleanSupTemp = () => {
    setSupTemp(
        {
            id: 0,
            name: "",
            cnpj: "",
            city_name: "",
            country: "",
            city_state: ""
           
        }   
    );
}

const onChangeSup = (e) => {
    
        setSup({...sup,
            [e.target.name]: e.target.value.toUpperCase()
        });
    
       
       
       //console.log("sup: ", sup);
    
};

//arrays de las despachantes registradas en las base de datos
const [dispatchers,setDispatchers] = useState(
    [ 
        
    ]
    
);
   //estructura para guardar una nueva despachante
const [disp,setDisp] = useState( 
    {
        id: 0,
        name_dispatcher: "",
        full_name: "",
        phone: "",
        email: "",
        frontier_agency: 0
      }
);
const cleanDisp = () => {
    setDisp(
        {
            id: 0,
            name_dispatcher: "",
            full_name: "",
            phone: "",
            email: "",
            frontier_agency: 0
        }  
    );
}

const [dispTemp,setDispTemp] = useState( 
    {
        id: 0,
        name_dispatcher: "",
        full_name: "",
        phone: "",
        email: "",
        frontier_agency: {
            agency_id: 0,
            agency_name_agency: "",
            agency_name: "",
            agency_phone: "",
            agency_email: ""
        }
    }   
);

const cleanDispTemp = () => {
setDispTemp(
    {
        id: 0,
        name_dispatcher: "",
        full_name: "",
        phone: "",
        email: "",
        frontier_agency: {
            agency_id: 0,
            agency_name_agency: "",
            agency_name: "",
            agency_phone: "",
            agency_email: ""
        }
       
    }   
);
}

const onChangeDisp = (e) => {

    if(e.target.name === "email")
    {
        setDisp({...disp,
            [e.target.name]: e.target.value.toLowerCase()
        });
    }
    else
    {
        setDisp({...disp,
            [e.target.name]: e.target.value.toUpperCase()
        });
    }
    
    
   
   
   //console.log("disp: ", disp);

};
//------------------------------------
const [frontierAgencies,setFrontierAgencies] = useState([]);
//estructura para guardar una nueva despachante
const [front,setFront] = useState( 
    {
        id: 0,
        name_agency: "",
        name: "",
        phone: "",
        email: ""
      }
);
const cleanFront = () => {
    setFront(
        {
            id: 0,
            name_agency: "",
            name: "",
            phone: "",
            email: ""
        }  
    );
}

const [frontTemp,setFrontTemp] = useState( 
    {
        id: 0,
        name_agency: "",
        name: "",
        phone: "",
        email: ""
    }   
);

const cleanFrontTemp = () => {
    setFrontTemp(
    {
        id: 0,
        name_agency: "",
        name: "",
        phone: "",
        email: ""
       
    }   
);
}

const onChangeFront = (e) => {

    if(e.target.name === "email")
    {
        setFront({...front,
            [e.target.name]: e.target.value.toLowerCase()
        });
    }
    else
    {
        setFront({...front,
            [e.target.name]: e.target.value.toUpperCase()
        });
    }
    
    
   
   
   //console.log("disp: ", disp);

};


//----------------------------------------
const [contactSupplier,setContactSupplier] = useState(
    
        {
            id: 0,
            name_contact: "",
            last_name_contact: "",
            cellphone: "",
            email: "",
            supplier_parent: 0,
            frontier_agency: 0
        }
    
  
); 
const cleanContactSupplier = () => {
    setContactSupplier(
        {
            id: 0,
            name_contact: "",
            last_name_contact: "",
            cellphone: "",
            email: "",
            supplier_parent: 0,
            frontier_agency: 0
        }
    
    );
}

const onChangeContactSupplier = (e) => {
    if(e.target.name === "email")
    {
        setContactSupplier({...contactSupplier,
            [e.target.name]: e.target.value.toLowerCase()
        });
    }
    else{
        setContactSupplier({...contactSupplier,
            [e.target.name]: e.target.value.toUpperCase()
        });
    }
    

    ////console.log("contactSupplier: ", contactSupplier);

};





//------------------FUNCIONES PARA CREAR O MODIFICAR UN PROVEEDOR
const [createSupplier, setCreateSupplier]= useState(false);   
const [modifSupplier,setModifSupplier] = useState(false);

const createSupClick = () => { 
   setCreateSupplier(true);  
   setModifSupplier(false);    
   cleanSup();
   cleanSupTemp();
   
      
};
const cancelCreateSupClick = () => { 
   setCreateSupplier(false); 
   setModifSupplier(false); 
   cleanSup();
   cleanSupTemp();
   
};
const modifSupClick = (e) =>{
   if(supTemp.name === "" || supTemp.cnpj === "" || supTemp === "" )
   {
       console.log("Error");
   } 
   else{
       setModifSupplier(true);
       setCreateSupplier(false);
   
     
       
       setSup({...sup,["city_state"]: supTemp.city_state,["country"]: supTemp.country ,["city_name"]: supTemp.city_name,["cnpj"]: supTemp.cnpj,["name"]: supTemp.name,["id"]:supTemp.id});
       ////console.log("sup temp : " ,supTemp);
    }
   
     
    
}
//guarda empresa en base de datos  
const crearSupplier = () => {
   if(sup.name === "" || sup.cnpj === "" || sup === "")
   {
       console.log("Error");
   }
   else{
       ////console.log("sup : " , sup)
      
      UserService.postSupplier((sup.name),(sup.cnpj),(sup.city_name),(sup.country),(sup.city_state)).then((response)=>{if(response.data){console.log(response.data);UserService.getSupplier().then(supplier =>setSuppliers(supplier));} else{console.log("error postSupplier");}});
      setCreateSupplier(false);
      ////console.log("sup : " , sup)
   }
   
   
   };
   const modificarSupplier = () =>{

       if(sup.name === "" || sup.cnpj === "" || sup === "")
       {
           //console.log("Error");
       }
       else{
           
         
           UserService.putSupplier((sup.name),(sup.cnpj),(sup.city_name),(sup.country),(sup.city_state),(sup.id)).then((response)=>{if(response.data){console.log(response.data);UserService.getSupplier().then(supplier => setSuppliers(supplier)  );} else{console.log("error putSupplier");}});
           
           setModifSupplier(false);
        //   //console.log("Empresas",empresas);
        //   //console.log("Ent",sup);
       }
   

   }

   const onChangeSelectSup = (a) =>{
       
       
         
       setSupTemp( suppliers.find( obj => {return obj.id === parseInt(a.value)}));
       setContactSupplier({...contactSupplier,["supplier_parent"]: a.value}) 
       ////console.log("contactSupplier: ", contactSupplier);
        //console.log("sup temp : " ,supTemp);
     };
   
//------------------FUNCIONES PARA CREAR O MODIFICAR UN DESPACHANTE-------------
const [createDispatcher, setCreateDispatcher]= useState(false);   
const [modifDispatcher,setModifDispatcher] = useState(false);

const createDispClick = () => { 
    setCreateDispatcher(true);  
    setModifDispatcher(false);    
   cleanDisp();
   cleanDispTemp();
   
      
};
const cancelCreateDispClick = () => { 
    setCreateDispatcher(false); 
    setModifDispatcher(false); 
    setCreateFrontier(false);
    setModifFrontier(false);
    cleanDisp();
    cleanDispTemp();
   
};
const modifDispClick = (e) =>{
   if(dispTemp.name_dispatcher === "" || dispTemp.full_name === "" || dispTemp === "" || dispTemp.frontier_agency == 0)
   {
       //console.log("Error");
   } 
   else{
        setModifDispatcher(true);
        setCreateDispatcher(false);
   
     
        //console.log("modif Agen1",disp)
       setDisp({...disp,["frontier_agency"]: dispTemp.frontier_agency,["email"]: dispTemp.email,["phone"]: dispTemp.phone ,["full_name"]: dispTemp.full_name,["name_dispatcher"]: dispTemp.name_dispatcher,["id"]:dispTemp.id});
        
       //console.log("modif Agen",dispTemp);
        //console.log("modif Agen1",disp)
    }
   
     
    
}
//guarda empresa en base de datos  
const crearDispatcher = () => {
   if(disp.full_name === "" || disp.name_dispatcher === "" || disp === "" || dispTemp.frontier_agency == 0)
   {
       console.log("Error");
       console.log("Disp: ",disp)
   }
   else{
       ////console.log("sup : " , sup)
      
      UserService.postDispatcher((disp.frontier_agency),(disp.name_dispatcher),(disp.full_name),(disp.phone),(disp.email)).then((response)=>{if(response.data){console.log(response.data);UserService.getDispatcher().then(dispatcher =>setDispatchers(dispatcher));} else{console.log("error postdispatcher");}});
      setCreateDispatcher(false);
      //console.log("disp : " , disp)
   }
   
   
   };
   const modificarDispatcher = () =>{

       if(disp.full_name === "" || disp.name_dispatcher === "" || sup === "" || dispTemp.frontier_agency.agency_id == 0)
       {
           console.log("Error");
       }
       else{
           
         
           UserService.putDispatcher((disp.frontier_agency),(disp.name_dispatcher),(disp.full_name),(disp.phone),(disp.email),(disp.id)).then((response)=>{if(response.data){console.log(response.data);UserService.getDispatcher().then(dispatcher => setDispatchers(dispatcher)  );} else{console.log("error putdispatcher");}});
           
           setModifDispatcher(false);
        //   //console.log("Empresas",empresas);
        //   //console.log("Ent",sup);
       }
   

   }

   const onChangeSelectDisp = (e) =>{
       
       
         
       setDispTemp( dispatchers.find( obj => {return obj.id === parseInt(e.value)}));
       setContactSupplier({...contactSupplier,["dispatcher_parent"]: parseInt(e.value)}) 
       //console.log("agentemp select: ", dispTemp);
       //console.log(" ContactSupplier: ", contactSupplier);
       //console.log(" Value: ", parseInt(e.value));
     };

     //------------------FUNCIONES PARA CREAR O MODIFICAR UNA AGENCIA FRONTERIZA-------------
const [createFrontier, setCreateFrontier]= useState(false);   
const [modifFrontier,setModifFrontier] = useState(false);

const createFrontClick = () => { 
    setCreateFrontier(true);  
    setModifFrontier(false);    
   cleanFront();
   cleanFrontTemp();
   
      
};
const cancelCreateFrontClick = () => { 
    setCreateFrontier(false); 
    setModifFrontier(false); 
    cleanFront();
   cleanFrontTemp();
   
};
const modifFrontClick = (e) =>{
   if(frontTemp.name_agency === "" || frontTemp.name === "" || frontTemp === "" )
   {
       console.log("Error");
       console.log("disp Temp: ",dispTemp)
   } 
   else{
        setModifFrontier(true);
        setCreateFrontier(false);
   
     
        //console.log("modif Agen1",disp)
       setFront({...front,["email"]: frontTemp.email,["phone"]: frontTemp.phone ,["name"]: frontTemp.name,["name_agency"]: frontTemp.name_agency,["id"]:frontTemp.id});
        
       //console.log("modif Agen",dispTemp);
        //console.log("modif Agen1",disp)
    }
   
     
    
}
//guarda empresa en base de datos  
const crearFrontier = () => {
   if(disp.name === "" || disp.name_agency === "" || disp === "" )
   {
       console.log("Error");
   }
   else{
       ////console.log("sup : " , sup)
      
      UserService.postFrontierAgency((front.name_agency),(front.name),(front.phone),(front.email)).then((response)=>{if(response.data){console.log(response.data);UserService.getFrontierAgency().then(frontier =>setFrontierAgencies(frontier));} else{console.log("error postFrontierAgency");}});
      setCreateFrontier(false);
      //console.log("disp : " , disp)
   }
   
   
   };
   const modificarFrontier = () =>{

       if(disp.name === "" || disp.name_agency === "" || disp === "")
       {
           console.log("Error");
       }
       else{
           
         
        UserService.putFrontierAgency((front.name_agency),(front.name),(front.phone),(front.email),(front.id)).then((response)=>{if(response.data){console.log(response.data);UserService.getFrontierAgency().then(frontier =>setFrontierAgencies(frontier));} else{console.log("error postFrontierAgency");}});
           setModifFrontier(false);
        //   //console.log("Empresas",empresas);
        //   //console.log("Ent",sup);
       }
   

   }

   const onChangeSelectFront = (e) =>{
       
       
         
       setFrontTemp( frontierAgencies.find( obj => {return obj.id === parseInt(e.value)}));

      
       setContactSupplier({...contactSupplier,["frontier_agency"]: parseInt(e.value)}) 
       //console.log("agentemp select: ", dispTemp);
       //console.log(" ContactSupplier: ", contactSupplier);
       //console.log(" Value: ", parseInt(e.value));
     };
     console.log(" select: ", frontTemp);
     console.log(" Disp: ", disp);

     const saveContactSupplier = () => { 
        if(contactSupplier.name_contact === "" || contactSupplier.frontier_agency === "" || contactSupplier.supplier_parent === "" || contactSupplier.last_name_contact == "")
        {
            console.log("Error");
        }
        else{
            ////console.log("sup : " , sup)
           
           UserService.postContactSupplier(contactSupplier.supplier_parent,contactSupplier.frontier_agency,contactSupplier.name_contact, contactSupplier.last_name_contact, contactSupplier.cellphone, contactSupplier.email).then((response)=>{if(response.data){console.log(response.data);setNewContactSupplier(false);} else{console.log("error postContactSuppliero");}});
           
           ////console.log("contactSupplier : " , contactSupplier)
        }
  
     };
     const modifContactSupplier = () => { 
        if(contactSupplier.name_contact === "" || contactSupplier.frontier_agency === "" || contactSupplier.supplier_parent === "" || contactSupplier.last_name_contact == "")
        {
            //console.log("Error");
        }
        else{
            ////console.log("sup : " , sup)
           
           UserService.putContactSupplier(contactSupplier.supplier_parent,contactSupplier.frontier_agency,contactSupplier.name_contact, contactSupplier.last_name_contact, contactSupplier.cellphone, contactSupplier.email,contactSupplier.id).then((response)=>{if(response.data){console.log(response.data);setEditContactSupplier(false);} else{console.log("error postContactSuppliero");}});
           
           ////console.log("contactSupplier : " , contactSupplier)
        }
   
     };
     
     //------------------------------------------------------------
     //estado de carga de datos de la base de datos
     const [isLoading, setIsLoading] = useState(false);

     //capturador de mensaje en caso de error al obtener datos de la base de datos
     const [errorMessage, setErrorMessage] = useState("");
    //------------------------------------------------


         
    useEffect(()=>{ 
        async function getData  ()  {
           
            try{
                setUpdate(true);
                var responseSuppliers = await UserService.getSupplier();
                
                if(responseSuppliers)
                {
                    setSuppliers(responseSuppliers)
                    
                }
                else
                {
                    console.log(responseSuppliers);
                }
                
                
                var responseFrontierAgency = await UserService.getFrontierAgency();
                if(responseFrontierAgency)
                {
                    setFrontierAgencies(responseFrontierAgency)
                }
                {
                    console.log(responseFrontierAgency);
                }
            
                setUpdate(false);
    
    
            }
            catch(error){
                console.error(error);
                setUpdate(false);
    
            }
       
            
    };
    getData();    
    },[]);  
           
    ////console.log("supTemp:",supTemp);
    ////console.log("Enterprises:",suppliers);
    ////console.log("ContactSuppliers:",contacts);
    ////console.log("errors:",errorMessage);
   // //console.log("contactEdit:",contactEdit);
    //console.log("Agencias",dispatchers);
    //console.log("AgenTemp:",dispTemp);
    //console.log("agencia",dispatchers);
    

        const [filterTextEnt, setFilterTextEnt] = useState("");
        const [filterTextName, setFilterTextName] = useState("");
        
        const enterpriseFilterMemo = React.useMemo(() => {
            return (
                <FilterComponent onFilter={e => setFilterTextEnt(e.target.value)}  filterText={filterTextEnt}  name = "Proveedor"/>
            );
        }, [filterTextEnt]);
        const enterpriseFilterNameMemo = React.useMemo(() => {
            return (
                <FilterComponent onFilter={e => setFilterTextName(e.target.value)}  filterText={filterTextName}  name = "Contacto del Proveedor"/>
            );
        }, [filterTextName]);

        const contactEditUpdate = React.useMemo(() => {
             setSupTemp( suppliers.find( obj => {return obj.id === contactEdit.supplier_parent.supplier_id}));
             setFrontTemp( frontierAgencies.find( obj => {return obj.id === contactEdit.frontier_agency.agency_id}));
                editContactSupplier && setContactSupplier({...contactSupplier,  ["frontier_agency"]: contactEdit.frontier_agency.agency_id,
                                                        ["supplier_parent"]: contactEdit.supplier_parent.supplier_id,
                                                        ["email"]:contactEdit.email,
                                                        ["cellphone"]:contactEdit.cellphone,
                                                        ["last_name_contact"]:contactEdit.last_name_contact,
                                                        ["name_contact"]:contactEdit.name_contact,
                                                        ["id"]:contactEdit.id,
                                                        });
                
            setCreateSupplier(false);
            setModifSupplier(false);
            setCreateFrontier(false);
            setModifFrontier(false);
            

        }, [editContactSupplier]);
        
        //-------------------------------------------------------------
        
       
        //console.log("Suppliers: ",suppliers);
        //console.log("Dispatchers: ",dispatchers)
    return ( 

      
            <div  className='BodyContainer container-fluid vh-100'>

           
            <div className='row'>

                <div id= 'sidebarMenu' className='col-2 bg-light vh-100 '>

                    <div className="mt-5 vh-100" >
                        
                        <div className="p-3" >
                            
                            
                           {enterpriseFilterMemo}

                        </div>
                        <div className="p-3">
                            {enterpriseFilterNameMemo}
                        
                        
                        </div>
                       
                    </div>
                </div>

                <div className='col-10'>

               

                { !(editContactSupplier || newContactSupplier)  && ( 
                    <div className='container-fluid'>
                     
                        <div className='row'>
                        
                            <div className="col-10 p-3">
                                
                                <h3 className="text-center">LISTA DE CONTACTOS DE PROVEEDORES</h3>
                                
                            </div>
                            <div className='col'>
                                <div className="m-1 ">
                                    <button type="button" className="btn " onClick={updateTable} disabled= {update}>
                                        
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg">
                                        <path fill="none" stroke="#000" stroke-width="2" d="M1.7507,16.0022 C3.3517,20.0982 7.3367,23.0002 11.9997,23.0002 C18.0747,23.0002 22.9997,18.0752 22.9997,12.0002 M22.2497,7.9982 C20.6487,3.9012 16.6627,1.0002 11.9997,1.0002 C5.9247,1.0002 0.9997,5.9252 0.9997,12.0002 M8.9997,16.0002 L0.9997,16.0002 L0.9997,24.0002 M22.9997,0.0002 L22.9997,8.0002 L14.9997,8.0002">
                                            </path>
                                    </svg>


                                    </button>
                                </div>
                                
                            </div>

                            
                            
                            <div className='col'>
                                <div className="m-1 ">
                                    <button type="button" className="btn " onClick={newContactSupplierClick}>
                                        
                                        <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                        </svg>

                                    </button>
                                </div>
                                
                            </div>

                        </div>
                        
                        <div className='row'>
                       {
                            !update && (
                                <DataTableContactSupplier
                                entSearch = {filterTextEnt}
                                nameSearch = {filterTextName}
                                setIDSelected = {setcontactEdit}
                                setEditEnt = {setEditContactSupplier}
                                
                                /> 
                            )  
                       }
                        
                      
                        
                        
                         
                           
                        </div>
                                            
                     
                    </div>
                    )}


    



                

                { (editContactSupplier || newContactSupplier) && (
                    <div>

                        <div className='m-4 row '>
                            <div className='col'> 
                             <button className='btn' type = "button"  onClick={()=>{setNewContactSupplier(false); setEditContactSupplier(false)}}>
                                 <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.5 280.6l192 160c20.6 17.2 52.5 2.8 52.5-24.6V96c0-27.4-31.9-41.8-52.5-24.6l-192 160c-15.3 12.8-15.3 36.4 0 49.2zm256 0l192 160c20.6 17.2 52.5 2.8 52.5-24.6V96c0-27.4-31.9-41.8-52.5-24.6l-192 160c-15.3 12.8-15.3 36.4 0 49.2z"></path>
                                </svg>
                             </button>   
                              
                            </div>
                            {(newContactSupplier) &&<div  className='col-10'>
                                <h1 >
                                    INGRESO DE UN NUEVO CONTACTO DE PROVEEDOR
                                </h1>
                            </div>}
                            {(editContactSupplier) &&<div  className='col-10'>
                                <h1 >
                                    MODIFICACION DE CONTACTO DE PROVEEDOR EXISTENTE
                                </h1>
                            </div>}

                            
                            <div className='col-1'>
                                    <button type="button" className="btn " onClick={updateTable}>
                                        
                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg">
                                            <path fill="none" stroke="#000" stroke-width="2" d="M1.7507,16.0022 C3.3517,20.0982 7.3367,23.0002 11.9997,23.0002 C18.0747,23.0002 22.9997,18.0752 22.9997,12.0002 M22.2497,7.9982 C20.6487,3.9012 16.6627,1.0002 11.9997,1.0002 C5.9247,1.0002 0.9997,5.9252 0.9997,12.0002 M8.9997,16.0002 L0.9997,16.0002 L0.9997,24.0002 M22.9997,0.0002 L22.9997,8.0002 L14.9997,8.0002">
                                                </path>
                                        </svg>
    
    
                                    </button>
                            </div>
                            
                            
                            


                        </div>
                        
                        <div className='m-5 row' >
                            
                            <div className='col-md-6 col ' >
                                {
                                    (!createSupplier && !modifSupplier) && (   
                                        <div className='m-2 input-group'>
                                            <span className=' input-group-text col-md-3'>Proveedor</span>
                                        

                                            <Select
                                                id='select_ent'
                                                className = 'col-md-7'
                                                placeholder="Seleccione una empresa" 
                                                onChange= {(a) => onChangeSelectSup(a)}
                                                defaultValue = { editContactSupplier && ((option) => ({label: contactEdit.supplier_parent.supplier_name,value: contactEdit.supplier_parent.supplier_id})) }
                                            options = {(suppliers.length !== 0)?  suppliers.map((option) => ({ label : option.name, value: option.id })) : ([])}
                                                isSearchable = {true}
                                                noOptionsMessage={() => 'NINGUN PROVEEDOR REGISTRADO'}
                                            />

                                            <button className='btn col-md-1 btn-outline-secondary ' type='button'  onClick={createSupClick}><BsFillPlusCircleFill fontSize={"1.5em"}/></button>
                                            <button className='btn col-md-1 btn-outline-secondary' type='button'  onClick={modifSupClick}>
                                            <BiEdit fontSize={"1.5em"}/>
                                            </button>
                                        </div>
                                )
                                }

                                
                                {
                                    ( modifSupplier || createSupplier) && (
                                        <div className='border rounded border-5 m-2' >   
                                            
                                            <div className='p-2 input-group'>
                                                <label className='col-md-3 input-group-text'>Nombre</label>
                                                <input 
                                                type="text"  
                                                
                                                name='name'
                                                
                                                placeholder="Nombre de la Empresa"
                                                className='col-md-9 form-control'
                                                value={sup.name}
                                                onChange={onChangeSup} 
                                                />
                                            </div>
                                            <div className='p-2 input-group'>
                                                <label  className='col-md-3 input-group-text'>CNPJ</label>
                                                <input 
                                                    type="text"  
                                                
                                                    
                                                    name = "cnpj"
                                                    placeholder="CNPJ de la Empresa"
                                                    className='col-md-9 form-control'
                                                    value={sup.cnpj}
                                                    onChange={onChangeSup} 
                                                />
                                            </div>
                                            <div className='p-2 input-group'>
                                                <label className='col-md-3 input-group-text'>Ciudad</label>
                                                <input 
                                                type="text"  
                                                
                                                    name='city_name'
                                                
                                                placeholder="Ciudad de la Empresa"
                                                className='col-md-9 form-control'
                                                value={sup.city_name}
                                                onChange={onChangeSup} 
                                                />
                                            </div>
                                            <div className='p-2 input-group'>
                                                <label className='col-md-3 input-group-text'>Estado</label>
                                                <input 
                                                type="text"  
                                                
                                                    name='city_state'
                                                
                                                placeholder="Departamento de la Empresa"
                                                className='col-md-9 form-control'
                                                value={sup.city_state}
                                                onChange={onChangeSup} 
                                                />
                                            </div>
                                            <div className='p-2 input-group'>
                                                <label className='col-md-3 input-group-text'>País</label>
                                                <input 
                                                type="text"  
                                                
                                                    name='country'
                                                
                                                placeholder="Pais de la Empresa"
                                                className='col-md-9 form-control'
                                                value={sup.country}
                                                onChange={onChangeSup} 
                                                />
                                            </div>
                                            {createSupplier && <button onClick={crearSupplier} className = "rounded m-2">CREAR EMPRESA</button>}
                                            {modifSupplier && <button onClick={modificarSupplier} className = "rounded m-2">MODIFICAR EMPRESA</button>}
                                           {/* <button type="Submit" className = "rounded m-2">CREAR</button>*/}
                                            <button onClick={cancelCreateSupClick}className = "rounded m-2">CANCELAR</button>
                                            
                                        </div>

                                        

                                        
                                    )
                                }

                                   
                                      
                                        <div className='m-2 input-group'>
                                            <label htmlFor="" className='col-md-3 input-group-text'>Nombre del Contacto </label>
                                            <input 
                                                name="name_contact" 
                                                type="text"
                                                placeholder="Nombre de la Persona de Contacto del Proveedor"
                                                className='col-md-9 form-control'
                                                value={contactSupplier.name_contact}
                                                onChange={onChangeContactSupplier}
                                                
                                            />
                                        </div>
                                        <div className='m-2 input-group'>
                                            <label htmlFor="" className='col-md-3 input-group-text'>Apellido del Contacto </label>
                                            <input 
                                                name="last_name_contact" 
                                                type="text"
                                                placeholder="Apellidos de la Persona de Contacto del Proveedor"
                                                className='col-md-9 form-control'
                                                value={contactSupplier.last_name_contact}
                                                onChange={onChangeContactSupplier}
                                                
                                            />
                                        </div>
                                        <div className='m-2 input-group'>
                                            <label htmlFor="" className='col-md-3  input-group-text'>Correo</label>
                                            <input 
                                            
                                                name="email" 
                                                type="text"
                                                placeholder="Correo de la Persona de Contacto del Proveedor"
                                                className='col-md-9 form-control'
                                                value={contactSupplier.email}
                                                onChange={onChangeContactSupplier}
                                                
                                            />
                                        </div>

                                        <div className='m-2 input-group'>
                                            <label htmlFor="" className='col-md-3  input-group-text'>Celular</label>
                                            <input 
                                            
                                                name="cellphone" 
                                                type="text"
                                                placeholder="Celular de la Persona de Contacto del Proveedor"
                                                className='col-md-9 form-control'
                                                value={contactSupplier.cellphone}
                                                onChange={onChangeContactSupplier}
                                                
                                            />
                                        </div>
                                       
                                  
                                    
                                   


                                


                                
                            </div>
                              
                            

                            <div className='col-md-6 col'>

                                

                                
                                            {
                                                (!createFrontier && !modifFrontier) && (
                                                    <div className='p-2 input-group'>
                                                        <span className=' input-group-text col-md-3'>Despachante</span>
                                                    

                                                        <Select
                                                            id='select_agen'
                                                            className = 'col-md-7'
                                                            placeholder="Seleccione una agencia Despachante" 
                                                            onChange= {(e) => onChangeSelectFront(e)}
                                                            defaultValue = { editContactSupplier && ((option) => ({label: contactEdit.frontier_agency.agency_name_agency ,value: contactEdit.frontier_agency.agency_id})) }
                                                            options = {(frontierAgencies.length !== 0 ) ? (frontierAgencies.map((option) => ({ label : option.name_agency + " - " +option.name, value: option.id })) ) :  ([]) }   
                                                            isSearchable = {true}
                                                            noOptionsMessage={() => 'NINGUNA AGENCIA DESPACHANTE REGISTRADA'}
                                                        />

                                                        <button className='btn col-md-1 btn-outline-secondary ' type='button'  onClick={createFrontClick}><BsFillPlusCircleFill fontSize={"1.5em"}/></button>
                                                        <button className='btn col-md-1 btn-outline-secondary' type='button'  onClick={modifFrontClick}>
                                                        <BiEdit fontSize={"1.5em"}/>
                                                        </button>
                                                    </div>
                                                )
                                            }

                                            {
                                                (createFrontier || modifFrontier) && (
                                                    <div className='border rounded border-5 m-2' >
                                                        <div className='p-2 input-group'>
                                                            <label  className='col-md-3 input-group-text'>Despachante</label>
                                                            <input 
                                                                type="text"  
                                                            
                                                                
                                                                name = "name_agency"
                                                                placeholder= "Nombre de la empresa Despachante"
                                                                className='col-md-9 form-control'
                                                                value={front.name_agency}
                                                                onChange={onChangeFront} 
                                                            />
                                                        </div>
                                                        <div className='p-2 input-group'>
                                                            <label  className='col-md-3 input-group-text'>Contacto</label>
                                                            <input 
                                                                type="text"  
                                                            
                                                                
                                                                name = "name"
                                                                placeholder="Nombre de la persona de contacto"
                                                                className='col-md-9 form-control'
                                                                value={front.name}
                                                                onChange={onChangeFront} 
                                                            />
                                                        </div>
                                                        <div className='p-2 input-group'>
                                                            <label className='col-md-3 input-group-text'>Teléfono</label>
                                                            <input 
                                                            type="text"  
                                                            
                                                                name='phone'
                                                            
                                                            placeholder="Telefono del contacto "
                                                            className='col-md-9 form-control'
                                                            value={front.phone}
                                                            onChange={onChangeFront} 
                                                            />
                                                        </div>
                                                        <div className='p-2 input-group'>
                                                            <label className='col-md-3 input-group-text'>Correo</label>
                                                            <input 
                                                            type="text"  
                                                            
                                                                name='email'
                                                            
                                                            placeholder="Correo del contacto"
                                                            className='col-md-9 form-control'
                                                            value={front.email}
                                                            onChange={onChangeFront} 
                                                            />
                                                        </div>
                                                        {createFrontier && <button onClick={crearFrontier} className = "rounded m-2">CREAR DESPACHANTE</button>}
                                                        {modifFrontier && <button onClick={modificarFrontier} className = "rounded m-2">MODIFICAR DESPACHANTE</button>}
                                                    
                                                        <button onClick={cancelCreateFrontClick}className = "rounded m-2">CANCELAR</button>
                                                    </div>
                                                )
                                            }
                                
                                
                                
                              
                               
                                

                            </div>
                            
                            

                            
                            {(newContactSupplier) && <button  onClick={saveContactSupplier}>
                            CREAR NUEVO CONTACTO DE UN PROVEEDOR
                              </button>
                            }

                            {(editContactSupplier) && <button  onClick={modifContactSupplier}>
                            MODIFICAR CONTACTO DE UN PROVEEDOR
                              </button>
                            }
                        </div>

                        
                    </div>

                )

                }
                
                
                </div>
                
            </div>
           
        </div>
        
       
    );
}
export default BodyContainerContactsSuppliers
