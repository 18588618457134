
import React, {Component} from 'react';


import 'bootstrap/dist/css/bootstrap.min.css';

import 'bootstrap/dist/js/bootstrap.bundle.min';
import { useEffect, useState } from 'react';
//import './body_container_clientes.css';
import {BsFillPlusCircleFill} from 'react-icons/bs'
import {BiEdit} from 'react-icons/bi'
import Select from 'react-select';
import UserService    from '../../services/user.service';
import { useForm } from 'react-hook-form';
import DataTableTransporters from '../data_Table_Transporters/data_table_transporters';

import userService from '../../services/user.service';
import FilterComponent from '../FilterComponent/filterComponent';
import LoadingSpinner from '../loading/loadingComponent';



const BodyContainerTransporter = () => {


    const emptyOptions =[ {}] ;

    const [newTransporter,setNewTransporter] = useState(false);
    
    const [editTransporter,setEditTransporter] = useState(false);

    const [update,setUpdate] = useState(false);

     //funcion que se llama al apretar el boton para crear nuevo contacto
     const newTransporterClick = () => { 
       
        setNewTransporter(true); 
        setEditTransporter(false); 
      
        cleanTransporter();
      
    };
   
    
    //actualiza la tabla de datos
    function updateTable  ()  {
        setUpdate(true);
        setUpdate(false);
        
        
    }

    //estructura de datos que recibe los datos de un contacto de proveedor a editar
    const [transporterEdit,setTrasnporterEdit] = useState(
        {
            id: 0,
            state: true,
            name: "",
            bank: "",
            bank_agency: "",
            bank_account: "",
            account_name: "",
            cnpj: "",
            cellphone: "",
            pix : "",
            zone: "",
          }
    );
    const cleanContactSupplierEdit = () => {
        setTrasnporterEdit(
            {
                id: 0,
                state: true,
                name: "",
                bank: "",
                bank_agency: "",
                bank_account: "",
                account_name: "",
                cnpj: "",
                cellphone: "",
                pix : "",
                zone: "",
              
              }
        );
    }


//----------------------------------------
const [transporter,setTransporter] = useState(
    
        {
            id: 0,
            state: true,
            name: "",
            bank: "",
            bank_agency: "",
            bank_account: "",
            account_name: "",
            cnpj: "",
            cellphone: "",
            pix : "",
            zone: "",
            
        }
    
  
); 
const cleanTransporter = () => {
    setTransporter(
        {
            id: 0,
            state: true,
            name: "",
            bank: "",
            bank_agency: "",
            bank_account: "",
            account_name: "",
            cnpj: "",
            cellphone: "",
            pix : "",
            zone: "",
           
        }
    
    );
}

const onChangeTransporter = (e) => {
    
        setTransporter({...transporter,
            [e.target.name]: e.target.value.toUpperCase()
        });
   
    

    ////console.log("transporter: ", transporter);

};



     const saveTransporter = () => { 
        if(transporter.name === "" || transporter.bank === "" || transporter.bank_agency === "" || transporter.bank_account == "" || transporter.account_name == "" || transporter.cnpj == "" )
        {
            console.log("Error");
        }
        else{
            ////console.log("sup : " , sup)
           
           UserService.postTransporterAgency(transporter.name,transporter.bank,transporter.bank_agency, transporter.bank_account, transporter.account_name, transporter.cnpj,transporter.cellphone,transporter.pix,transporter.zone).then((response)=>{if(response.data){console.log(response.data);setNewTransporter(false);} else{console.log("error postTransporterAgency");}});
           
           ////console.log("transporter : " , transporter)
        }
  
     };
     const modifTransporter = () => { 
        if(transporter.name === "" || transporter.bank === "" || transporter.bank_agency === "" || transporter.bank_account == "" || transporter.account_name == "" || transporter.cnpj == "" )
        {
            //console.log("Error");
        }
        else{
            ////console.log("sup : " , sup)
           
           UserService.putTransporterAgency(transporter.name,transporter.bank,transporter.bank_agency, transporter.bank_account, transporter.account_name, transporter.cnpj,transporter.cellphone,transporter.pix,transporter.zone,transporter.id).then((response)=>{if(response.data){console.log(response.data);setEditTransporter(false);} else{console.log("error putTransporterAgency");}});
           
           ////console.log("transporter : " , transporter)
        }
   
     };
     
     //------------------------------------------------------------
     //estado de carga de datos de la base de datos
     const [isLoading, setIsLoading] = useState(false);

     //capturador de mensaje en caso de error al obtener datos de la base de datos
     const [errorMessage, setErrorMessage] = useState("");
    //------------------------------------------------


         
 
           
   
    

        const [filterTextPlate, setFilterTextPlate] = useState("");
        const [filterTextName, setFilterTextName] = useState("");
        
        
        const filterNameMemo = React.useMemo(() => {
            return (
                <FilterComponent onFilter={e => setFilterTextName(e.target.value)}  filterText={filterTextName}  name = "Nombre Transportadora"/>
            );
        }, [filterTextName]);

        const contactEditUpdate = React.useMemo(() => {
            
                editTransporter && setTransporter({...transporter,  
                                                        ["cellphone"]: transporterEdit.cellphone,
                                                        ["zone"]: transporterEdit.zone,
                                                        ["pix"]: transporterEdit.pix,
                                                        
                                                        ["cnpj"]: transporterEdit.cnpj,
                                                        ["account_name"]: transporterEdit.account_name,
                                                        ["bank_account"]:transporterEdit.bank_account,
                                                        ["bank_agency"]:transporterEdit.bank_agency,
                                                        ["bank"]:transporterEdit.bank,
                                                        ["name"]:transporterEdit.name,
                                                        ["id"]:transporterEdit.id,
                                                        });
                
          
            

        }, [editTransporter]);
        
        //-------------------------------------------------------------
   
       
        //console.log("Suppliers: ",suppliers);
        //console.log("Dispatchers: ",dispatchers)
    return ( 

      
            <div  className='BodyContainer container-fluid vh-100'>

           
            <div className='row'>

                <div id= 'sidebarMenu' className='col-2 bg-light vh-100 '>

                    <div className="mt-5 vh-100" >
                        
                       
                        <div className="p-3">
                            {filterNameMemo}
                        
                        
                        </div>
                       
                    </div>
                </div>

                <div className='col-10'>

               

                { !(editTransporter || newTransporter)  && ( 
                    <div className='container-fluid'>
                     
                        <div className='row'>
                        
                            <div className="col-11 p-3">
                                
                                <h3 className="text-center">LISTA DE AGENCIAS TRANSPORTADORAS</h3>
                                
                            </div>
                            

                            
                            
                            <div className='col'>
                                <div className="m-1 ">
                                    <button type="button" className="btn " onClick={newTransporterClick}>
                                        
                                        <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                        </svg>

                                    </button>
                                </div>
                                
                            </div>

                        </div>
                        
                        <div className='row'>
                       {
                            !update && (
                                <DataTableTransporters
                                
                                nameSearch = {filterTextName}
                                setIDSelected = {setTrasnporterEdit}
                                setEditTrans = {setEditTransporter}
                                
                                /> 
                            )  
                       }
                        
                      
                        
                        
                         
                           
                        </div>
                                            
                     
                    </div>
                    )}


    



                

                { (editTransporter || newTransporter) && (
                    <div>

                        <div className='m-4 row '>
                            <div className='col'> 
                             <button className='btn' type = "button"  onClick={()=>{setNewTransporter(false); setEditTransporter(false)}}>
                                 <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.5 280.6l192 160c20.6 17.2 52.5 2.8 52.5-24.6V96c0-27.4-31.9-41.8-52.5-24.6l-192 160c-15.3 12.8-15.3 36.4 0 49.2zm256 0l192 160c20.6 17.2 52.5 2.8 52.5-24.6V96c0-27.4-31.9-41.8-52.5-24.6l-192 160c-15.3 12.8-15.3 36.4 0 49.2z"></path>
                                </svg>
                             </button>   
                              
                            </div>
                            {(newTransporter) &&<div  className='col-10'>
                                <h1 >
                                    INGRESO DE AGENCIA TRANSPORTADORA
                                </h1>
                            </div>}
                            {(editTransporter) &&<div  className='col-10'>
                                <h1 >
                                    MODIFICACION DE AGENCIA TRANSPORTADORA
                                </h1>
                            </div>}

                            
                            <div className='col-1'>
                                    <button type="button" className="btn " onClick={updateTable}>
                                        
                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg">
                                            <path fill="none" stroke="#000" stroke-width="2" d="M1.7507,16.0022 C3.3517,20.0982 7.3367,23.0002 11.9997,23.0002 C18.0747,23.0002 22.9997,18.0752 22.9997,12.0002 M22.2497,7.9982 C20.6487,3.9012 16.6627,1.0002 11.9997,1.0002 C5.9247,1.0002 0.9997,5.9252 0.9997,12.0002 M8.9997,16.0002 L0.9997,16.0002 L0.9997,24.0002 M22.9997,0.0002 L22.9997,8.0002 L14.9997,8.0002">
                                                </path>
                                        </svg>
    
    
                                    </button>
                            </div>
                            
                            
                            


                        </div>
                        
                        <div className='m-5 row' >
                            
                            <div className='col-md-6 col ' >
                              

                                
                                

                                   
                                      
                                        <div className='m-2 input-group'>
                                            <label htmlFor="" className='col-md-3 input-group-text'>NOMBRE  </label>
                                            <input 
                                                name="name" 
                                                type="text"
                                                //placeholder="Nombre de la Persona de Contacto del Proveedor"
                                                className='col-md-9 form-control'
                                                value={transporter.name}
                                                onChange={onChangeTransporter}
                                                
                                            />
                                        </div>
                                        <div className='m-2 input-group'>
                                            <label htmlFor="" className='col-md-3  input-group-text'>CELULAR</label>
                                            <input 
                                            
                                                name="cellphone" 
                                                type="text"
                                                //placeholder="Celular de la Persona de Contacto del Proveedor"
                                                className='col-md-9 form-control'
                                                value={transporter.cellphone}
                                                onChange={onChangeTransporter}
                                                
                                            />
                                        </div>
                                        <div className='m-2 input-group'>
                                            <label htmlFor="" className='col-md-3  input-group-text'>ZONA</label>
                                            <input 
                                            
                                                name="zone" 
                                                type="text"
                                                //placeholder="Celular de la Persona de Contacto del Proveedor"
                                                className='col-md-9 form-control'
                                                value={transporter.zone}
                                                onChange={onChangeTransporter}
                                                
                                            />
                                        </div>
                                        <div className='m-2 input-group'>
                                            <label htmlFor="" className='col-md-3 input-group-text'>BANCO  </label>
                                            <input 
                                                name="bank" 
                                                type="text"
                                                //placeholder="Apellidos de la Persona de Contacto del Proveedor"
                                                className='col-md-9 form-control'
                                                value={transporter.bank}
                                                onChange={onChangeTransporter}
                                                
                                            />
                                        </div>
                                        <div className='m-2 input-group'>
                                            <label htmlFor="" className='col-md-3  input-group-text'>AGENCIA </label>
                                            <input 
                                            
                                                name="bank_agency" 
                                                type="text"
                                                //placeholder="Correo de la Persona de Contacto del Proveedor"
                                                className='col-md-9 form-control'
                                                value={transporter.bank_agency}
                                                onChange={onChangeTransporter}
                                                
                                            />
                                        </div>

                                        <div className='m-2 input-group'>
                                            <label htmlFor="" className='col-md-3  input-group-text'>CUENTA BANCARIA</label>
                                            <input 
                                            
                                                name="bank_account" 
                                                type="text"
                                                //placeholder="Celular de la Persona de Contacto del Proveedor"
                                                className='col-md-9 form-control'
                                                value={transporter.bank_account}
                                                onChange={onChangeTransporter}
                                                
                                            />
                                        </div>

                                        <div className='m-2 input-group'>
                                            <label htmlFor="" className='col-md-3  input-group-text'>FAVORECIDO</label>
                                            <input 
                                            
                                                name="account_name" 
                                                type="text"
                                                //placeholder="Celular de la Persona de Contacto del Proveedor"
                                                className='col-md-9 form-control'
                                                value={transporter.account_name}
                                                onChange={onChangeTransporter}
                                                
                                            />
                                        </div>

                                        <div className='m-2 input-group'>
                                            <label htmlFor="" className='col-md-3  input-group-text'>CNPJ</label>
                                            <input 
                                            
                                                name="cnpj" 
                                                type="text"
                                                //placeholder="Celular de la Persona de Contacto del Proveedor"
                                                className='col-md-9 form-control'
                                                value={transporter.cnpj}
                                                onChange={onChangeTransporter}
                                                
                                            />
                                        </div>
                                        <div className='m-2 input-group'>
                                            <label htmlFor="" className='col-md-3  input-group-text'>CLAVE PIX</label>
                                            <input 
                                            
                                                name="pix" 
                                                type="text"
                                                //placeholder="Celular de la Persona de Contacto del Proveedor"
                                                className='col-md-9 form-control'
                                                value={transporter.pix}
                                                onChange={onChangeTransporter}
                                                
                                            />
                                        </div>
                                        
                                       
                                  
                                    
                                   


                                


                            {(newTransporter) && <button className='btn btn-primary'  onClick={saveTransporter}>
                            CREAR NUEVA AGENCIA TRANSPORTADORA
                              </button>
                            }

                            {(editTransporter) && <button className='btn btn-primary' onClick={modifTransporter}>
                            MODIFICAR AGENCIA TRANSPORTADORA
                              </button>
                            }    
                            </div>
                              
                            

                            <div className='col-md-6 col'>

                                

                                
                                
                                
                                
                              
                               
                                

                            </div>
                            
                            

                            
                           
                        </div>

                        
                    </div>

                )

                }
                
                
                </div>
                
            </div>
           
        </div>
        
       
    );
}
export default BodyContainerTransporter
