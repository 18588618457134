
import React, {Component} from 'react';


import 'bootstrap/dist/css/bootstrap.min.css';

import 'bootstrap/dist/js/bootstrap.bundle.min';
import { useEffect, useState } from 'react';
//import './body_container_clientes.css';
import {BsFillPlusCircleFill} from 'react-icons/bs'
import {BiEdit} from 'react-icons/bi'
import Select from 'react-select';
import UserService    from '../../services/user.service';
import { useForm } from 'react-hook-form';
import DataTableDriver from '../data_table_drivers/data_table_drivers';

import userService from '../../services/user.service';
import FilterComponent from '../FilterComponent/filterComponent';
import LoadingSpinner from '../loading/loadingComponent';



const BodyContainerDrivers = () => {


    const emptyOptions =[ {}] ;

    const [newDriver,setNewDriver] = useState(false);
    
    const [editDriver,setEditDriver] = useState(false);

    const [update,setUpdate] = useState(false);

     //funcion que se llama al apretar el boton para crear nuevo contacto
     const newDriverClick = () => { 
       
        setNewDriver(true); 
        setEditDriver(false); 
      
        cleanDriver();
      
    };
   
    
    //actualiza la tabla de datos
    function updateTable  ()  {
        setUpdate(true);
        setUpdate(false);
        
        
    }

    //estructura de datos que recibe los datos de un contacto de proveedor a editar
    const [driverEdit,setdriverEdit] = useState(
        {
            id: 0,
            state: true,
            name: "",
            last_name: "",
            cpf: "",
            plate: "",
            zone: "",
            cellphone: "",
            bank: "",
            bank_agency: "",
            bank_account: "",
            account_name: "",
            cpf_cnpj: "",
            pix: "",
          }
    );
    const cleanContactSupplierEdit = () => {
        setdriverEdit(
            {
                id: 0,
                state: true,
                name: "",
                last_name: "",
                cpf: "",
                plate: "",
                zone: "",
                cellphone: "",
                bank: "",
                bank_agency: "",
                bank_account: "",
                account_name: "",
                cpf_cnpj: "",
                pix: "",
              }
        );
    }


//----------------------------------------
const [driver,setDriver] = useState(
    
        {
            id: 0,
            state: true,
            name: "",
            last_name: "",
            cpf: "",
            plate: "",
            zone: "",
            cellphone: "",
            bank: "",
            bank_agency: "",
            bank_account: "",
            account_name: "",
            cpf_cnpj: "",
            pix: "",
        }
    
  
); 
const cleanDriver = () => {
    setDriver(
        {
            id: 0,
            state: true,
            name: "",
            last_name: "",
            cpf: "",
            plate: "",
            zone: "",
            cellphone: "",
            bank: "",
            bank_agency: "",
            bank_account: "",
            account_name: "",
            cpf_cnpj: "",
            pix: "",
        }
    
    );
}

const onChangeDriver = (e) => {
    
        setDriver({...driver,
            [e.target.name]: e.target.value.toUpperCase()
        });
   
    

    ////console.log("driver: ", driver);

};



     const saveDriver = () => { 
        if(driver.name === "" || driver.last_name === "" || driver.cpf === "" || driver.plate == "" || driver.zone == "")
        {
            console.log("Error");
        }
        else{
            ////console.log("sup : " , sup)
           
           UserService.postDriver(driver.name,driver.last_name,driver.cpf, driver.plate, driver.zone,driver.cellphone,driver.bank,driver.bank_agency, driver.bank_account, driver.account_name, driver.cpf_cnpj,driver.pix).then((response)=>{if(response.data){console.log(response.data);setNewDriver(false);} else{console.log("error postDriver");}});
           
           ////console.log("driver : " , driver)
        }
  
     };
     const modifDriver = () => { 
        if(driver.name === "" || driver.last_name === "" || driver.cpf === "" || driver.plate == "" || driver.zone == "")
        {
            //console.log("Error");
        }
        else{
            ////console.log("sup : " , sup)
           
           UserService.putDriver(driver.name,driver.last_name,driver.cpf, driver.plate, driver.zone,driver.cellphone,driver.bank,driver.bank_agency, driver.bank_account, driver.account_name, driver.cpf_cnpj,driver.pix,driver.id).then((response)=>{if(response.data){console.log(response.data);setEditDriver(false);} else{console.log("error putDriver");}});
           
           ////console.log("driver : " , driver)
        }
   
     };
     
     //------------------------------------------------------------
     //estado de carga de datos de la base de datos
     const [isLoading, setIsLoading] = useState(false);

     //capturador de mensaje en caso de error al obtener datos de la base de datos
     const [errorMessage, setErrorMessage] = useState("");
    //------------------------------------------------


         
 
           
   
    

        const [filterTextPlate, setFilterTextPlate] = useState("");
        const [filterTextName, setFilterTextName] = useState("");
        
        const plateFilterMemo = React.useMemo(() => {
            return (
                <FilterComponent onFilter={e => setFilterTextPlate(e.target.value)}  filterText={filterTextPlate}  name = "Placa"/>
            );
        }, [filterTextPlate]);
        const enterpriseFilterNameMemo = React.useMemo(() => {
            return (
                <FilterComponent onFilter={e => setFilterTextName(e.target.value)}  filterText={filterTextName}  name = "Nombre"/>
            );
        }, [filterTextName]);

        const contactEditUpdate = React.useMemo(() => {
            
                editDriver && setDriver({...driver,  
                                                        ["pix"]: driverEdit.pix,
                                                        ["cpf_cnpj"]: driverEdit.cpf_cnpj,
                                                        ["account_name"]: driverEdit.account_name,
                                                        ["bank_account"]:driverEdit.bank_account,
                                                        ["bank_agency"]:driverEdit.bank_agency,
                                                        ["bank"]:driverEdit.bank,
                                                        ["cellphone"]: driverEdit.cellphone,
                                                        ["zone"]: driverEdit.zone,
                                                        ["plate"]:driverEdit.plate,
                                                        ["cpf"]:driverEdit.cpf,
                                                        ["last_name"]:driverEdit.last_name,
                                                        ["name"]:driverEdit.name,
                                                        ["id"]:driverEdit.id,
                                                        });
                
          
            

        }, [editDriver]);
        
        //-------------------------------------------------------------
   
       
        //console.log("Suppliers: ",suppliers);
        //console.log("Dispatchers: ",dispatchers)
    return ( 

      
            <div  className='BodyContainer container-fluid vh-100'>

           
            <div className='row'>

                <div id= 'sidebarMenu' className='col-2 bg-light vh-100 '>

                    <div className="mt-5 vh-100" >
                        
                        <div className="p-3" >
                            
                            
                           {plateFilterMemo}

                        </div>
                        <div className="p-3">
                            {enterpriseFilterNameMemo}
                        
                        
                        </div>
                       
                    </div>
                </div>

                <div className='col-10'>

               

                { !(editDriver || newDriver)  && ( 
                    <div className='container-fluid'>
                     
                        <div className='row'>
                        
                            <div className="col-11 p-3">
                                
                                <h3 className="text-center">LISTA DE MOTORISTAS</h3>
                                
                            </div>
                            

                            
                            
                            <div className='col'>
                                <div className="m-1 ">
                                    <button type="button" className="btn " onClick={newDriverClick}>
                                        
                                        <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                        </svg>

                                    </button>
                                </div>
                                
                            </div>

                        </div>
                        
                        <div className='row'>
                       {
                            !update && (
                                <DataTableDriver
                                plateSearch = {filterTextPlate}
                                nameSearch = {filterTextName}
                                setIDSelected = {setdriverEdit}
                                setEditDriver = {setEditDriver}
                                
                                /> 
                            )  
                       }
                        
                      
                        
                        
                         
                           
                        </div>
                                            
                     
                    </div>
                    )}


    



                

                { (editDriver || newDriver) && (
                    <div>

                        <div className='m-4 row '>
                            <div className='col'> 
                             <button className='btn' type = "button"  onClick={()=>{setNewDriver(false); setEditDriver(false)}}>
                                 <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.5 280.6l192 160c20.6 17.2 52.5 2.8 52.5-24.6V96c0-27.4-31.9-41.8-52.5-24.6l-192 160c-15.3 12.8-15.3 36.4 0 49.2zm256 0l192 160c20.6 17.2 52.5 2.8 52.5-24.6V96c0-27.4-31.9-41.8-52.5-24.6l-192 160c-15.3 12.8-15.3 36.4 0 49.2z"></path>
                                </svg>
                             </button>   
                              
                            </div>
                            {(newDriver) &&<div  className='col-10'>
                                <h1 >
                                    INGRESO DE UN NUEVO MOTORISTA
                                </h1>
                            </div>}
                            {(editDriver) &&<div  className='col-10'>
                                <h1 >
                                    MODIFICACION DE MOTORISTA
                                </h1>
                            </div>}

                            
                            <div className='col-1'>
                                    <button type="button" className="btn " onClick={updateTable}>
                                        
                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg">
                                            <path fill="none" stroke="#000" stroke-width="2" d="M1.7507,16.0022 C3.3517,20.0982 7.3367,23.0002 11.9997,23.0002 C18.0747,23.0002 22.9997,18.0752 22.9997,12.0002 M22.2497,7.9982 C20.6487,3.9012 16.6627,1.0002 11.9997,1.0002 C5.9247,1.0002 0.9997,5.9252 0.9997,12.0002 M8.9997,16.0002 L0.9997,16.0002 L0.9997,24.0002 M22.9997,0.0002 L22.9997,8.0002 L14.9997,8.0002">
                                                </path>
                                        </svg>
    
    
                                    </button>
                            </div>
                            
                            
                            


                        </div>
                        
                        <div className='m-5 row' >
                            
                            <div className='col-md-6 col ' >
                              

                                
                                

                                   
                                      
                                        <div className='m-2 input-group'>
                                            <label htmlFor="" className='col-md-3 input-group-text'>NOMBRE  </label>
                                            <input 
                                                name="name" 
                                                type="text"
                                                //placeholder="Nombre de la Persona de Contacto del Proveedor"
                                                className='col-md-9 form-control'
                                                value={driver.name}
                                                onChange={onChangeDriver}
                                                
                                            />
                                        </div>
                                        <div className='m-2 input-group'>
                                            <label htmlFor="" className='col-md-3 input-group-text'>APELLIDO  </label>
                                            <input 
                                                name="last_name" 
                                                type="text"
                                                //placeholder="Apellidos de la Persona de Contacto del Proveedor"
                                                className='col-md-9 form-control'
                                                value={driver.last_name}
                                                onChange={onChangeDriver}
                                                
                                            />
                                        </div>
                                        <div className='m-2 input-group'>
                                            <label htmlFor="" className='col-md-3  input-group-text'>CPF</label>
                                            <input 
                                            
                                                name="cpf" 
                                                type="text"
                                                //placeholder="Correo de la Persona de Contacto del Proveedor"
                                                className='col-md-9 form-control'
                                                value={driver.cpf}
                                                onChange={onChangeDriver}
                                                
                                            />
                                        </div>

                                        <div className='m-2 input-group'>
                                            <label htmlFor="" className='col-md-3  input-group-text'>PLACA</label>
                                            <input 
                                            
                                                name="plate" 
                                                type="text"
                                                //placeholder="Celular de la Persona de Contacto del Proveedor"
                                                className='col-md-9 form-control'
                                                value={driver.plate}
                                                onChange={onChangeDriver}
                                                
                                            />
                                        </div>

                                        <div className='m-2 input-group'>
                                            <label htmlFor="" className='col-md-3  input-group-text'>ZONA</label>
                                            <input 
                                            
                                                name="zone" 
                                                type="text"
                                                //placeholder="Celular de la Persona de Contacto del Proveedor"
                                                className='col-md-9 form-control'
                                                value={driver.zone}
                                                onChange={onChangeDriver}
                                                
                                            />
                                        </div>
                                        <div className='m-2 input-group'>
                                            <label htmlFor="" className='col-md-3  input-group-text'>CELULAR</label>
                                            <input 
                                            
                                                name="cellphone" 
                                                type="text"
                                                //placeholder="Celular de la Persona de Contacto del Proveedor"
                                                className='col-md-9 form-control'
                                                value={driver.cellphone}
                                                onChange={onChangeDriver}
                                                
                                            />
                                        </div>
                                       
                                  
                                    
                                   


                                


                            
                            </div>
                              
                            

                            <div className='col-md-6 col'>
                            
                            <div className='m-2 input-group'>
                                <label htmlFor="" className='col-md-3 input-group-text'>BANCO  </label>
                                    <input 
                                        name="bank" 
                                        type="text"
                                               //placeholder="Apellidos de la Persona de Contacto del Proveedor"
                                        className='col-md-9 form-control'
                                        value={driver.bank}
                                        onChange={onChangeDriver}
                                                
                                    />
                            </div>
                                        <div className='m-2 input-group'>
                                            <label htmlFor="" className='col-md-3  input-group-text'>AGENCIA</label>
                                            <input 
                                            
                                                name="bank_agency" 
                                                type="text"
                                                //placeholder="Correo de la Persona de Contacto del Proveedor"
                                                className='col-md-9 form-control'
                                                value={driver.bank_agency}
                                                onChange={onChangeDriver}
                                                
                                            />
                                        </div>

                                        <div className='m-2 input-group'>
                                            <label htmlFor="" className='col-md-3  input-group-text'>CUENTA BANCARIA</label>
                                            <input 
                                            
                                                name="bank_account" 
                                                type="text"
                                                //placeholder="Celular de la Persona de Contacto del Proveedor"
                                                className='col-md-9 form-control'
                                                value={driver.bank_account}
                                                onChange={onChangeDriver}
                                                
                                            />
                                        </div>

                                        <div className='m-2 input-group'>
                                            <label htmlFor="" className='col-md-3  input-group-text'>FAVORECIDO</label>
                                            <input 
                                            
                                                name="account_name" 
                                                type="text"
                                                //placeholder="Celular de la Persona de Contacto del Proveedor"
                                                className='col-md-9 form-control'
                                                value={driver.account_name}
                                                onChange={onChangeDriver}
                                                
                                            />
                                        </div>

                                        <div className='m-2 input-group'>
                                            <label htmlFor="" className='col-md-3  input-group-text'>CNPJ / CPF</label>
                                            <input 
                                            
                                                name="cpf_cnpj" 
                                                type="text"
                                                //placeholder="Celular de la Persona de Contacto del Proveedor"
                                                className='col-md-9 form-control'
                                                value={driver.cpf_cnpj}
                                                onChange={onChangeDriver}
                                                
                                            />
                                        </div>

                                        <div className='m-2 input-group'>
                                            <label htmlFor="" className='col-md-3  input-group-text'>CLAVE PIX</label>
                                            <input 
                                            
                                                name="pix" 
                                                type="text"
                                                //placeholder="Celular de la Persona de Contacto del Proveedor"
                                                className='col-md-9 form-control'
                                                value={driver.pix}
                                                onChange={onChangeDriver}
                                                
                                            />
                                        </div>
                                

                                
                                
                                
                                
                              
                               
                                

                            </div>
                            {(newDriver) && <button className='btn btn-primary'  onClick={saveDriver}>
                            CREAR NUEVO MOTORISTA
                              </button>
                            }

                            {(editDriver) && <button className='btn btn-primary' onClick={modifDriver}>
                            MODIFICAR MOTORISTA
                              </button>
                            }    
                            

                            
                           
                        </div>

                        
                    </div>

                )

                }
                
                
                </div>
                
            </div>
           
        </div>
        
       
    );
}
export default BodyContainerDrivers
