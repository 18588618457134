
import React, {Component} from 'react';


import 'bootstrap/dist/css/bootstrap.min.css';

import 'bootstrap/dist/js/bootstrap.bundle.min';
import { useEffect, useState } from 'react';
import './body_container_clientes.css';
import {BsFillPlusCircleFill} from 'react-icons/bs'
import {BiEdit} from 'react-icons/bi'
import Select from 'react-select';
import UserService    from '../../services/user.service';
import { useForm } from 'react-hook-form';
import DataTable1 from '../data_table/dataTable';
import userService from '../../services/user.service';
import FilterComponent from '../FilterComponent/filterComponent';
import LoadingSpinner from '../loading/loadingComponent';



const BodyContainerClientes = () => {


    const emptyOptions =[ {}] ;

    const [newContact,setNewContact] = useState(false);
    
    const [editContact,setEditContact] = useState(false);

    const [update,setUpdate] = useState(false);

     //funcion que se llama al apretar el boton para crear nuevo contacto
     const newEnterpriseClick = () => { 
       
        setNewContact(true); 
        setEditContact(false); 
        cleanEnt();
        cleanEntTemp();
        cleanContact();
      
    };
   
    //actualiza la tabla de datos
    const updateTable = () => {
        
        setUpdate(true);
        UserService.getEnterprise().then(enter => {setEnterprises(enter);
            
                UserService.getAgencies().then(a => {setAgencies(a);
                    setUpdate(false);;
                }).catch( () => {setErrorMessage("No se puede obtener los contactos del servidor");setUpdate(false);} );
            
        }).catch( () => {setErrorMessage("No se puede obtener los empresas del servidor");setUpdate(false);} );
        
    };

    //estructura de datos que recibe los datos de un contacto a editar
    const [contactEdit,setcontactEdit] = useState(
        {
            id: 0,
            name_contact: "",
            last_name_contact: "",
            phone: "",
            cellphone: "",
            intern: "",
            email: "",
            enterprise: {
              enterprise_id: 0,
              enterprise_name: "",
              enterprise_nit: "",
              enterprise_city: "",
              enterprise_country: "",
              enterprise_state: ""
            },
            agency: {
                agency_id: 0,
                agency_name: "",
                agency_contact_name: "",
                agency_phone: "",
                agency_email: ""
              }
          }
    );
    const cleanContactEdir = () => {
        setcontactEdit(
            {
                id: 0,
                name_contact: "",
                last_name_contact: "",
                phone: "",
                cellphone: "",
                intern: "",
                email: "",
                enterprise: {
                  enterprise_id: 0,
                  enterprise_name: "",
                  enterprise_nit: "",
                  enterprise_city: "",
                  enterprise_country: "",
                  enterprise_state: ""
                },
                agency: {
                    agency_id: 0,
                    agency_name: "",
                    agency_contact_name: "",
                    agency_phone: "",
                    agency_email: ""
                  }
              }
        );
    }

    //arrays de las empresas(nombre,direccion) registradas en las base de datos
    const [enterprises,setEnterprises] = useState(
        [ 
           
        ]
        
    );
       //estructura para guardar una nueva empresa
   const [ent,setEnt] = useState( 
    {
        id: 0,
        
        name: "",
        nit: "",
        city_name: "",
        country: "",
        city_state: "",
        
    }   
);
    const cleanEnt = () => {
        setEnt(
            {
                id: 0,
                
                name: "",
                nit: "",
                city_name: "",
                country: "",
                city_state: "",
                
            }   
        );
    }

const [entTemp,setEntTemp] = useState( 
    {
        id: 0,
        state: true,
        name: "",
        nit: "",
        city_name: "",
        country: "",
        city_state: "",
       
    }   
);

const cleanEntTemp = () => {
    setEntTemp(
        {
            id: 0,
            state: true,
            name: "",
            nit: "",
            city_name: "",
            country: "",
            city_state: "",
           
        }   
    );
}

const onChangeEnt = (e) => {
    
        setEnt({...ent,
            [e.target.name]: e.target.value.toUpperCase()
        });
    
       
       
       //console.log("ent: ", ent);
    
};

//arrays de las empresas(nombre,direccion) registradas en las base de datos
const [agencies,setAgencies] = useState(
    [ 
        
    ]
    
);
   //estructura para guardar una nueva empresa
const [agen,setAgen] = useState( 
    {
        id: 0,
        
        name_agency: "",
        name: "",
        phone: "",
        email: ""
      }
);
const cleanAgen = () => {
    setAgen(
        {
            id: 0,
            
            name_agency: "",
            name: "",
            phone: "",
            email: ""
        }  
    );
}

const [agenTemp,setAgenTemp] = useState( 
    {
        id: 0,
        
        name_agency: "",
        name: "",
        phone: "",
        email: ""
    }   
);

const cleanAgenTemp = () => {
setAgenTemp(
    {
        id: 0,
        
        name: "",
        nit: "",
        city_name: "",
        country: "",
        city_state: "",
       
    }   
);
}

const onChangeAgen = (e) => {

    if(e.target.name === "email")
    {
        setAgen({...agen,
            [e.target.name]: e.target.value.toLowerCase()
        });
    }
    else
    {
        setAgen({...agen,
            [e.target.name]: e.target.value.toUpperCase()
        });
    }
    
    
   
   
   //console.log("agen: ", agen);

};



const [contact,setContact] = useState(
    
        {
            id: 0,
           
            name: "",
            last_name: "",
            phone: "",
            cellphone: "",
            intern: "",
            email: "",
            agency : 0,
            empresa: 0
          }
    
  
); 
const cleanContact = () => {
    setContact(
        {
            id: 0,
           
            name: "",
            last_name: "",
            phone: "",
            cellphone: "",
            intern: "",
            email: "",
            agency : 0,
            empresa: 0
          }
    
    );
}

const onChangeContact = (e) => {
    if(e.target.name === "email")
    {
        setContact({...contact,
            [e.target.name]: e.target.value.toLowerCase()
        });
    }
    else{
        setContact({...contact,
            [e.target.name]: e.target.value.toUpperCase()
        });
    }
    

    ////console.log("contact: ", contact);

};





//------------------FUNCIONES PARA CREAR O MODIFICAR UNA EMPRESA
const [createEnterprise, setCreateEnterprise]= useState(false);   
const [modifEntterprise,setModifEntterprise] = useState(false);

const createEntClick = () => { 
   setCreateEnterprise(true);  
   setModifEntterprise(false);    
   cleanEnt();
   cleanEntTemp();
   
      
};
const cancelCreateEntClick = () => { 
   setCreateEnterprise(false); 
   setModifEntterprise(false); 
   cleanEnt();
   cleanEntTemp();
   
};
const modifEntClick = (e) =>{
   if(entTemp.name === "" || entTemp.nit === "" || entTemp === "")
   {
       console.log("Error");
   } 
   else{
       setModifEntterprise(true);
       setCreateEnterprise(false);
   
     
       
       setEnt({...ent,["city_state"]: entTemp.city_state,["country"]: entTemp.country ,["city_name"]: entTemp.city_name,["nit"]: entTemp.nit,["name"]: entTemp.name,["id"]:entTemp.id});
       ////console.log("ent temp : " ,entTemp);
    }
   
     
    
}
//guarda empresa en base de datos  
const crearEmpresa = () => {
   if(ent.name === "" || ent.nit === "" || ent === "")
   {
       console.log("Error");
   }
   else{
       ////console.log("ent : " , ent)
      
      UserService.postEnterprise((ent.name),(ent.nit),(ent.city_name),(ent.country),(ent.city_state)).then((response)=>{if(response.data){console.log(response.data);UserService.getEnterprise().then(empresas =>setEnterprises(empresas));} else{console.log("error postEmpresa");}});
      setCreateEnterprise(false);
      ////console.log("ent : " , ent)
   }
   
   
   };
   const modificarEnterp = () =>{

       if(ent.EnterName === "" || ent.EnterAdress === "" || ent === "")
       {
           //console.log("Error");
       }
       else{
           
         
           UserService.putEnterprise((ent.name),(ent.nit),(ent.city_name),(ent.country),(ent.city_state),(ent.id)).then((response)=>{if(response.data){console.log(response.data);UserService.getEnterprise().then(empresas => setEnterprises(empresas)  );} else{console.log("error putEmpresa");}});
           
           setModifEntterprise(false);
        //   //console.log("Empresas",empresas);
        //   //console.log("Ent",ent);
       }
   

   }

   const onChangeSelectEnt = (a) =>{
       
       
         
       setEntTemp( enterprises.find( obj => {return obj.id === parseInt(a.value)}));
       setContact({...contact,["empresa"]: a.value}) 
       ////console.log("contact: ", contact);
        //console.log("ent temp : " ,entTemp);
     };
   
//------------------FUNCIONES PARA CREAR O MODIFICAR UNA AGENCIA
const [createAgency, setCreateAgency]= useState(false);   
const [modifAgency,setModifAgency] = useState(false);

const createAgenClick = () => { 
    setCreateAgency(true);  
    setModifAgency(false);    
   cleanAgen();
   cleanAgenTemp();
   
      
};
const cancelCreateAgenClick = () => { 
    setCreateAgency(false); 
    setModifAgency(false); 
    cleanAgen();
    cleanAgenTemp();
   
};
const modifAgenClick = (e) =>{
   if(agenTemp.name === "" || agenTemp.name_agency === "" || agenTemp === "")
   {
       //console.log("Error");
   } 
   else{
        setModifAgency(true);
        setCreateAgency(false);
   
     
        //console.log("modif Agen1",agen)
       setAgen({...agen,["email"]: agenTemp.email,["phone"]: agenTemp.phone ,["name"]: agenTemp.name,["name_agency"]: agenTemp.name_agency,["id"]:agenTemp.id});
        
       //console.log("modif Agen",agenTemp);
        //console.log("modif Agen1",agen)
    }
   
     
    
}
//guarda empresa en base de datos  
const crearAgencia = () => {
   if(agen.name === "" || agen.name_agency === "" || agen === "")
   {
       //console.log("Error");
   }
   else{
       ////console.log("ent : " , ent)
      
      UserService.postAgency((agen.name_agency),(agen.name),(agen.phone),(agen.email)).then((response)=>{if(response.data){console.log(response.data);UserService.getAgencies().then(agencias =>setAgencies(agencias));} else{console.log("error postAgencia");}});
      setCreateAgency(false);
      //console.log("agen : " , agen)
   }
   
   
   };
   const modificarAgencia = () =>{

       if(agen.name === "" || agen.name_agency === "" || ent === "")
       {
           //console.log("Error");
       }
       else{
           
         
           UserService.putAgency((agen.name_agency),(agen.name),(agen.phone),(agen.email),(agen.id)).then((response)=>{if(response.data){console.log(response.data);UserService.getAgencies().then(agencia => setAgencies(agencia)  );} else{console.log("error putAgencia");}});
           
           setModifAgency(false);
        //   //console.log("Empresas",empresas);
        //   //console.log("Ent",ent);
       }
   

   }

   const onChangeSelectAgen = (e) =>{
       
       
         
       setAgenTemp( agencies.find( obj => {return obj.id === parseInt(e.value)}));
       setContact({...contact,["agency"]: parseInt(e.value)}) 
       //console.log("agentemp select: ", agenTemp);
       //console.log(" Contact: ", contact);
       //console.log(" Value: ", parseInt(e.value));
     };




     const saveContact = () => { 
        if(contact.name === "" || contact.empresa === 0 || contact.last_name == "")
        {
            console.log("Error");
        }
        else{
            ////console.log("ent : " , ent)
           
           UserService.postContact(contact.name, contact.last_name, contact.phone,contact.cellphone,contact.intern, contact.email,contact.agency,contact.empresa).then((response)=>{if(response.data){console.log(response.data);setNewContact(false);} else{console.log("error postContacto");}});
           
           ////console.log("contact : " , contact)
        }
  
     };
     const modifContact = () => { 
        if(contact.name === "" || contact.empresa === 0 || contact.last_name == "")
        {
            //console.log("Error");
        }
        else{
            ////console.log("ent : " , ent)
           
           UserService.putContact(contact.name, contact.last_name, contact.phone,contact.cellphone,contact.intern, contact.email,contact.agency,contact.empresa,contact.id).then((response)=>{if(response.data){console.log(response.data);setEditContact(false);} else{console.log("error postContacto");}});
           
           ////console.log("contact : " , contact)
        }
   
     };
     
     //------------------------------------------------------------
     //estado de carga de datos de la base de datos
     const [isLoading, setIsLoading] = useState(false);

     //capturador de mensaje en caso de error al obtener datos de la base de datos
     const [errorMessage, setErrorMessage] = useState("");
    //------------------------------------------------


         
    useEffect(()=>{ 
        const getData = () => {
            //setIsLoading(true);
            setUpdate(true);
            UserService.getEnterprise().then(enter => {setEnterprises(enter);
                
                    UserService.getAgencies().then(a => {setAgencies(a);
                       setUpdate(false);;
                    }).catch( () => {setErrorMessage("No se puede obtener los contactos del servidor");setUpdate(false);} );
                
            }).catch( () => {setErrorMessage("No se puede obtener los empresas del servidor");setUpdate(false);} );
            
       
            
    };
    getData();    
    },[]);  
           
    ////console.log("entTemp:",entTemp);
    ////console.log("Enterprises:",enterprises);
    ////console.log("Contacts:",contacts);
    ////console.log("errors:",errorMessage);
   // //console.log("contactEdit:",contactEdit);
    //console.log("Agencias",agencies);
    //console.log("AgenTemp:",agenTemp);
    //console.log("agencia",agencies);
    

        const [filterTextEnt, setFilterTextEnt] = useState("");
        const [filterTextName, setFilterTextName] = useState("");
        
        const enterpriseFilterMemo = React.useMemo(() => {
            return (
                <FilterComponent onFilter={e => setFilterTextEnt(e.target.value)}  filterText={filterTextEnt}  name = "Empresa"/>
            );
        }, [filterTextEnt]);
        const enterpriseFilterNameMemo = React.useMemo(() => {
            return (
                <FilterComponent onFilter={e => setFilterTextName(e.target.value)}  filterText={filterTextName}  name = "Contacto"/>
            );
        }, [filterTextName]);

        const contactEditUpdate = React.useMemo(() => {
             setEntTemp( enterprises.find( obj => {return obj.id === contactEdit.enterprise.enterprise_id}));
             setAgenTemp( agencies.find( obj => {return obj.id === contactEdit.agency.agency_id}));
                editContact && setContact({...contact,  ["agency"]: contactEdit.agency.agency_id,
                                                        ["empresa"]: contactEdit.enterprise.enterprise_id,
                                                        ["email"]:contactEdit.email,
                                                        ["intern"]:contactEdit.intern,
                                                        ["cellphone"]:contactEdit.cellphone,
                                                        ["phone"]:contactEdit.phone,
                                                        ["last_name"]:contactEdit.last_name_contact,
                                                        ["name"]:contactEdit.name_contact,
                                                        ["id"]:contactEdit.id,
                                                        });
                
            setCreateEnterprise(false);
            setModifEntterprise(false);
            setCreateAgency(false);
            setModifAgency(false);
            

        }, [editContact]);
        
        //-------------------------------------------------------------
        
       
        
    return ( 

      
            <div  className='BodyContainer container-fluid vh-100'>

           
            <div className='row'>

                <div id= 'sidebarMenu' className='col-2 bg-light vh-100 '>

                    <div className="mt-5 vh-100" >
                        
                        <div className="p-3" >
                            
                            
                           {enterpriseFilterMemo}

                        </div>
                        <div className="p-3">
                            {enterpriseFilterNameMemo}
                        
                        
                        </div>
                       
                    </div>
                </div>

                <div className='col-10'>

               

                { !(editContact || newContact)  && ( 
                    <div className='container-fluid'>
                     
                        <div className='row'>
                        
                            <div className="col-10 p-3">
                                
                                <h3 className="text-center">LISTA DE CLIENTES</h3>
                                
                            </div>
                            <div className='col'>
                                <div className="m-1 ">
                                    <button type="button" className="btn " onClick={updateTable} disabled= {update}>
                                        
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg">
                                        <path fill="none" stroke="#000" stroke-width="2" d="M1.7507,16.0022 C3.3517,20.0982 7.3367,23.0002 11.9997,23.0002 C18.0747,23.0002 22.9997,18.0752 22.9997,12.0002 M22.2497,7.9982 C20.6487,3.9012 16.6627,1.0002 11.9997,1.0002 C5.9247,1.0002 0.9997,5.9252 0.9997,12.0002 M8.9997,16.0002 L0.9997,16.0002 L0.9997,24.0002 M22.9997,0.0002 L22.9997,8.0002 L14.9997,8.0002">
                                            </path>
                                    </svg>


                                    </button>
                                </div>
                                
                            </div>

                            
                            
                            <div className='col'>
                                <div className="m-1 ">
                                    <button type="button" className="btn " onClick={newEnterpriseClick}>
                                        
                                        <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                        </svg>

                                    </button>
                                </div>
                                
                            </div>

                        </div>
                        
                        <div className='row'>
                       {
                            !update && (
                                <DataTable1
                                entSearch = {filterTextEnt}
                                nameSearch = {filterTextName}
                                setIDSelected = {setcontactEdit}
                                setEditEnt = {setEditContact}
                                
                                /> 
                            ) 
                       }
                        
                      
                        
                        
                         
                           
                        </div>
                                            
                     
                    </div>
                    )}


    



                

                { (editContact || newContact) && (
                    <div>

                        <div className='m-4 row '>
                            <div className='col'> 
                             <button className='btn' type = "button"  onClick={()=>{setNewContact(false); setEditContact(false)}}>
                                 <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.5 280.6l192 160c20.6 17.2 52.5 2.8 52.5-24.6V96c0-27.4-31.9-41.8-52.5-24.6l-192 160c-15.3 12.8-15.3 36.4 0 49.2zm256 0l192 160c20.6 17.2 52.5 2.8 52.5-24.6V96c0-27.4-31.9-41.8-52.5-24.6l-192 160c-15.3 12.8-15.3 36.4 0 49.2z"></path>
                                </svg>
                             </button>   
                              
                            </div>
                            {(newContact) &&<div  className='col-10'>
                                <h1 >
                                    INGRESO DE UN NUEVO CLIENTE
                                </h1>
                            </div>}
                            {(editContact) &&<div  className='col-10'>
                                <h1 >
                                    MODIFICACION DE CONTACTO EXISTENTE
                                </h1>
                            </div>}

                            
                            <div className='col-1'>
                                    <button type="button" className="btn " onClick={updateTable}>
                                        
                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg">
                                            <path fill="none" stroke="#000" stroke-width="2" d="M1.7507,16.0022 C3.3517,20.0982 7.3367,23.0002 11.9997,23.0002 C18.0747,23.0002 22.9997,18.0752 22.9997,12.0002 M22.2497,7.9982 C20.6487,3.9012 16.6627,1.0002 11.9997,1.0002 C5.9247,1.0002 0.9997,5.9252 0.9997,12.0002 M8.9997,16.0002 L0.9997,16.0002 L0.9997,24.0002 M22.9997,0.0002 L22.9997,8.0002 L14.9997,8.0002">
                                                </path>
                                        </svg>
    
    
                                    </button>
                            </div>
                            
                            
                            


                        </div>
                        
                        <div className='m-5 row' >
                            
                            <div className='col-md-6 col ' >
                                {
                                    (!createEnterprise && !modifEntterprise) && (   
                                    <div className='m-2 input-group'>
                                    <span className=' input-group-text col-md-3'>Empresa</span>
                                   

                                    <Select
                                        id='select_ent'
                                        className = 'col-md-7'
                                        placeholder="Seleccione una empresa" 
                                        onChange= {(a) => onChangeSelectEnt(a)}
                                        defaultValue = { editContact && ((option) => ({label: contactEdit.enterprise.enterprise_name,value: contactEdit.enterprise.enterprise_id})) }
                                       options = {(enterprises.length !== 0)?  enterprises.map((option) => ({ label : option.name, value: option.id })) : ([])}
                                        isSearchable = {true}
                                        noOptionsMessage={() => 'NINGUNA EMPRESA REGISTRADA'}
                                    />

                                    <button className='btn col-md-1 btn-outline-secondary ' type='button'  onClick={createEntClick}><BsFillPlusCircleFill fontSize={"1.5em"}/></button>
                                    <button className='btn col-md-1 btn-outline-secondary' type='button'  onClick={modifEntClick}>
                                       <BiEdit fontSize={"1.5em"}/>
                                    </button>
                                     </div>
                                )
                                }

                                
                                {
                                    ( modifEntterprise || createEnterprise) && (
                                        <div className='border rounded border-5 m-2' >   
                                            
                                            <div className='m-2'>
                                                <label className='col-md-3'>Nombre</label>
                                                <input 
                                                type="text"  
                                                
                                                    name='name'
                                                
                                                placeholder="Nombre de la Empresa"
                                                className='col-md-9 '
                                                value={ent.name}
                                                onChange={onChangeEnt} 
                                                />
                                            </div>
                                            <div className='m-2'>
                                                <label  className='col-md-3'>Nit</label>
                                                <input 
                                                    type="text"  
                                                
                                                    
                                                    name = "nit"
                                                    placeholder="NIT de la Empresa"
                                                    className='col-md-9 '
                                                    value={ent.nit}
                                                    onChange={onChangeEnt} 
                                                />
                                            </div>
                                            <div className='m-2'>
                                                <label className='col-md-3'>Ciudad</label>
                                                <input 
                                                type="text"  
                                                
                                                    name='city_name'
                                                
                                                placeholder="Ciudad de la Empresa"
                                                className='col-md-9 '
                                                value={ent.city_name}
                                                onChange={onChangeEnt} 
                                                />
                                            </div>
                                            <div className='m-2'>
                                                <label className='col-md-3'>Departamento</label>
                                                <input 
                                                type="text"  
                                                
                                                    name='city_state'
                                                
                                                placeholder="Departamento de la Empresa"
                                                className='col-md-9 '
                                                value={ent.city_state}
                                                onChange={onChangeEnt} 
                                                />
                                            </div>
                                            <div className='m-2'>
                                                <label className='col-md-3'>País</label>
                                                <input 
                                                type="text"  
                                                
                                                    name='country'
                                                
                                                placeholder="Pais de la Empresa"
                                                className='col-md-9 '
                                                value={ent.country}
                                                onChange={onChangeEnt} 
                                                />
                                            </div>
                                            {createEnterprise && <button onClick={crearEmpresa} className = "rounded m-2">CREAR EMPRESA</button>}
                                            {modifEntterprise && <button onClick={modificarEnterp} className = "rounded m-2">MODIFICAR EMPRESA</button>}
                                           {/* <button type="Submit" className = "rounded m-2">CREAR</button>*/}
                                            <button onClick={cancelCreateEntClick}className = "rounded m-2">CANCELAR</button>
                                            
                                        </div>

                                        

                                        
                                    )
                                }

                                


                               
                            
                                
                                
                                        
                                        


                                    <div className=' m-2'> 
                                      
                                        <div className='m-2'>
                                            <label htmlFor="" className='col-md-3'>Nombre Contacto</label>
                                            <input 
                                                name="name" 
                                                type="text"
                                                placeholder="Nombre de la Persona de Contacto"
                                                className='col-md-9 '
                                                value={contact.name}
                                                onChange={onChangeContact}
                                                
                                            />
                                        </div>
                                        <div className='m-2'>
                                            <label htmlFor="" className='col-md-3'>Apellido Contacto</label>
                                            <input 
                                                name="last_name" 
                                                type="text"
                                                placeholder="Apellidos de la Persona de Contacto"
                                                className='col-md-9 '
                                                value={contact.last_name}
                                                onChange={onChangeContact}
                                                
                                            />
                                        </div>
                                        <div className='m-2'>
                                    <label htmlFor="" className='col-md-3 '>Correo</label>
                                    <input 
                                       
                                        name="email" 
                                        type="text"
                                        placeholder="Correo de la Persona de Contacto"
                                        className='col-md-9 '
                                        value={contact.email}
                                        onChange={onChangeContact}
                                        
                                    />
                                </div>
                                       
                                    </div>
                                    
                                


                                


                                
                            </div>
                              
                            

                            <div className='col-md-6 col'>

                                <div className='m-2'>
                                    <label htmlFor="" className='col-md-3 '>Telefono</label>
                                    <input 
                                        name="phone" 
                                        type="text"
                                        placeholder="Telefono de la Persona de Contacto"
                                        className='col-md-9 '
                                        value={contact.phone}
                                        onChange={onChangeContact}
                                        
                                    />
                                </div>
                                
                                <div className='m-2'>
                                    <label htmlFor="" className='col-md-3 '>Interno</label>
                                    <input 
                                        name="intern" 
                                        type="text"
                                        placeholder="Interno de la Persona de Contacto"
                                        className='col-md-9 '
                                        value={contact.intern}
                                        onChange={onChangeContact}
                                        
                                    />
                                </div>

                                <div className='m-2'>
                                    <label htmlFor="" className='col-md-3 '>Celular</label>
                                    <input 
                                      
                                        name="cellphone" 
                                        type="text"
                                        placeholder="Celular de la Persona de Contacto"
                                        className='col-md-9 '
                                        value={contact.cellphone}
                                        onChange={onChangeContact}
                                        
                                    />
                                </div>

                                
                                
                                {
                                    (!createAgency && !modifAgency) && (   
                                    <div className='m-2 input-group'>
                                    <span className=' input-group-text col-md-3'>Agencia</span>
                                   

                                    <Select
                                        id='select_agen'
                                        className = 'col-md-7'
                                        placeholder="Seleccione una Agencia" 
                                        onChange= {(e) => onChangeSelectAgen(e)}
                                        defaultValue = { editContact && ((option) => ({label: contactEdit.agency.agency_name,value: contactEdit.agency.agency_id})) }
                                        options = {(agencies.length !== 0 ) ? (agencies.map((option) => ({ label : option.name_agency + " - " +option.name, value: option.id })) ) :  ([]) }   
                                        isSearchable = {true}
                                        noOptionsMessage={() => 'NINGUNA AGENCIA REGISTRADA'}
                                    />

                                    <button className='btn col-md-1 btn-outline-secondary ' type='button'  onClick={createAgenClick}><BsFillPlusCircleFill fontSize={"1.5em"}/></button>
                                    <button className='btn col-md-1 btn-outline-secondary' type='button'  onClick={modifAgenClick}>
                                       <BiEdit fontSize={"1.5em"}/>
                                    </button>
                                     </div>
                                )
                                }

                                
                                {
                                    ( modifAgency || createAgency) && (
                                        <div className='border rounded border-5 m-2' >   
                                            
                                            <div className='m-2'>
                                                <label className='col-md-3'>Agencia</label>
                                                <input 
                                                type="text"  
                                                
                                                    name='name_agency'
                                                
                                                placeholder="Nombre de la Agencia"
                                                className='col-md-9 '
                                                value={agen.name_agency}
                                                onChange={onChangeAgen} 
                                                />
                                            </div>
                                            <div className='m-2'>
                                                <label  className='col-md-3'>Contacto</label>
                                                <input 
                                                    type="text"  
                                                
                                                    
                                                    name = "name"
                                                    placeholder="Nombre del contacto"
                                                    className='col-md-9 '
                                                    value={agen.name}
                                                    onChange={onChangeAgen} 
                                                />
                                            </div>
                                            <div className='m-2'>
                                                <label className='col-md-3'>Teléfono</label>
                                                <input 
                                                type="text"  
                                                
                                                    name='phone'
                                                
                                                placeholder="Telefono del contacto"
                                                className='col-md-9 '
                                                value={agen.phone}
                                                onChange={onChangeAgen} 
                                                />
                                            </div>
                                            <div className='m-2'>
                                                <label className='col-md-3'>Correo</label>
                                                <input 
                                                type="text"  
                                                
                                                    name='email'
                                                
                                                placeholder="Correo del contacto"
                                                className='col-md-9 '
                                                value={agen.email}
                                                onChange={onChangeAgen} 
                                                />
                                            </div>
                                            
                                            {createAgency && <button onClick={crearAgencia} className = "rounded m-2">CREAR AGENCIA</button>}
                                            {modifAgency && <button onClick={modificarAgencia} className = "rounded m-2">MODIFICAR AGENCIA</button>}
                                           {/* <button type="Submit" className = "rounded m-2">CREAR</button>*/}
                                            <button onClick={cancelCreateAgenClick}className = "rounded m-2">CANCELAR</button>
                                            
                                        </div>

                                        

                                        
                                    )
                                }
                              
                               
                                

                            </div>
                            
                            

                            
                            {(newContact) && <button  onClick={saveContact}>
                            CREAR NUEVO CLIENTE
                              </button>
                            }

                            {(editContact) && <button  onClick={modifContact}>
                            MODIFICAR CLIENTE
                              </button>
                            }
                        </div>

                        
                    </div>

                )

                }
                
                
                </div>
                
            </div>
           
        </div>
        
       
    );
}
export default BodyContainerClientes
