import React, {useEffect,Component,useState} from 'react';


import { useForm ,Controller} from 'react-hook-form';
import Select from 'react-select';
import './body_container_cotizacion.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FiltersContainer from '../filters/filters_container';
import FormContainer from '../form/form_container';
import CotizacionPDF from './cotizacion_pdf'
import DataTable from 'react-data-table-component';
import { PDFViewer ,PDFDownloadLink} from '@react-pdf/renderer';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useLocation,Link } from 'react-router-dom';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { BsTruckFlatbed } from 'react-icons/bs';
import UserService from '../../services/user.service';
import FilterComponent from '../FilterComponent/filterComponent';
import { usePDF, Document, Page } from '@react-pdf/renderer';
import DataTableQuote from '../data_Table_Quote/dataTableQuote';
let counter = 0;
function BodyContainerCotizaciones(props) {
    const [newQuote,setNewQuote] = useState(false);//estado creacion nueva cotizacion
    const [editQuote,setEditQuote] = useState(false);//estado de edicion de cotizacion
    const [datos,setDatos] = useState();

    const min_insur = 20.0;

    const [valortotal,setValorTotal] = useState(" ");
    
    const [requests,setRequests] = useState();
    const [contacts,setContacts] = useState();
    const [enterprises,setEnterprises] = useState();
    const [errorMessage,setErrorMessage] = useState([]);
    const [editQuoteData,setEditQuoteData] = useState();

    const [selectedRows,setSelectedRows] = useState();
    const [pdfData,setPdfData] = useState();
    const [pdfEnt,setPdfEnt] = useState();
    const [updateDataPDF,setUpdateDataPDF] = useState(false);
    const [condPagoSelect,setCondPagoSelect] = useState(1);
    const condPago = [
        {name: "A la llegada de la mercadería a destino, en dólares americanos o su equivalente en bolivianos al cambio oficial.",id: 1},
        {name: "Contra entrega de los documentos para la nacionalización, en dólares americanos o su equivalente en bolivianos al cambio oficial.",id: 2},
        {name: "Crédito 15 días de la llegada de la mercadería a destino, en dólares americanos o su equivalente en bolivianos al cambio oficial.",id: 3},
        {name: "Crédito 30 días de la llegada de la mercadería a destino, en dólares americanos o su equivalente en bolivianos al cambio oficial.",id: 4}

       
        

    ];
    
    
    
        
    
    const servicios = [
        {name: "Despacho desde Origen.",status:false,id: 1},
        {name: "Emisión de CRT y MIC.",status:false,id: 2},
        {name: "Liberación del Vehículo en frontera.",status:false,id: 3},
        {name: "Factura IVA CERO por tramo brasilero.",status:false,id: 4},
        {name: "Factura con crédito fiscal para tramo boliviano.",status:false,id: 5},
        {name: "Seguimiento de carga y comunicación.",status:false,id: 6},
        {name: "Factura por servicios logísticos con crédito fiscal.",status:false,id: 7},
        {name: "Factura de transporte IVA CERO de origen a destino.",status:false,id: 8},
        
        
        {name: "Emisión del Manifiesto de Carga Internacional",status:false,id: 9},
        {name: "Seguimiento de carga y status.",status:false,id: 10},
    ];
    const [servState,setServState] = 
        
           useState(
                new Array(servicios.length).fill(false)
              );

    const onChangeSelectCond = (select)  => {
        console.log( condPago.find( (item)=> (item.id === select.value)) );
        //servicios[2].status = true;
        //setUpdateDataPDF(!updateDataPDF);
        setCondPagoSelect(select.value);
        //console.log(servicios)
    }
    const onChangeServicio = (dat) => {
        console.log("serv",dat.checked,dat.id);
        //servicios[dat.id].status=dat.checked;
        //setServicios({...servicios,["status"]:dat.checked});
       // const updateServicios = servicios.map((item, index) =>
       // index === dat.id ? {["name"]:item.name,["status"]:!item.status,["id"]:item.id} : item
       // );
       const updatedCheckedState = servState.map((item, index) =>
        (index == dat.id) ? !item : item
        );
        setServState(updatedCheckedState);
        console.log("Update Servicios:",servState)

    }
    const updatePDF =() =>{
        console.log("servicios:",servicios);
        //setUpdateDataPDF(!updateDataPDF);
        
        setVerPdf(true);
    }

    //console.log("edit quote:",editQuoteData);
    const newQuoteClick = () =>{
        reset();
        setNewQuote(true);
        var curr = new Date();
        curr.setDate(curr.getDate() );
        var date = curr.toISOString().substring(0,10);
        setValue("quote_date",date)
        
        setValue("insurance_value","0,35");
        setValue("validity",30);
        

        console.log(date);
    }; //click crear nueva solicitud
    function stringToFloat (num){
        if(num)
        {
            var numString = (num.replace(".","")).replace(",",".");
            var numFloat = parseFloat(numString);
            return numFloat ? numFloat : 0
        }
        else{
            return 0
        }
        
    }
    function floatToString (num = 0){
        
        if(num === null)
        {
            return "";
        }
        else
        {
            var numFormat = num.toLocaleString("es-BO") ;
            return numFormat ? numFormat : ""
           
        }
        
    }
    function  calculateInsurance()
    {   
        var insu_flaot = stringToFloat(getValues("insurance_value"));
        var value = stringToFloat(getValues("value"));
        var ins_val = Math.round(insu_flaot*value)/100.0;
        setValue("amount5", (ins_val < min_insur) ? "20" : floatToString(ins_val))
        console.log("cal",ins_val);

    }
    const { watch,control ,setValue,getValues ,reset,register, handleSubmit, formState: { errors } } = useForm(
        {
            defaultValues: {
                amount1: "0",
                amount2: "0",
                amount3: "0",
                amount4: "0",
                amount5: "0",
                concept1: " ",
                concept2: " ",
                concept3: " ",
                concept4: " ",
                concept5: " ",
                concept1_status: true,
                concept2_status: false,
                concept3_status: false,
                concept4_status: false,
                concept5_status: false,
                description1: " ",
                description2: " ",
                description3: " ",
                description4: " ",
                description5: " ",
                ent_id: 0,
                ent_name: " ",
                id: 0,
                insurance_status: "si",
                insurance_value: " ",
                quote_date: " ",
                quote_number: " ",
                
                user: 0,
                validation_description: " ",
                validation_status: " ",
                validity: 0
                
            }
        }
    );

    const watchRequest = watch("request_parent");
    const watchInsuranceStatus = watch("insurance_status");
    const watchInsuranceValue = watch("insurance_value");
    const watchConcept1_Status = watch("concept1_status");
    const watchConcept2_Status = watch("concept2_status");
    const watchConcept3_Status = watch("concept3_status");
    const watchConcept4_Status = watch("concept4_status");
    const watchConcept5_Status = watch("concept5_status");
    const watchInsuranceOption = watch("insurance_option");
    //const watchC1 = watch("concept2_status");
    //console.log("Watch C1",watchC1);
    function updateEditQuote  (quoEdit) {
        
        console.log("quoteData: ",quoEdit);
        setValue("insurance_option", " ");
        Object.keys(quoEdit).forEach((key, index) => {
            console.log(key,quoEdit[key]);
            (key === "request_parent") &&  setValue(key, {label:" ",value:quoEdit[key].id});
            (!isNaN(quoEdit[key])  && key !== "concept1_status" && key !== "concept2_status" && key !== "concept3_status" && key !== "concept4_status" && key !== "concept5_status") && setValue(key,floatToString(quoEdit[key]));
            (key === "insurance_status" ) && setValue(key, quoEdit.insurance_status === true ? "si" : "no");
            
            ( key === "concept1_status" ) && setValue(key,(quoEdit[key]));
            ( key === "concept2_status" ) && setValue(key,(quoEdit[key]));
            ( key === "concept3_status" ) && setValue(key,(quoEdit[key]));
            ( key === "concept4_status" ) && setValue(key,(quoEdit[key]));
            ( key === "concept5_status" ) && setValue(key,(quoEdit[key]));
            (key !== "insurance_status" && ( isNaN(quoEdit[key]) ) && key !== "request_parent") &&  setValue(key,quoEdit[key]);
            
             
        });
        
        console.log("quoteData: ",quoEdit);
        
    }
    const onError = (errors, e) => 
    {
        toast.warning("Debe llenar los campos obligatorios de rojo");

    }
    const onSubmit = data => { 
        //console.log("datos: ",data);
        //console.log("fecha: ",getValues("quoteDate"));
        var auxDate = getValues("quote_date");
        //data.quoteDate = auxDate;
        var year = auxDate.substring(0,4);
        //console.log("fecha: ",getValues("quoteDate"));
        console.log("datos: ",data);
        if(newQuote)
        {
            const am1  = stringToFloat(data.amount1) ;
            const am2  = stringToFloat(data.amount2);
            const am3  = stringToFloat(data.amount3) ;
            const am4  = stringToFloat(data.amount4) ;
            const am5  = stringToFloat(data.amount5) ;
            const ins_val = stringToFloat(data.insurance_value);
            UserService.getIdRequest(2).then(id => {
                UserService.postQuote(
                                    id.id_number+ "/"+year,data.quote_date,(data.insurance_status === "si") ? true: false,ins_val,data.validity,data.validation_status,
                                    data.validation_description,data.concept1,am1,data.description1,data.concept2,am2,data.description2,
                                    data.concept3,am3,data.description3,data.concept4,am4,data.description4,data.concept5,am5,
                                    data.description5,1,data.request_parent.value,data.concept1_status,data.concept2_status,data.concept3_status,
                                    data.concept4_status,data.concept5_status,stringToFloat(data.totalAmount) 
                                    //).then((response)=>{if(response.data){console.log("Post Respond: ",response.data); setNewQuote(false);reset()} else{console.log(response.data);}});
                                    ).then((response)=>{if(response.status == 201){console.log("Post Respond: ",response.data); setNewQuote(false);reset();  toast.success("Cotizacion Creada Exitosamente!"); } else{console.log(response)} }).catch(  error =>{   toast.error("Error al Crear la Cotizacion "); console.log("Error: ",error)}    );
                                    //).then((response)=>{{console.log("Post Respond: ",response.data); } });
    
                                    } );
                                    
        }
        if(editQuote)
        {
            
            const am1  = stringToFloat(data.amount1) ;
            const am2  = stringToFloat(data.amount2);
            const am3  = stringToFloat(data.amount3) ;
            const am4  = stringToFloat(data.amount4) ;
            const am5  = stringToFloat(data.amount5) ;
            const ins_val = stringToFloat(data.insurance_value);
            console.log("id",data.id);
                UserService.putQuote(
                                    data.quote_number,data.quote_date,(data.insurance_status === "si") ? true: false,ins_val,data.validity,data.validation_status,
                                    data.validation_description,data.concept1,am1,data.description1,data.concept2,am2,data.description2,
                                    data.concept3,am3,data.description3,data.concept4,am4,data.description4,data.concept5,am5,
                                    data.description5,1,data.request_parent.value,data.concept1_status,data.concept2_status,data.concept3_status,
                                    data.concept4_status,data.concept5_status,stringToFloat(data.totalAmount) ,data.id
                                    //).then((response)=>{if(response.data){console.log("Put Respond: ",response.data); setEditQuote(false);reset()} else{console.log(response.data);}});
                                    ).then((response)=>{if(response.status == 200){console.log("Post Respond: ",response.data); setEditQuote(false);reset();toast.success("Cotizacion Editada Exitosamente!");} }).catch(  error =>{   toast.error("Error al editar la Cotizacion "); console.log("Error: ",error)}    );
                                    //).then((response)=>{{console.log("Post Respond: ",response.data); } });
    
                                    
           console.log("editQuote",data);
        }


    }
    console.log("Erros: ",errors);
    //const data = useLocation();
    //console.log("row: ",data);
    

      const [verCotiz,setVerCotiz] = useState(false);
      const [verPdf,setVerPdf] = useState(false);
      const verCotizacion = (datos) =>
    { 
        setVerCotiz(true);
        setNewQuote(false);
        setEditQuote(false);
       console.log("ver",datos);
       console.log("route",datos.request_parent.request_route.route_id)
       
       setPdfData(datos);

        if(datos.request_parent.request_route.route_id === 1)//brasil-bolivia
        {//1,2,3,4,5,10
            const updatedCheckedState = servState.map((item, index) =>
            (index == 0 || index == 1 || index == 2 || index == 3 || index == 4 || index == 9 ) ? true : false
            );
            setServState(updatedCheckedState);
            }
        if(datos.request_parent.request_route.route_id === 2)//argentina-bolivia
        {
            const updatedCheckedState = servState.map((item, index) =>
            (index == 0 || index == 1 || index == 2 || index == 7 || index == 6 || index == 9 ) ? true : false
            );
            setServState(updatedCheckedState);
            }
    }
      


    

      
    const [startDate, setStartDate] = useState("2000-01-01");
    const [endDate, setEndDate] = useState("3000-01-01");
    const onChangeStartDate = (e) =>{
        setStartDate(e.target.value);
    }
    const onChangeEndDate = (e) =>{
        setEndDate(e.target.value);
    }

    if(watchInsuranceStatus  === "no")
    {
        
        setValue("concept5"," ");
        setValue("amount5","0");
        setValue("description5"," ");
        watchConcept5_Status && setValue("concept5_status",false);
    }

    
    if (watchInsuranceValue && watchInsuranceStatus  === "si" ) 
    {
        calculateInsurance();
        !watchConcept5_Status && setValue("concept5_status",true)
    }
  

    console.log("requests: ",requests);
    
    var reqTemp;
    console.log("Watch Request: ",watchRequest);
    console.log(" Request: ",getValues('request_parent'));
   
    (watchConcept2_Status === true)  && (setValue("description2","TOTAL"));
    (watchConcept3_Status === true) && (setValue("description3","TOTAL"));
    (watchConcept4_Status === true) && (setValue("description4","TOTAL"));
    counter++;
   //const insurance_status = watch("insurance_status");




    useEffect(()=>{ 
        const getData = () => {
            UserService.getRequest().then(req => {setRequests(req)}).catch(() => {setErrorMessage("No se puede obtener los datos del servidor");});
            UserService.getContact().then(con => {setContacts(con)}).catch(() => {setErrorMessage("No se puede obtener los datos del servidor");});
            UserService.getEnterprise().then(ent => {setEnterprises(ent)}).catch(() => {setErrorMessage("No se puede obtener los datos del servidor");});
            
            
    };
    getData();    
    },[]);
    console.log("editQuoteData ",editQuoteData);
    React.useEffect( () => {
        console.log("UseEffect Edit");
        return (
            editQuoteData && (updateEditQuote(editQuoteData))
        );
    },[editQuoteData]
    );

    useEffect(()=>{ 
        const getData = () => {
            if(watchRequest && !reqTemp )
            {
               
                
                    console.log("actulizado");
                    (reqTemp =  requests.find(ob => {return ob.id === watchRequest.value}) );
                    (setValue("client",contacts.find(ob =>{return ob.id === reqTemp.request_contact.contact_id} ).enterprise.enterprise_name));
                    (setValue("contact",reqTemp.request_contact.name + " " +reqTemp.request_contact.last_name  ));
                    (setValue("city",enterprises.find(ob => {return ob.id === reqTemp.request_contact.enterprise_id}).city_name));
                    (setValue("phone",reqTemp.request_contact.cellphone));
                    (setValue("route",reqTemp.request_route.route_description));
                    
                    (setValue("exporter", reqTemp.supplier_selection === true ?  (reqTemp.contact_supplier ? reqTemp.contact_supplier.supplier_name.toUpperCase() : 'SELECCIONAR PROVEEDOR') :  reqTemp.exporter.toUpperCase()    ) );
                    (setValue("merchandise",reqTemp.merchandise));
                    (setValue("weight",floatToString(reqTemp.weight) ));
                    (setValue("value",floatToString(reqTemp.value)));
                    (setValue("volume",floatToString(reqTemp.volume)));
                    (setValue("origin",reqTemp.origin));
                    (setValue("destination",reqTemp.destination));
                    (setValue("custom",reqTemp.request_custom.custom + " - " + reqTemp.request_custom.city));
                    if(newQuote){
                        (setValue("concept1","COTIZAMOS FLETE " + reqTemp.origin+" / "+ reqTemp.destination));
                   
                        (setValue("description1","TOTAL"));

                        (watchInsuranceStatus === "si") && (setValue("description5","TOTAL"));
                        (watchInsuranceStatus === "si") && (setValue("concept5","SEGURO TODO RIESGO - BISA"));
                    }

                    
                    //(setValue("amount5",  (reqTemp.value*stringToFloat(getValues("insurance_value"))*0.01).toLocaleString("es-BO") ));
                    calculateInsurance()
                

                
            
            }
            
    };
    getData();    
    },[watchRequest]);
   
    useEffect(()=>{ 
        const getData = () => {
            if(watchRequest && !reqTemp )
            {
               
                
                    

                    (watchInsuranceStatus === "si") && (setValue("description5","TOTAL"));
                    (watchInsuranceStatus === "si") && (setValue("concept5","SEGURO TODO RIESGO - BISA"));

                    (watchInsuranceStatus === "no") && (setValue("description5",""));
                    (watchInsuranceStatus === "no") && (setValue("concept5",""));
                    
                    //(setValue("amount5",  (reqTemp.value*stringToFloat(getValues("insurance_value"))*0.01).toLocaleString("es-BO") ));
                    calculateInsurance()
                

                
            
            }
            
    };
    getData();    
    },[watchInsuranceStatus,watchInsuranceValue]);

    useEffect(()=>{ 
        const getData = () => {
            if(watchRequest && !reqTemp )
            {
               
                
                    

                    (watchInsuranceOption === "0,35") && (setValue("insurance_value","0,35"));
                    (watchInsuranceOption === "0,33") && (setValue("insurance_value","0,33"));

                    (watchInsuranceOption === "0,15") && (setValue("insurance_value","0,15"));
                    
                   
                    
                

                
            
            }
            
    };
    getData();    
    },[watchInsuranceOption]);

    const [filterImporter, setFilterImporter] = useState("");
    const [filterSupplier, setFilterSupplier] = useState("");
    const importerFilterMemo = React.useMemo(() => {
        return (
            <FilterComponent onFilter={e => setFilterImporter(e.target.value)}  filterText={filterImporter}  name = "Cliente"/>
        );
    }, [filterImporter]);
    const supplierFilterMemo = React.useMemo(() => {
        return (
            <FilterComponent onFilter={e => setFilterSupplier(e.target.value)}  filterText={filterSupplier}  name = "Proveedor"/>
        );
    }, [filterSupplier]);
    
    const pdfName = pdfData ? ("Coti" +pdfData.quote_number.substring(0,pdfData.quote_number.length-5)+"-"+pdfData.quote_number.substring(pdfData.quote_number.length-4) +"-"+pdfData.ent_name+"-"+( pdfData.request_parent.supplier_selection ?(pdfData.request_parent.contact_supplier ? pdfData.request_parent.contact_supplier.supplier_name.toUpperCase() : 'SELECCIONAR PROVEEDOR') : pdfData.request_parent.exporter.toUpperCase()) +"-"+
                    floatToString(pdfData.request_parent.weight)+"-"+floatToString(pdfData.request_parent.volume)+"-"+pdfData.request_parent.origin+"-"+pdfData.request_parent.destination +".pdf") : " ";
    console.log("pdfDatos: ", pdfData);
    console.log("pdfName: ", pdfName);

    function consolidarCoti (){
        console.log('Selected Rows: ', selectedRows);
    }

    return ( 
        <div  className='BodyContainer container-fluid vh-100'>

            <div className='row'>

                <div id= 'sidebarMenu' className='col-2 bg-light vh-100 '>

                    <div className="mt-5 vh-100" >
                        
                       {/* <p>{counter}</p>*/  }  
                       <div className="p-3">
                            {importerFilterMemo}
                        </div>
                        <div className="p-3">
                           {supplierFilterMemo}
                        </div>
                        <div className="p-3">
                            <label for="">Fecha de Inicio</label>
                            <input 
                                type="date" 
                                className="form-control" 
                                placeholder="Fecha Inicio"
                                onChange={onChangeStartDate} />
                        </div>
                        <div className="p-3">
                            <label for="">Fecha de Fin</label>
                            <input 
                                type="date" 
                                className="form-control" 
                                placeholder="Fecha Fin" 
                                onChange={onChangeEndDate}/>
                        </div>
                        <div className="p-3">
                            <Link to="/consolidados" state={{ quotes: selectedRows, editConsol : true }} className="btn btn-primary">
                            CONSOLIDAR 
                            </Link>
                           
                        </div>
                        
                    </div>
                </div>

                <div className='col-10'>
                    <div>
                        <ToastContainer
                                position="bottom-center"
                                autoClose = {2500}
                                theme='light'
                        />
                    </div>

                    { !(newQuote || editQuote || verCotiz)  && (

                        <div className='container-fluid h-100'>
                            
                            <div className='row'>

                                <div className="col-11 p-3">
                                    
                                    <h2 className="text-center">LISTA DE COTIZACIONES</h2>
                                    
                                </div>
                                <div className="col-1">
                                    <div className="m-2 ">
                                        <button type="button" className="btn " onClick={newQuoteClick}>
                                            
                                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                            </svg>

                                        </button>
                                    </div>
                                    
                                </div>
                            </div>

                            <div className='row h-100' >
                            {
                                (true) && (

                                    <DataTableQuote
                                    impSearch = {filterImporter}
                                    supSearch = {filterSupplier}
                                    startDateSearch={startDate}
                                    endDateSearch={endDate}
                                    setIDSelected = {setEditQuoteData}
                                    setEditReq = {setEditQuote}
                                    verCoti = {verCotizacion}
                                    setSelectedRows={setSelectedRows}
                               />
                                )
                            }

                             
                              
                            
                           
                            
                            
                            </div>
                            

                        </div>
                        )
                    }
                            {
                                verCotiz && (
                                    <div className='row vh-100'>
                                        <div className='col-5'>
                                            <button className='btn' type = "button" onClick={ () => {setVerCotiz(false); setVerPdf(false)}}>
                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.5 280.6l192 160c20.6 17.2 52.5 2.8 52.5-24.6V96c0-27.4-31.9-41.8-52.5-24.6l-192 160c-15.3 12.8-15.3 36.4 0 49.2zm256 0l192 160c20.6 17.2 52.5 2.8 52.5-24.6V96c0-27.4-31.9-41.8-52.5-24.6l-192 160c-15.3 12.8-15.3 36.4 0 49.2z"></path>
                                                </svg>
                                            </button>
                                            <div className='m-2 input-group'>
                                                <span className=' input-group-text col-md-3'>Cond. de Pago:</span>
                                                                    
                                                <Select
                                                                                
                                                    className = 'col-md-8'
                                                    placeholder="Seleccione condicion de pago" 
                                                    onChange= {(a) => onChangeSelectCond(a)}
                                                    //defaultValue={{ label : condPago[condPagoSelect].name, value: condPago[condPagoSelect].id }}                            
                                                    options = {(condPago.length !== 0)?  condPago.map((option) => ({ label : option.name, value: option.id })) : ([])}
                                                                                
                                                                            
                                                />
                                            </div>
                                            {
                                                servicios.map( (item,index)=> (
                                                    <div className='m-2 input-group'>
                                                        <div className='input-group-text  '>
                                                            <input 
                                                                className="form-check-input mt-0" 
                                                                id={index}
                                                                onChange={ (a) =>(onChangeServicio(a.target))}
                                                                checked = {servState[index]}
                                                                //defaultChecked={item.status}
                                                                type="checkbox"  
                                                                
                                                            />
                                                        </div>
                                                        <span className=' input-group-text col-10 ' style={{whiteSpace:"normal",textAlign:"start"}} >
                                                            {item.name}
                                                        </span>
                                                    </div>
                                                ))
                                            }
                                            <div className='m-2' >
                                                <button className="btn btn-lg btn-secondary" style={{backgroundColor: "navy"}} onClick={() => {navigator.clipboard.writeText(pdfName );toast.info("Nombre Copiado en el Portapeles ")}}>
                                                    GENERAR NOMBRE DEL DOCUMENTO 
                                                </button>
                                            </div>
                                            {
                                                /*
                                                 <div className='m-2' >
                                                    <div  className=' input-group col'>
                                                        <span className=' input-group-text col-md-3' > TOTAL</span>
                                                        <input type="text"   className='col-md-7 form-control' placeholder='' onChange={ (e) => setValorTotal(e.target.value)} value = {valortotal}/>
                                                    </div>
                                                </div>
                                                */ 
                                            }
                                           
                                          
                                            
                                        </div>
                                        <div className='col-7' style={{ height : "100%"}}>
                                            {
                                                (true) && (
                                                    
                                                        <CotizacionPDF 
                                                            quote = {pdfData}
                                                            servs= {servicios}
                                                            servsStatus = {servState}
                                                            cond= {condPago}
                                                            condSel= {condPagoSelect}
                                                            vtotal = {valortotal}
                                                        />
                                                    
                                               
                                               
                                                    
                                                )
                                            }
                                            
                                        </div>
                                        


                                        
                                    </div>
                                    
                                )
                            }

                    { (newQuote || editQuote) && (
                            <div>

                                <div className='m-4 row '>
                                    <div className='col'> 
                                        <button className='btn' type = "button"  onClick={()=>{setNewQuote(false); setEditQuote(false)}}>
                                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.5 280.6l192 160c20.6 17.2 52.5 2.8 52.5-24.6V96c0-27.4-31.9-41.8-52.5-24.6l-192 160c-15.3 12.8-15.3 36.4 0 49.2zm256 0l192 160c20.6 17.2 52.5 2.8 52.5-24.6V96c0-27.4-31.9-41.8-52.5-24.6l-192 160c-15.3 12.8-15.3 36.4 0 49.2z"></path>
                                            </svg>
                                        </button>   
                                    </div>
                                    {(newQuote) &&
                                        <div  className='col-10'>
                                            <h1 >
                                                INGRESE UNA NUEVA COTIZACION
                                            </h1>
                                        </div>}
                                    {(editQuote) &&
                                        <div  className='col-10'>
                                            <h1 >
                                                MODIFICAR COTIZACION
                                            </h1>
                                        </div>}
                                        
                                    <div className='col-1'>
                                            <button type="button" className="btn " >
                                                
                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill="none" stroke="#000" stroke-width="2" d="M1.7507,16.0022 C3.3517,20.0982 7.3367,23.0002 11.9997,23.0002 C18.0747,23.0002 22.9997,18.0752 22.9997,12.0002 M22.2497,7.9982 C20.6487,3.9012 16.6627,1.0002 11.9997,1.0002 C5.9247,1.0002 0.9997,5.9252 0.9997,12.0002 M8.9997,16.0002 L0.9997,16.0002 L0.9997,24.0002 M22.9997,0.0002 L22.9997,8.0002 L14.9997,8.0002">
                                                        </path>
                                                </svg>
            
            
                                            </button>
                                    </div>

                                    
                                </div>
                                
                                <div >
                                <form className='m-5 row' onSubmit={ handleSubmit(onSubmit,onError)}>
                                    <div className='row'>
                                        {
                                            newQuote && (
                                                
                                                <Controller
                                                name="request_parent"
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        options = {(requests.length !== 0)?  requests.map((option) => ({ label : option.request_number + " | "+ 
                                                                                                                                contacts.find(ob =>{return ob.id === option.request_contact.contact_id} ).enterprise.enterprise_name + " | " +
                                                                                                                                ( (option.supplier_selection === true) ?  (option.contact_supplier ? option.contact_supplier.supplier_name.toUpperCase() : 'SELECCIONAR PROVEEDOR') : option.exporter.toUpperCase() )+ " | "+
                                                                                                                                //(option.contact_supplier ? option.contact_supplier.supplier_name.toUpperCase() : 'SELECCIONAR PROVEEDOR')+ " | "+
                                                                                                                                option.weight + "Kgs | "+
                                                                                                                                option.volume + "m3 | "+
                                                                                                                                option.value + "$ | "
                                                                                                                                
                                                                                                                                , value: option.id })) : ([])}
                                                        className = "col m-3"
                                                        
                                                    />
                                                )}
                                                control={control}
                                                defaultValue=""
                                                
                                                rules={{ required: true }}
                                        />
                                            )
                                        }
                                        
                                        
                                    </div>
                                    <div className='row'>
                                        <div  className=' input-group col'>
                                            <span className=' input-group-text col-md-3' > CLIENTE/IMPORT.</span>
                                            <input type="text"   className='col-md-7 form-control' placeholder='' readOnly {...register("client",{required: true})}  />
                                        </div>
                                        <div  className=' input-group  col '>
                                            <span className=' input-group-text col-md-3' > CONTACTO</span>
                                            <input type="text"   className='col-md-7 form-control' placeholder='' readOnly {...register("contact",{required: true})}  />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-4'>
                                            <div  className=' input-group  '>
                                                <span className=' input-group-text col-md-3' > CIUDAD</span>
                                                <input type="text"   className='col-md-7 form-control' placeholder='' readOnly {...register("city",{required: true})}  />
                                            </div>
                                        </div>
                                        <div className='col-4'>
                                            <div  className=' input-group   '>
                                                <span className=' input-group-text col-md-5' >TELEFONO</span>
                                                <input type="text"   className='col-md-6 form-control' placeholder='' readOnly {...register("phone",{required: true})}  />
                                            </div>
                                        </div>     
                                        <div className='col-4'>
                                            <div  className=' input-group   '>
                                                <span className=' input-group-text col-md-5' > FECHA</span>
                                                <input type="date"   className='col-md-6 form-control' placeholder='' style={{backgroundColor: errors.quote_date ? '#f5dce2' : '' }} {...register("quote_date",{required: true})}  />
                                            </div>
                                        </div>
                                        
                                        
                                        
                                    </div>
                                    
                                    <div className='row m-1'>
                                        
                                    </div>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className='row'>
                                                <div  className=' input-group   '>
                                                        <span className=' input-group-text col-md-3' > RUTA</span>
                                                        <input type="text"   className='col-md-7 form-control' placeholder='' readOnly {...register("route",{required: true})}  />
                                                </div>
                                                <div  className=' input-group   '>
                                                    <span className=' input-group-text col-md-3' > PROVEEDOR</span>
                                                    <input type="text"   className='col-md-7 form-control' placeholder='' readOnly {...register("exporter",{required: true})}  />
                                                </div>
                                                <div  className=' input-group   '>
                                                    <span className=' input-group-text col-md-3' > MERCADERIA</span>
                                                    <input type="text"   className='col-md-7 form-control' placeholder='' readOnly style={{backgroundColor: errors.merchandise ? '#f5dce2' : '' }} {...register("merchandise",{required: true})}  />
                                                </div>
                                                <div  className=' input-group   '>
                                                    <span className=' input-group-text col-md-3' > PESO</span>
                                                    <input type="text"   className='col-md-7 form-control' placeholder='' readOnly style={{backgroundColor: errors.weight ? '#f5dce2' : '' }} {...register("weight",{required: true})}  />
                                                </div>
                                                <div  className=' input-group   '>
                                                    <span className=' input-group-text col-md-3' > VOLUMEN</span>
                                                    <input type="text"   className='col-md-7 form-control' placeholder='' readOnly style={{backgroundColor: errors.volume ? '#f5dce2' : '' }} {...register("volume",{required: true})}  />
                                                </div>
                                                <div  className=' input-group   '>
                                                    <span className=' input-group-text col-md-3' > VALOR</span>
                                                    <input type="text"   className='col-md-7 form-control' placeholder=''readOnly  style={{backgroundColor: errors.value ? '#f5dce2' : '' }} {...register("value",{required: true})}  />
                                                </div>
                                                <div  className=' input-group   '>
                                                    <span className=' input-group-text col-md-3' > ORIGEN</span>
                                                    <input type="text"   className='col-md-7 form-control' placeholder='' readOnly style={{backgroundColor: errors.origin ? '#f5dce2' : '' }} {...register("origin",{required: true})}  />
                                                </div>
                                                <div  className=' input-group   '>
                                                    <span className=' input-group-text col-md-3' > DESTINO</span>
                                                    <input type="text"   className='col-md-7 form-control' placeholder='' readOnly style={{backgroundColor: errors.destination ? '#f5dce2' : '' }} {...register("destination",{required: true})}  />
                                                </div>
                                                <div className=' input-group'>
                                                    <span className=' input-group-text col-md-3 ' > ADUANA</span>
                                                    <input   className='form-control col-md-7 ' placeholder='' readOnly {...register("custom",{required: true})}  />
                                                </div>
                                            </div>
                                            
                                            
                                            
                                            
                                            
                                        </div>
                                        <div className='col-2'>

                                        </div>
                                        <div className='col-4 '>    
                                            <div className='row '>
                                                    <div className='m-5'>

                                                    </div>
                                                       
                                                        <div className=' input-group  '>
                                                            <span className=' input-group-text col-4 ' > SEGURO</span>
                                                            <div className='input-group-text'>
                                                                <input type='radio'  className=' form-check-input mt-0 ' placeholder='' defaultChecked value="si" {...register("insurance_status",{required: true})}  />
                                                            </div>
                                                            
                                                            <span className=' input-group-text  ' > SI</span>
                                                            <div className='input-group-text'>
                                                                <input type='radio'  className=' form-check-input mt-0 ' placeholder=''  value="no" {...register("insurance_status",{required: true})}  />
                                                            </div>
                                                            <span className=' input-group-text ' > NO</span>
                                                        </div>
                                                        {
                                                            (watchInsuranceStatus === "si") && (
                                                                <div className='col col-8'>
                                                                    <div className=' input-group  '>
                                                                        <span className=' input-group-text  col-6' > PORCENTAJE</span>
                                                                        <input   className=' col-4' placeholder='' style={{backgroundColor: errors.insurance_value ? '#f5dce2' : '' }} {...register("insurance_value",{required: true})}  />
                                                                        <span className=' input-group-text col' > %</span>
                                                                    </div>
                                                                    <div className=' input-group  '>
                                                                        <span className=' input-group-text  col' > 0,35% </span>
                                                                        <div className='input-group-text'>
                                                                            <input type='radio'  className=' form-check-input mt-0 ' placeholder=''  value="0,35" {...register("insurance_option",{required: false})}  />
                                                                        </div>
                                                                        

                                                                        <span className=' input-group-text  col' >  0,33%  </span>
                                                                        <div className='input-group-text'>
                                                                            <input type='radio'  className=' form-check-input mt-0 ' placeholder=''  value="0,33" {...register("insurance_option",{required: false})}  />
                                                                        </div>
                                                                    

                                                                        <span className=' input-group-text  col' >  0,15%  </span>
                                                                        <div className='input-group-text'>
                                                                            <input type='radio'  className=' form-check-input mt-0 ' placeholder=''  value="0,15" {...register("insurance_option",{required: false})}  />
                                                                        </div>
                                                                    

                                                                    </div>
                                                                </div>
                                                            
                                                            
                                                            )
                                                        }
                                                        
                                            </div>
                                                        
                                               
                                        </div>
                                            
                                        
                                       
                                    
                                                                     
                                    </div>
                                    <div className='row'>
                                            <div className='col-11 '>
                                                <div  className=' input-group  '>
                                                    <div className='input-group-text  '>
                                                        <input 
                                                            className="form-check-input mt-0" 
                                                            {...register("concept1_status", { required: false })} 
                                                            type="checkbox"  
                                                            disabled
                                                        />
                                                        
                                                    </div>
                                                    {
                                                        (watchConcept1_Status) && (
                                                            <>
                                                                <input type="text"   className=' col-md-7 form-control'  placeholder='' style={{backgroundColor: errors.concept1 ? '#f5dce2' : '' }} {...register("concept1",{required: true})}  />
                                                                <span className=' input-group-text  ' >$us</span>
                                                                <input type="text"   className='col-md-2 ' placeholder='' style={{backgroundColor: errors.amount1 ? '#f5dce2' : '' }} {...register("amount1",{required: true})}  />
                                                                <input type="text"   className=' col-md-2 ' placeholder='' {...register("description1",{required: true})}  />
                                                    
                                                            </>
                                                        )
                                                    }
                                                                
                                                </div>
                                                <div  className=' input-group   '>
                                                    <div className='input-group-text  '>
                                                        <input 
                                                            className="form-check-input mt-0" 
                                                            {...register("concept2_status", { required: false })} 
                                                            type="checkbox"  
                                                            
                                                        />
                                                          
                                                    </div>
                                                    {
                                                        (watchConcept2_Status) && (
                                                            <>
                                                                <input type="text"   className='col-md-7 form-control' placeholder='' {...register("concept2",{required: true})}  />
                                                                <span className=' input-group-text ' >$us</span>
                                                                <input type="text"   className='col-md-2' placeholder='' {...register("amount2",{required: true})}  />
                                                                <input type="text"   className='col-md-2' placeholder='' {...register("description2",{required: true})}  />
                                                            </>
                                                        )
                                                    }
                                                   
                                                    
                                                </div>
                                                <div  className=' input-group   '>
                                                    <div className='input-group-text  '>
                                                        <input 
                                                            className="form-check-input mt-0" 
                                                            {...register("concept3_status", { required: false })} 
                                                            type="checkbox"  
                                                        />
                                                    </div>
                                                    {
                                                        (watchConcept3_Status) && (
                                                            <>
                                                                <input type="text"   className='col-md-7 form-control' placeholder='' {...register("concept3",{required: true})}  />
                                                    <span className=' input-group-text ' >$us</span>
                                                    <input type="text"   className='col-md-2 ' placeholder='' {...register("amount3",{required: true})}  />
                                                    <input type="text"   className='col-md-2 ' placeholder='' {...register("description3",{required: true})}  />
                                                    
                                                            </>
                                                        )
                                                    }
                                                </div>
                                                <div  className=' input-group   '>
                                                    <div className='input-group-text  '>
                                                        <input 
                                                            className="form-check-input mt-0" 
                                                            {...register("concept4_status", { required: false })} 
                                                            type="checkbox"  
                                                        />
                                                    </div>
                                                    {
                                                        (watchConcept4_Status) && (
                                                            <>
                                                                <input type="text"   className='col-md-7 form-control' placeholder='' {...register("concept4",{required: true})}  />
                                                    <span className=' input-group-text ' >$us</span>
                                                    <input type="text"   className='col-md-2 ' placeholder='' {...register("amount4",{required: true})}  />
                                                    <input type="text"   className='col-md-2' placeholder='' {...register("description4",{required: true})}  />
                                                    
                                                            </>
                                                        )
                                                    }
                                                </div>
                                                <div  className=' input-group   '>
                                                    <div className='input-group-text  '>
                                                        <input 
                                                            className="form-check-input mt-0" 
                                                            {...register("concept5_status", { required: false })} 
                                                            type="checkbox"
                                                            disabled  
                                                        />
                                                    </div>
                                                    {
                                                        (watchConcept5_Status) && (
                                                            <>
                                                                <input type="text"   className='col-md-7 form-control' placeholder='' {...register("concept5",{required: false})}  />
                                                                <span className=' input-group-text ' >$us</span>
                                                                <input type="text"   className='col-md-2' placeholder='' {...register("amount5",{required: false})}  />
                                                                <input type="text"   className='col-md-2 ' placeholder='' {...register("description5",{required: false})}  />
                                                    
                                                            </>
                                                        )
                                                    }
                                                    
                                                </div>
                                                <div  className=' input-group   '>
                                                   
                                                    
                                           
                                                            
                                                    <label type="text"   className='col-md-7 form-control' disabled  />
                                                    <span className=' input-group-text ' >$us</span>
                                                    <input type="text"   className='col-md-2' placeholder=''  style={{backgroundColor: errors.totalAmount ? '#f5dce2' : '' }} {...register("totalAmount",{required: true})}  />
                                                    <input type="text"   className='col-md-2' placeholder='TOTAL COTIZACÓN' readOnly />
                                                    
                                                            
                                                        
                                                    
                                                    
                                                </div>
                                            </div>
                                    </div>
                                    <div>

                                    </div>
                                    
                                    
                                    

                                    
                                        {
                                            (newQuote) && <button  type="submit">CREAR COTIZACION</button>
                                        }
                                        {
                                            (editQuote) && <button  type="submit">ACTUALIZAR COTIZACION</button>
                                        }
                                        
                                    
                                   
                                    
                                </form>

                                </div>

                            </div>
                        )
                    }


                </div>

            </div>
            
        </div>
    );
}
export default BodyContainerCotizaciones
