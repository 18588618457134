import React, {useEffect,Component,useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './body_container_solicitudes.css';
import { useForm ,Controller} from 'react-hook-form';

import Select from 'react-select';
import FilterComponent from '../FilterComponent/filterComponent';
import DataTableRequest from '../data_Table_Request/dataTableRequest';
import FormContainer from '../form/form_container';
import UserService    from '../../services/user.service';
import { error } from 'jquery';
import userService from '../../services/user.service';

function BodyContainerSolicitudes() {
   

    function stringToFloat (num){
        if(num)
        {
            var numString = (num.replace(".","")).replace(",",".");
            var numFloat = parseFloat(numString);
            return numFloat ? numFloat : 0
        }
        else{
            return 0
        }
        
    }
    function floatToString (num = 0){
        

        var numFormat = num.toLocaleString("es-BO") ;
        return numFormat ? numFormat : " "
    }
       
        
    
    
    const [newRequest,setNewRequest] = useState(false);//estado creacion nueva solicitud
    const [editRequest,setEditRequest] = useState(false);//estado de edicion de solicitud
    const [duplicateRequest,setDuplicateRequest] = useState(false);//estado de edicion de solicitud


    const [update,setUpdate] = useState(false);
    const { reset,getValues,control, watch ,setValue, register, handleSubmit, formState: { errors } } = useForm();
    const newRequestClick = () =>{

        setNewRequest(true);
        reset();
        var curr = new Date();
        curr.setDate(curr.getDate() );
        var date = curr.toISOString().substring(0,10);
        setValue("begin_date",date);
        setValue("transport_type",false);
        setValue("carreta_abier_cant",0);
        setValue("carreta_abierta_enlon_status",false);
        setValue("carreta_baul_cant",0);
        setValue("carreta_baul_status",false);
        setValue("carreta_saider_cant",0);
        setValue("carreta_saider_status",false);
        setValue("truck_abierto_cant",0);
        setValue("truck_abierto_status",false);
        setValue("truck_baul_cant",0);
        setValue("truck_baul_status",false);
        setValue("vagon_cant",0);
        setValue("vagon_status",false);
        setValue("destination","SANTA CRUZ - ALBO");
        setValue("contact_supplier",{label: '',value: 0});
        setValue("supplier_selection",false);

    }; //click crear nueva solicitud
    const onError = (errors, e) => 
    {
        //toast.warning("Debe llenar los campos obligatorios de rojo");
        console.log("Error",errors)
    }
    
    const watchcheckExcl = watch("transport_type", false);

    const watchEnterp = watch("importer");
    const onSubmit = data => {
        //console.log("datos: ",data); 
        var auxDate = getValues("begin_date").toISOString().substring(0,10);
        data.begin_date = auxDate;
        var year = auxDate.substring(0,4);
        
        console.log("datos: ",data);
        if(newRequest || duplicateRequest)
        {
           
            UserService.getIdRequest(1).then(id => {
                UserService.postRequest(
                                    id.id_number+ "/"+year,data.begin_date,data.contact_supplier.value === 0 ? null : data.contact_supplier.value,data.merchandise,stringToFloat(data.weight),stringToFloat(data.value),stringToFloat(data.volume),data.origin,data.destination,data.observations,
                                    (data.transport_type === "exclusivo") ? true : false,stringToFloat(data.carreta_abier_cant) ,data.carreta_abierta_enlon_status,stringToFloat(data.carreta_baul_cant),
                                    data.carreta_baul_status,stringToFloat(data.carreta_saider_cant),data.carreta_saider_status, stringToFloat(data.truck_abierto_cant),data.truck_abierto_status,
                                    stringToFloat(data.truck_baul_cant), data.truck_baul_status,stringToFloat(data.vagon_cant),data.vagon_status,1,data.requestSol.value,data.request_route.value,data.request_custom.value, (data.supplier_selection === "true") ? true : false,data.exporter
                                    ).then((response)=>{if(response.data){console.log("Post Respond: ",response.data); setNewRequest(false);setDuplicateRequest(false);reset()} else{console.log("error postRequest");}});
    
                                    } );
                                    
        }
        if(editRequest)
        {
            UserService.putRequest(
                data.request_number,data.begin_date,data.contact_supplier.value === 0 ? null : data.contact_supplier.value,data.merchandise,stringToFloat(data.weight),stringToFloat(data.value),stringToFloat(data.volume),data.origin,data.destination,data.observations,
                (data.transport_type === "exclusivo") ? true : false,stringToFloat(data.carreta_abier_cant),data.carreta_abierta_enlon_status,stringToFloat(data.carreta_baul_cant),
                data.carreta_baul_status,stringToFloat(data.carreta_saider_cant),data.carreta_saider_status, stringToFloat(data.truck_abierto_cant),data.truck_abierto_status,
                stringToFloat(data.truck_baul_cant), data.truck_baul_status,stringToFloat(data.vagon_cant),data.vagon_status,1,data.requestSol.value,data.request_route.value,data.request_custom.value,data.id,(data.supplier_selection === "true") ? true : false,data.exporter
                ).then((response)=>{if(response.data){console.log("Post Respond: ",response.data);setEditRequest(false);reset();} else{console.log("error postRequest");}});


        }
       
        //console.log("request: ",request);
      
    }
    //console.log("Erros: ",errors);
    
    //------------------------Nueva solicitud-----------------------------------------------
    const [request,setRequest] = useState(
        {
            
            request_number: "",
            begin_date: "",
            exporter: "",
            merchandise: "",
            weight: 0,
            value: 0,
            origin: "",
            destination: "",
            observations: "TIEMPO DE TRÁNSITO DE  5 A 7 DIAS HÁBILES",
            transport_type: false,
            carreta_abierta_enlon: 0,
            carreta_abierta_enlon_status: false,
            carreta_baul: 0,
            carreta_baul_status: false,
            carreta_saider: 0,
            carreta_saider_status: false,
            truck_abierto_enlon: 0,
            truck_abierto_enlon_status: false,
            truck_baul: 0,
            truck_baul_status: false,
            vagon: 0,
            vagon_status: false,
            status: "",
            user: 0,
            request_contact: 0,
            request_route: 0,
            request_custom: 0,
            contact_supplier : 0,
            supplier_selection: false,
          }
    );

    const [editReqData,setEditReqData] = useState({});
    //console.log("request edit :",editReqData);

    function updateEditRequest  (reqEdit) {
        //reset();
        setValue("contact_supplier",{label: '',value: 0});
        console.log("Edit:",reqEdit);
        setValue("importer",{label: reqEdit.ent_name, value: reqEdit.ent_id });
        
        Object.keys(reqEdit).forEach((key, index) => {
            (!isNaN(reqEdit[key]) && (key !== "carreta_abierta_enlon_status" ) && (key !== "carreta_baul_status" ) &&
                                    (key !== "carreta_saider_status" ) && (key !== "ent_id" ) &&
                                    (key !== "id" ) && (key !== "transport_type" ) &&
                                    (key !== "truck_abierto_status" ) && (key !== "truck_baul_status" )&&
                                    (key !== "user" ) && (key !== "vagon_status" ) &&  (key !== "contact_supplier" ) && (key !== "supplier_selection") ) && setValue(key,floatToString(reqEdit[key]));
            (key === "request_route" ) && setValue("request_route",{label: reqEdit.request_route.route_description   , value: reqEdit.request_route.route_id });
            (key === "request_contact" ) && setValue("requestSol",{label: reqEdit.request_contact.name + " "+reqEdit.request_contact.last_name   , value: reqEdit.request_contact.contact_id });
            (key === "request_custom" ) && setValue("request_custom",{label: reqEdit.request_custom.custom + " " + reqEdit.request_custom.city   , value: reqEdit.request_custom.custom_id });
            (key === "transport_type" ) && setValue("transport_type", reqEdit.transport_type === true ? "exclusivo" : "consolidado");
            (key === "contact_supplier" ) && setValue("contact_supplier",{label: reqEdit.contact_supplier.supplier_name    , value: reqEdit.contact_supplier.supplier_id });
            (key === "supplier_selection" ) && setValue("supplier_selection",reqEdit.supplier_selection === true ? "true" : "false");
            (   ( ((key === "carreta_abierta_enlon_status" ) || (key === "carreta_baul_status" ) ||
            (key === "carreta_saider_status" ) || (key === "ent_id" ) ||
            (key === "id" ) ||
            (key === "truck_abierto_status" ) || 
            (key === "user" ) || (key === "vagon_status" ))  )  || ( key !== "request_contact" && key !== "request_route" &&  (key !== "contact_supplier" ) && key !== "request_custom" && key !== "transport_type" && key !== "supplier_selection" && isNaN(reqEdit[key])  )) && setValue(key,reqEdit[key]);
            
             console.log(key,reqEdit[key]);
        });
      
        

    }
    React.useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            console.log("watch: ",value, name, type); 
          
        });
       
        return () => subscription.unsubscribe();
      }, [watch]);

    React.useEffect( () => {
        return (
            editReqData && (updateEditRequest(editReqData))
        );
    },[editReqData]
    );

      const [errorMessage,setErrorMessage] = useState([]);
      const [requests,setRequests] = useState([
       
      ]);
      const [routes,setRoutes] = useState([
       
      ]);
      const [customs,setCustoms] = useState([
        //{
        //    id: 0,
        //    state: true,
        //    custom_state: "string",
        //    custom: "string",
        //   city: 0
        //  }
      ]);
      const [contacts,setContacts] = useState(
            [
               
            ]
        );
        
      //arrays de las empresas(nombre,direccion) registradas en las base de datos
        //arrays de las empresas(nombre,direccion) registradas en las base de datos
    const [enterprises,setEnterprises] = useState(
        [ 
           
        ]
        
    );

        const [contactSuppliers,setContactSuppliers] = useState([]);

    const opciones = [
        { value: 'value1', label: 'label1',name: 'name1' },
        { value: 'value2', label: 'label2',name: 'name2' },   
        { value: 'value3', label: 'label3',name: 'name3' }
      ];
      const opciones1 = [
        {label: "label1",value: "value1"},
        {label: "label2",value: "value2"},
        {label: "label3",value: "value3"},

      ];
      const rutas = [
        {label: "Ruta 1",value: 1},
        {label: "Ruta 2",value: 2},
        {label: "Ruta 3",value: 3},

      ];
      const aduanas = [
        {label: "Aduana 1",value: 1},
        {label: "Aduana 2",value: 2},
        {label: "Aduana 3",value: 3},

      ];


      useEffect(()=>{ 
        const getData = () => {
            
            UserService.getContact().then(cont => {setContacts(cont);}).catch( () => {setErrorMessage("No se puede obtener los datos del servidor");} );
            UserService.getEnterprise().then(ent => {setEnterprises(ent);}).catch( () => {setErrorMessage("No se puede obtener los datos del servidor");} );
            UserService.getCustom().then(cust => {setCustoms(cust)}).catch(() => {setErrorMessage("No se puede obtener los datos del servidor");});
            UserService.getRoute().then(cust => {setRoutes(cust)}).catch(() => {setErrorMessage("No se puede obtener los datos del servidor");});
            UserService.getRequest().then(req => {setRequests(req)}).catch(() => {setErrorMessage("No se puede obtener los datos del servidor");});
            UserService.getContactSupplier().then(sup => {setContactSuppliers(sup)}).catch(() => {setErrorMessage("No se puede obtener los datos del servidor");});
            
            
    };
    getData();    
    },[]); 
    //console.log("Error: ",errors); 
    //console.log("Contacts: ",contacts);
    //console.log("Enterprises: ",enterprises);
    //console.log("Customs: ",customs);
    //console.log("Routes: ",routes);
    //console.log("Request: ",request);
    //const temp = getValues("importer");
    var filterContacts = [];
    ( watchEnterp)&&( filterContacts = contacts.filter(item => (item.enterprise.enterprise_id === watchEnterp.value)));
    //console.log("watch ent: ",watchEnterp);
    
    const [filterTextEnt, setFilterTextEnt] = useState("");
    const [filterTextCode, setFilterTextCode] = useState("");
    
    const requestEntFilterMemo = React.useMemo(() => {
        return (
            <FilterComponent onFilter={e => setFilterTextEnt(e.target.value)}  filterText={filterTextEnt}  name = "Empresa"/>
        );
    }, [filterTextEnt]);
    const requestCodeFilterMemo = React.useMemo(() => {
        return (
            <FilterComponent onFilter={e => setFilterTextCode(e.target.value)}  filterText={filterTextCode}  name = "Codigo"/>
        );
    }, [filterTextCode]);

    const [startDate, setStartDate] = useState("2000-01-01");
    const [endDate, setEndDate] = useState("3000-01-01");
    const onChangeStartDate = (e) =>{
        setStartDate(e.target.value);
    }
    const onChangeEndDate = (e) =>{
        setEndDate(e.target.value);
    }
    console.log("errors",errors);

    return ( 
        <div  className='BodyContainer container-fluid vh-100'>

            <div className='row'>

                <div id= 'sidebarMenu' className='col-2 bg-light vh-100 '>

                    <div className="mt-5 vh-100" >
                        
                        <div className="p-3">
                           
                        {requestEntFilterMemo} 
                         
                        </div>
                        <div className="p-3">
                        {requestCodeFilterMemo}
                        </div>
                        <div className="p-3">
                            <label>Fecha de Inicio</label>
                            <input type="date" onChange={onChangeStartDate} className="form-control" placeholder="Fecha Inicio" />
                        </div>
                        <div className="p-3">
                            <label>Fecha de Fin</label>
                            <input type="date" onChange={onChangeEndDate} className="form-control" placeholder="Fecha Fin" />
                        </div>
                                               
                    </div>
                </div>

                <div className='col-10'>

                    { !(newRequest || editRequest || duplicateRequest) && (

                            <div className='container-fluid'>
                                <div className='row'>

                                    <div className="col-11 p-3">
                                        
                                        <h2 className="text-center">LISTA DE SOLICITUDES</h2>
                                        
                                    </div>
                                    <div className="col-1">
                                        <div className="m-2 ">
                                            <button type="button" className="btn " onClick={newRequestClick}>
                                                
                                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                                </svg>

                                            </button>
                                        </div>
                                        
                                    </div>
                                </div>

                                <div className='row'>
                                    
                                {
                                !update && (
                                    <DataTableRequest
                                        entSearch = {filterTextEnt}
                                        nameSearch = {filterTextCode}
                                        startDateSearch={startDate}
                                        endDateSearch={endDate}
                                        setIDSelected = {setEditReqData}
                                        setEditReq = {setEditRequest}
                                        setDupliReq = {setDuplicateRequest}
                                    /> 
                            ) 
                       }
                                
                                </div>
                              
                                

                            </div>
                        )
                    }

                    { (newRequest || editRequest || duplicateRequest) && (
                            <div>

                                <div className='m-4 row '>
                                    <div className='col'> 
                                        <button className='btn' type = "button"  onClick={()=>{setNewRequest(false); setEditRequest(false);setDuplicateRequest(false)}}>
                                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.5 280.6l192 160c20.6 17.2 52.5 2.8 52.5-24.6V96c0-27.4-31.9-41.8-52.5-24.6l-192 160c-15.3 12.8-15.3 36.4 0 49.2zm256 0l192 160c20.6 17.2 52.5 2.8 52.5-24.6V96c0-27.4-31.9-41.8-52.5-24.6l-192 160c-15.3 12.8-15.3 36.4 0 49.2z"></path>
                                            </svg>
                                        </button>   
                                    </div>
                                    {(newRequest) &&
                                        <div  className='col-10'>
                                            <h1 >
                                                INGRESE UNA NUEVA SOLICITUD
                                            </h1>
                                        </div>}
                                    {(editRequest) &&
                                        <div  className='col-10'>
                                            <h1 >
                                                MODIFICAR SOLICITUD
                                            </h1>
                                        </div>}
                                    {(duplicateRequest) &&
                                        <div  className='col-10'>
                                            <h1 >
                                                DUPLICAR SOLICITUD
                                            </h1>
                                        </div>}
                                    <div className='col-1'>
                                            <button type="button" className="btn " onClick>
                                                
                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill="none" stroke="#000" stroke-width="2" d="M1.7507,16.0022 C3.3517,20.0982 7.3367,23.0002 11.9997,23.0002 C18.0747,23.0002 22.9997,18.0752 22.9997,12.0002 M22.2497,7.9982 C20.6487,3.9012 16.6627,1.0002 11.9997,1.0002 C5.9247,1.0002 0.9997,5.9252 0.9997,12.0002 M8.9997,16.0002 L0.9997,16.0002 L0.9997,24.0002 M22.9997,0.0002 L22.9997,8.0002 L14.9997,8.0002">
                                                        </path>
                                                </svg>
            
            
                                            </button>
                                    </div>

                                    
                                </div>
                                
                                <div >
                                <form className='m-4 row' onSubmit={ handleSubmit(onSubmit,onError)}>

                                    <div className='col-md-5 col ' >
                                        <div  className='m-2 input-group'>
                                            <span className=' input-group-text col-md-3' > FECHA</span>
                                            <input type="date"   className='col-md-7 form-control' placeholder='Fecha' {...register("begin_date",{required: true,valueAsDate: true,})}  />
                                        </div>
                                       
                                        <div className='m-2 input-group'>
                                            <span className=' input-group-text col-md-3' > EMPRESA</span>
                                            <Controller
                                                name="importer"
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                       options = {(enterprises.length !== 0)?  enterprises.map((option) => ({ label : option.name, value: option.id })) : ([])}
                                            
                                                        className = "col"
                                                    
                                                    />
                                                )}
                                                control={control}
                                                defaultValue=""
                                                rules={{ required: true }}
                                            />
                                        </div>
                                        <div className='m-2 input-group'>
                                            
                                            <span className=' input-group-text col-md-4 text-wrap' >CLIENTE/IMPORT.</span>
                                            <Controller
                                                name="requestSol"
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        options = {(filterContacts.length !== 0)?  filterContacts.map((option) => ( ({ label : option.name_contact + " " + option.last_name_contact  , value: option.id })   )) : ([])}
                                                        
                                                        className = "col "
                                                    
                                                    />
                                                )}
                                                control={control}
                                                defaultValue=""
                                                rules={{ required: true }}
                                            />
                                        </div>
                                        <div  className='m-2 input-group'>
                                            <div className='input-group-text  '>
                                                <input  className="form-check-input mt-0"  {...register("supplier_selection", { required: true })} type="radio" value = "false"  />
                                            </div>
                                            <span className=' input-group-text col-md-2' > PROVEEDOR</span>
                                            <input type="text"   className='col-md-7 form-control ' placeholder='PROVEEDOR' {...register("exporter",{required: false})}  />
                                        </div>

                                        <div className='m-2 input-group'>
                                            <div className='input-group-text  '>
                                                <input  className="form-check-input mt-0"  {...register("supplier_selection", { required: true })} type="radio" value = "true"  />
                                            </div>
                                            <span className=' input-group-text col-md-2' > PROVEEDOR</span>
                                            <Controller
                                                name="contact_supplier"
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                       options = {(contactSuppliers.length !== 0)?  contactSuppliers.map((option) => ({ label : option.supplier_parent.supplier_name, value: option.id })) : ([])}
                                            
                                                        className = "col "
                                                    
                                                    />
                                                )}
                                                control={control}
                                                
                                                rules={{ required: true }}
                                            />
                                        </div>

                                        <div  className='m-2 input-group'>
                                            <span className=' input-group-text col-md-3' > PESO</span>
                                            <input type="text"  className='form-control ' placeholder='' {...register("weight",{required: true})}  />
                                            <span className=' input-group-text ' > KG</span>
                                            {/*
                                                <select className='input-group-text' {...register("requestUnitWeight",{required: true})}> 
                                                        <option value="KG">KG</option>
                                                        <option defaultValue="TON">TON</option>
                                                        <option value="LIB">LIB</option>
                                                </select>*/
                                            }
                                            
                                        </div>

                                        <div  className='m-2 input-group'>
                                            <span className=' input-group-text col-md-3' > VOLUMEN</span>
                                            <input type="text"   className='form-control' placeholder='' {...register("volume",{required: true})}  />
                                            <span className=' input-group-text' > m3</span>
                                            {/*
                                            <select className='input-group-text' {...register("requestUnitVolume",{required: true})}> 
                                                    <option defaultValue="m3">m3</option>
                                                    <option value="cm3">cm3</option>
                                                    <option value="pulg3">pulg3</option>
                                            </select>*/
                                            }
                                        </div>

                                        <div  className='m-2 input-group'>
                                            <span className=' input-group-text col-md-3' > VALOR</span>
                                            <input type="text"   className='form-control ' placeholder='' {...register("value",{required: true})}  />
                                            <span className=' input-group-text '>$</span>
                                        </div>
                                        <div className='m-2 input-group'>
                                            <span className=' input-group-text col-md-3' > MERCADERIA</span>
                                            <input type="text"   className='form-control ' placeholder='' {...register("merchandise",{required: true})}  />
                                        </div>
                                        <div className='m-2 input-group'>
                                            <span className=' input-group-text col-md-3' > RUTA</span>
                                            <Controller
                                                name="request_route"
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        options = {(routes.length !== 0)?  routes.map((option) => ({ label : option.route, value: option.id })) : ([])}
                                                        className = "col"
                                                        
                                                    />
                                                )}
                                                control={control}
                                                defaultValue=""
                                                rules={{ required: true }}
                                            />
                                        </div>

                                        <div className='m-2 input-group'>
                                            <span className=' input-group-text col-md-3' > ORIGEN</span>
                                            <input type="text"   className='form-control' placeholder='' {...register("origin",{required: true})}  />
                                        </div>

                                    </div>
                                    
                                    <div className='col-md-1 col ' ></div>
                                    <div className='col-md-5 col ' >
                                        
                                        
                                            

                                        
                                        <div className='m-2 input-group'>
                                            <span className=' input-group-text col-md-3' > DESTINO</span>
                                            <input type="text"   className='form-control ' placeholder='' {...register("destination",{required: true})}  />
                                        </div>

                                        <div className='m-2 input-group'>
                                            <span className=' input-group-text col-md-3' > ADUANA</span>
                                            <Controller
                                                name="request_custom"
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        options = {(customs.length !== 0)?  customs.map((option) => ({ label : option.custom + " "+ option.city , value: option.id })) : ([])}
                                                        className = "col"
                                                        
                                                    />
                                                )}
                                                control={control}
                                                defaultValue=""
                                                rules={{ required: true }}
                                            />
                                        </div>

                                        <div className='m-2 input-group'>
                                            <span className=' input-group-text col-md-3 ' > OBSERVACION</span>
                                            <textarea   className='form-control  ' placeholder='' {...register("observations",{required: true , value: 'TIEMPO DE TRÁNSITO DE 5 A 7 DIAS HÁBILES.'})}  />
                                        </div>

                                        <div className='m-2 input-group'>
                                           
                                            <div className='input-group-text  '>
                                                <input  className="form-check-input mt-0"  {...register("transport_type", { required: true })} type="radio" value = "consolidado"  defaultValue={false}/>
                                            </div>
                                            <span className=' input-group-text col' > Consolidado</span>
                                            
                                            
                                            <div className='input-group-text  '>
                                                <input className="form-check-input mt-0" {...register("transport_type", { required: true })} type="radio" value="exclusivo" />
                                            </div>
                                            <span className=' input-group-text col' > Exclusivo</span>
                                            
                                           
                                        </div>
                                        { (watchcheckExcl && getValues("transport_type")=="exclusivo" ) &&
                                            
                                            <div>
                                                <div className='m-2 input-group'>
                                                    <div className='input-group-text  '>
                                                        {
                                                            (newRequest) && (
                                                                
                                                            <input  
                                                                className="form-check-input mt-0"  
                                                                {...register("carreta_abierta_enlon_status", { required: false })} 
                                                                type="checkbox" 
                                                                defaultChecked
                                                                
                                                            />
                                                            )
                                                        }
                                                        {
                                                            (editRequest || duplicateRequest) && (
                                                            <input  
                                                                className="form-check-input mt-0"  
                                                                {...register("carreta_abierta_enlon_status", { required: false })} 
                                                                type="checkbox" 
                                                                
                                                                
                                                            />
                                                            )
                                                        }
                                                        
                                                    </div>                             
                                                    <span className=' input-group-text col' > Carreta Abierta Enlon.</span>
                                                    <input 
                                                        type="text"   
                                                        className='form-control ' 
                                                        placeholder='Cantidad'  
                                                        {...register("carreta_abier_cant",{required: false})}  
                                                        defaultValue={0}
                                                    />
                                        
                                        
                                                </div>
                                                <div className='m-2 input-group'>
                                                    <div className='input-group-text  '>
                                                        <input 
                                                            className="form-check-input mt-0" 
                                                            {...register("carreta_baul_status", { required: false })} 
                                                            type="checkbox"  
                                                        />
                                                    </div>
                                                    <span className=' input-group-text col' > Carreta Baul</span>
                                                    <input 
                                                        type="text"   
                                                        className='form-control ' 
                                                        placeholder='Cantidad' 
                                                        {...register("carreta_baul_cant",{required: false})}  
                                                        defaultValue={0}
                                                    />
                                        
                                                </div>
                                                <div className='m-2 input-group'>
                                                    <div className='input-group-text  '>
                                                        <input 
                                                            className="form-check-input mt-0" 
                                                            {...register("carreta_saider_status", { required: false })} 
                                                            type="checkbox"  
                                                        />
                                                    </div>
                                                    <span className=' input-group-text col' > Carreta Saider</span>
                                                    <input 
                                                        type="text"   
                                                        className='form-control ' 
                                                        placeholder='Cantidad' {...register("carreta_saider_cant",{required: false})}  
                                                        defaultValue={0}
                                                    />
                                        
                                                </div>
                                                <div className='m-2 input-group'>
                                                    <div className='input-group-text  '>
                                                        <input 
                                                            className="form-check-input mt-0" 
                                                            {...register("truck_abierto_status", { required: false })} 
                                                            type="checkbox" 
                                                        />
                                                    </div>
                                                    <span className=' input-group-text col' > Truck Abierto Enlonado</span>
                                                    <input 
                                                        type="text"   
                                                        className='form-control ' 
                                                        placeholder='Cantidad' 
                                                        {...register("truck_abierto_cant",{required: false})}  
                                                        defaultValue={0}
                                                    />
                                        
                                                </div>
                                                <div className='m-2 input-group'>
                                                    <div className='input-group-text  '>
                                                        <input 
                                                            className="form-check-input mt-0" 
                                                            {...register("truck_baul_status", { required: false })} 
                                                            type="checkbox"  
                                                        />
                                                    </div>
                                                    <span className=' input-group-text col' > Truck Baul</span>
                                                    <input 
                                                        type="text"   
                                                        className='form-control ' 
                                                        placeholder='Cantidad' 
                                                        {...register("truck_baul_cant",{required: false})}  
                                                        defaultValue={0}
                                                    />
                                        
                                                </div>
                                                <div className='m-2 input-group'>
                                                    <div className='input-group-text  '>
                                                        <input 
                                                            className="form-check-input mt-0" 
                                                            {...register("vagon_status", { required: false })} 
                                                            type="checkbox"  
                                                        />
                                                    </div>
                                                    <span className=' input-group-text col' > Vagon</span>
                                                    <input 
                                                        type="text"   
                                                        className='form-control ' 
                                                        placeholder='Cantidad' 
                                                        {...register("vagon_cant",{required: false})}  
                                                        defaultValue={0}
                                                    />
                                        
                                                </div>
                                            </div>
                                            
                                        

                                        }          
                                        

                                    </div>
                                    <div className='col-md-1 col '>

                                    </div>
                                    {(newRequest) && <button  type="submit">CREAR SOLICITUD</button>}
                                    {(editRequest) && <button  type="submit">MODIFICAR SOLICITUD</button>}
                                    {(duplicateRequest) && <button  type="submit">DUPLICAR SOLICITUD</button>}
                                    
                                    
                                   
                                    
                                </form>

                                </div>

                            </div>
                        )
                    }

                    

                  





                </div>

            </div>
            
        </div>
    );
}
export default BodyContainerSolicitudes
