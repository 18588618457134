import axios from "axios";
import React, { Component }  from 'react';
import { useState ,useRef}  from 'react';
import { useNavigate } from 'react-router-dom';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import 'bootstrap/dist/css/bootstrap.min.css';

import 'bootstrap/dist/js/bootstrap.bundle.min';
import './login_container.css';
import AuthService from '../../services/auth.service'
import logoImage from '../../assets/Logo_BBA.png';
import backgroundImage from '../../assets/Transporte1920x1280.jpg';

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const LoginForm = () => {

    let navigate = useNavigate();
    const form = useRef();
    const checkBtn = useRef();

    

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const onChangeUsername = (e) => {
    
      const username = e.target.value;
      setUsername(username);
    };
    const onChangePassword = (e) => {
      const password = e.target.value;
      setPassword(password);
    };
    const handleLogin = (e) => {
      e.preventDefault();
      

     

      setMessage("");
      //setLoading(true);
      //form.current.validateAll();
      //if (checkBtn.current.context._errors.length === 0) {
        AuthService.login(username, password).then(
          () => {
            navigate("../");
            window.location.reload();
            
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            //setLoading(false);
            setMessage(resMessage);
          }
        );
       
      //} else {
      //  setLoading(false);
      //}
      
    };




    return ( 

       
                  
                    <section   className = "backgound_login vh-100" >
                    
                    <div className="container py-5 h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                      <div className="col col-xl-5">
                        <div className="card_login card"   >
                          <div className="row g-0">
                            
                            <div className=" d-flex align-items-center">
                              <div className="card-body p-4 p-lg-5 text-black">
                
                                <form onSubmit={handleLogin} >
                
                                  <div className="d-flex items-align-center mb-3 pb-1">
                                  
                                    
                                        <img src={logoImage} width="224" height="61"  alt=""/>
                                    
                                  </div>
                
                                  <h5 className="textLogin fw-normal mb-3 pb-3" >Iniciar Sesión</h5>
                
                                  <div className="form-outline mb-4">
                                    <input 
                                        id="input-usuario"  
                                        className="form-control form-control-lg" 
                                        placeholder='Usuario' 
                                        value={username}
                                        onChange={onChangeUsername}
                                        validations={[required]}
                                      />
                                    
                                    <label className="form-label" htmlFor="input-usuario">Usuario</label>
                                  </div>
                
                                  <div className="form-outline mb-4">
                                    <input 
                                        type="password" 
                                        id="input-password" 
                                        className="form-control form-control-lg" 
                                        placeholder='Contraseña'
                                        value={password}
                                        onChange={onChangePassword}
                                        validations={[required]}
                                      
                                      />
                                    <label className="form-label" htmlFor="input-password">Contraseña</label>  
                                  </div>
                
                                  <div className="pt-1 mb-4">
                                    <button className="btn btn-dark btn-lg btn-block"  type="submit" >
                                    
                                      Ingresar</button>
                                  </div>
                
                                  
                                </form>
                
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>  
            
                    </section>










  
      
     );
}


export default LoginForm;