
import React, {Component} from 'react';


import 'bootstrap/dist/css/bootstrap.min.css';

import 'bootstrap/dist/js/bootstrap.bundle.min';
import { useEffect, useState } from 'react';

import {BsFillPlusCircleFill} from 'react-icons/bs'
import {BiEdit} from 'react-icons/bi'
import Select from 'react-select';
import UserService    from '../../services/user.service';
import { useForm } from 'react-hook-form';

import userService from '../../services/user.service';

import LoadingSpinner from '../loading/loadingComponent';



const BodyContainerOtros = () => {

    //arrays de los paises almacenadas
    const [countries,setCountries] = useState(
        [
            { 
                id : 0,
                state: true,
                name : "",
            }
        ]
        
    );
    //arrays de los departamentos almacenadas
    const [departments,setDepartments] = useState(
        [
            { 
                id : 0,
                state: true,
                name : "",
                country : 0,
            }
        ]
       
    );
    //arrays de las ciudades almacenadas
    const [cities,setCities] = useState(
        [
            { 
                id : 0,
                state: true,
                name: "",
                id_department: 0,
            }
        ]
       
    );

    //arrays de las empresas(nombre,direccion) registradas en las base de datos
    const [enterprises,setEnterprises] = useState(
        [ 
            {
            id: 0,
            state : true,
            name: "",
            nit:  "",
            }   
        ]
        
    );
    

    //arrays de los contactos(nombre de la persona) regsitrados en la base de datos
    const [sucursals,setSucursals] = useState(
        [
           
        ]
       
    );

        //nueva empresa
   const [ent,setEnt] = useState( 
        {
            id : 0,
            name:"",  
            nit : "",
        }
    );
    const [entTemp,setEntTemp] = useState( 
        {
            id : 0,
            state: true,
            name:"",  
            nit : "",
        }
    );

    const onChangeEnt = (e) => {
        
            setEnt({...ent,
                [e.target.name]: e.target.value.toUpperCase()
            });
        
           // console.log("ent: ", ent);
        
    };

    const [countr,setCountr] = useState(
        {
            id: 0,
            name: "",
          }
      );
    const [countrTemp,setCountrTemp] = useState(
        {
            id: 0,
            state: true,
            name: "",
        }
    );

      const onChangCountr = (e) => {
        setCountr({...countr,
                        [e.target.name]: e.target.value.toUpperCase()
                    });
     
      };

      const [departm,setDepartm] = useState(
        {
            id: 0,
            name: "",
            country : 0,
          }
      );
      const [departmTemp,setDepartmTemp] = useState(
        {
            id: 0,
            state: true,
            name: "",
            country: {
                country_id: 0,
                country_name: ""
                     },
        }
      );

      const onChangDepartm = (e) => {
        setDepartm({...departm,
                        [e.target.name]: e.target.value.toUpperCase()
                    });
     
      };

    const [cit,setCit] = useState(
        {
            id: 0,
            city_name: "",
            id_department: 0,
            
        }
    );
    const [citTemp,setCitTemp] = useState(
        {
            id: 0,
            state: true,
            city_name: "",
            department : {
                country_id: 0,  
                department_id: 0,
                department_name: "",
            }
            
        }
    );

    const onChangeCit = (e) =>{
        setCit({...cit,
            [e.target.name]: e.target.value.toUpperCase()
        });
    };

    const [sucurs,setSucurs] = useState(
        {
            id: 0,
            id_ent : 0,
            id_city: 0,
            address : "",
            
        }
    );
    const [sucursTemp,setSucursTemp] = useState(
        {
            id: 0,
            enterprise: {
                        enterprise_id: 0,
                        enterprise_name: "",
                        enterprise_nit: ""
                        },
            city: {
                    city_id: 0,
                    department_id: 0,
                    city_name: ""
                    },
            address: ""
            
        }
    );

    const onChangeSucurs = (e) =>{
        setSucurs({...sucurs,
            [e.target.name]: e.target.value.toUpperCase()
        });
    };
  


    //--------------estructuras temporales------------------

    //-----------------------------------------------
    //---------------------------------------------------------------------------------------------------------
    //------------------FUNCIONES PARA CREAR O MODIFICAR UNA EMPRESA

     //------------------FUNCIONES PARA CREAR O MODIFICAR UNA EMPRESA
     const [createEnterprise, setCreateEnterprise]= useState(false);   
     const [modifEntterprise,setModifEntterprise] = useState(false);
     
     const createEntClick = () => { 
        setCreateEnterprise(true);  
        setModifEntterprise(false);    
        setEnt({...ent,["nit"]: "",["name"]: "",["id"]:""});
           
    };
     const cancelCreateEntClick = () => { 
        setCreateEnterprise(false); 
        setModifEntterprise(false);  
    };
     const modifEntClick = (e) =>{
        if(entTemp.name === "" || entTemp.nit === "" || entTemp === "")
        {
            console.log("Error");
        } 
        else{
            setModifEntterprise(true);
            setCreateEnterprise(false);
        
          
            
            setEnt({...ent,["nit"]: entTemp.nit,["name"]: entTemp.name,["id"]:entTemp.id});
           
        }
        
             //console.log("Ent_Temp:",entTemp);
             //console.log("Ent:",ent);
         
     }
     //guarda empresa en base de datos  
     const crearEmpresa = () => {
        if(ent.name === "" || ent.nit === "" || ent === "")
        {
            console.log("Error");
        }
        else{
            console.log("ent : " , ent)
           
           UserService.postEnterprise((ent.name),(ent.nit)).then((response)=>{if(response.data){console.log(response.data);UserService.getEnterprise().then(empresas =>setEnterprises(empresas));} else{console.log("error postEmpresa");}});
           setCreateEnterprise(false);
           
        }
        
        
        };
        const modificarEnterp = () =>{

            if(ent.EnterName === "" || ent.EnterAdress === "" || ent === "")
            {
                console.log("Error");
            }
            else{
                
              
                UserService.putEnterprise((ent.name),(ent.nit),(ent.id)).then((response)=>{if(response.data){console.log(response.data);UserService.getEnterprise().then(empresas => setEnterprises(empresas)  );} else{console.log("error putEmpresa");}});
                
                setModifEntterprise(false);
             //   console.log("Empresas",empresas);
             //   console.log("Ent",ent);
            }
        

        }

        const onChangeSelectEnt = (e) =>{
            
            
              
            setEntTemp( enterprises.find( obj => {return obj.id === parseInt(e.value)}));
             
            
          };
        
     //-----------------FUNCIONES PARA CREAR O MODIFICAR PAIS---------------------
     const [createCountry, setCreateCrountry]= useState(false);   
     const [modifCountry,setModifCountry] = useState(false);
     
     const createCountryClick = () => { 
        setCreateCrountry(true);  
        setModifCountry(false);   
        setCountr({...countr,["id"]: "",["name"]: ""});
    };
     const cancelCreateCountryClick = () => { setCreateCrountry(false); setModifCountry(false);  };
     const modifCountryClick = (e) =>{
        
        if(countrTemp.name === "" || entTemp === "")
        {
            console.log("country temp :",countrTemp);
            console.log("Error");
        } 
        else{
            setModifCountry(true);
            setCreateCrountry(false);
        
          
            
            setCountr({...countr,["name"]: countrTemp.name,["id"]:countrTemp.id});
           
        }
        
        
        
     };
     //guarda pais en base de datos  
     const crearCountry = () => {
        if(ent.EnterName === "" || ent === "")
        {
            console.log("Error");
        }
        else{
            
           
            UserService.postCountry((countr.name)).then((response)=>{if(response.data){console.log(response.data);UserService.getCountry().then(count =>setCountries(count));} else{console.log("error postCountry");}});
            setCreateCrountry(false);
           
        }
        
        
     };
     const modificarCountry = () =>{
           
        if(countr.name === "" || countr.id === "" || countr.id === 0 || countr === "")
        {
            console.log("Error");
        }
        else{
            
           
            //UserService.putCountry((countr.name),(countr.id)).then((response)=>{if(response.data){console.log(response.data);UserService.getCountry().then(count =>setCountries(count));} else{console.log("error putCountry");}});
            setCreateCrountry(false);
           
        }
        

    };
    const onChangeSelectCount = (e) =>{
            
            
              
        setCountrTemp( countries.find( obj => {return obj.id === parseInt(e.value)}));
         
        console.log("e: ",e);
      };

     //-----------------FUNCIONES PARA CREAR O MODIFICAR DEPARTAMENTO---------------------
     const [createDepartment, setCreateDepartment]= useState(false);   
     const [modifDepartment,setModifDepartment] = useState(false);
     
     const createDepartmentClick = () => { 
        setCreateDepartment(true);  
        setModifDepartment(false);  
        setDepartm({...departm,["id"]:0,["name"]:"",["country"]: 0 }); 
    };
     const cancelCreateDepartmentClick = () => { setCreateDepartment(false); setModifDepartment(false);  };
     const modifDepartmentClick = (e) =>{
        setModifDepartment(true);
        setCreateDepartment(false);

        if(departmTemp.name === "" || departmTemp === "")
        {
            //console.log("country temp :",countrTemp);
            console.log("Error");
        } 
        else{
            setModifDepartment(true);
            setCreateDepartment(false);
        
          
            
            setDepartm({...departm,["name"]: departmTemp.name,["country"]:departmTemp.country.country_id,["id"]:departmTemp.id});
           console.log("depart: ",departm);
        }
       
     };
     //guarda departamento en base de datos  
     const crearDepartment = () => {
        if(departm.name === "" || departm === "")
        {
            console.log("Error");
        }
        else{
            
            setDepartm({...departm,["country"]: departmTemp.country.country_id});
            UserService.postDepartment((departm.name),(departm.country)).then((response)=>{if(response.data){console.log(response.data);UserService.getDepartment().then(aux =>setDepartments(aux));} else{console.log("error postDepartment");}});
            setCreateDepartment(false);
            console.log("depart:", departm);
        }
        
     };
     const modificarDepartment = () =>{
        if(departm.name === "" || departm.id === "" ||  departm === "")
        {
            console.log("Error");
        }
        else{
            
           
            //UserService.putDepartment((departm.name),(departm.id_country),(departm.id)).then((response)=>{if(response.data){console.log(response.data);UserService.getDepartment().then(aux =>setDepartments(aux));} else{console.log("error putDepartment");}});
            setCreateDepartment(false);
           
        }
    };
    const onChangeSelectDepart = (e) =>{
            
            
              
        setDepartmTemp( departments.find( obj => {return obj.id === parseInt(e.value)}));
         console.log("depars:",departments);
         console.log("deparTemp:",departmTemp);
        
      };
      const onChangeSelectCountDep = (e) =>{
            
            
        setDepartm({...departm,["country"]: e.value});   
        //setDepartmTemp({...departm,["country"]: departments.find( obj => {return obj.id_country === parseInt(e.value)}).id_country} );
         
        
      };
     //------------------------------------------------------------------------------------------------------
     
     //--------------------------FUNCIONES PARA CREAR O MODIFICAR CITY-----------------------------------------------------------
     const [createCity,setCreateCity]= useState(false);
     const [modifCity,setModifCity] = useState(false);
     //modif
     const createCityClick = () => { 
        setCreateCity(true); 
        setModifCity(false); 
        setCit({...departm,["id"]:0,["name"]:"",["id_department"]: 0 }); 
    };
     const cancelCreateCityClick = () => { setCreateCity(false); setModifCity(false);   };
     const modifCityClick = (e) =>{
         //console.log("modifCi",createCity,modifCity);
         setModifCity(true);
         setCreateCity(false);
         if(citTemp.name === "" || citTemp === "")
         {
             //console.log("country temp :",countrTemp);
             console.log("Error");
         } 
         else{
            setModifCity(true);
            setCreateCity(false);
         
           
             
             setCit({...cit,["city_name"]: citTemp.city_name,["id_department"]:citTemp.department.department_id,["id"]:citTemp.id});
            console.log("city: ",cit);
         }
     }

       //guarda ciudad en base de datos  
       const crearCity = () => {
        if(cit.city_name === "" || cit === "")
        {
            console.log("Error");
        }
        else{
            
            setCit({...cit,["id_department"]: citTemp.department.department_id});
            UserService.postCity((cit.city_name),(cit.id_department)).then((response)=>{if(response.data){console.log(response.data);UserService.getCitys().then(aux =>setCities(aux));} else{console.log("error postCity");}});
            setCreateCity(false);
            //console.log("depart:", departm);
        }
        
     };
     const modificarCity = () =>{
        if(cit.city_name === "" || cit.id_department === "" ||  cit === "")
        {
            console.log("Error");
        }
        else{
            
           
            UserService.putCity((cit.city_name),(cit.id_department),(cit.id)).then((response)=>{if(response.data){console.log(response.data);UserService.getCitys().then(aux =>setCities(aux));} else{console.log("error putCity");}});
            setModifCity(false);
           
        }
    };
    const onChangeSelectCity = (e) =>{
            
            
              
        setCitTemp( cities.find( obj => {return obj.id === parseInt(e.value)}));
         ///console.log("depars:",departments);
         //console.log("deparTemp:",departmTemp);
        
      };
      const onChangeSelectDepCit = (e) =>{
            
            
        setCit({...cit,["id_department"]: e.value});   
        //setDepartmTemp({...departm,["country"]: departments.find( obj => {return obj.id_country === parseInt(e.value)}).id_country} );
         
        
      };


    //--------------------------FUNCIONES PARA CREAR O MODIFICAR Sucursal-----------------------------------------------------------
    const [createSucursal,setCreateSucursal]= useState(false);
    const [modifSucursal,setModifSucursal] = useState(false);
    //modif
    const createSucursalClick = () => { 
        setCreateSucursal(true); 
        setModifSucursal(false); 
        setSucurs({...sucurs,["id"]:0,["id_ent"]:"",["id_city"]: 0,["address"]: "" });  
    };
    const cancelCreateSucursalClick = () => { setCreateSucursal(false); setModifSucursal(false);   };
    const modifSucursalClick = (e) =>{
        
        setModifSucursal(true);
        setCreateSucursal(false);
        if(sucursTemp.address === "" || sucursTemp === "")
        {
            //console.log("country temp :",countrTemp);
            console.log("Error");
        } 
        else{
            setModifSucursal(true);
            setCreateSucursal(false);
        
          
            
            setSucurs({...sucurs,["address"]: sucursTemp.address,["id_city"]:sucursTemp.city.city_id,["id_ent"]:sucursTemp.enterprise.enterprise_id,["id"]: sucursTemp.id});
           //console.log("city: ",cit);
        }
    }

      //guarda ciudad en base de datos  
      const crearSucursal = () => {
       /*
       if(ent.EnterName === "" || ent === "")
       {
           console.log("Error");
       }
       else{
           
          
          // UserService.postEmpresa((ent.EnterName),(ent.EnterAdress)).then((response)=>{if(response.data){console.log(response.data);} else{console.log("error postEmpresa");}});
          // UserService.getEnterprise().then(empresas =>setEmpresas(empresas));
          
       }
       */
       
    };
    const modificarSucursal = () =>{
         /*  
       if(cit.city_name === "" || cit.city_state === "" || cit.country === "")
       {
           console.log("Error");
       }
       else{
           
          
         //  UserService.putCity((cit.city_name),(cit.city_state),(cit.country),(cit.id)).then((response)=>{if(response.data){console.log(response.data);} else{console.log("error putCity");}});
         //  UserService.getCitys().then(citys =>{ if(citys.data){setEmpresas(citys)} else {console.log("Error get cit",citys.data)} });
           
         //  console.log("Empresas",cities);
         //  console.log("Ent",cit);
       }
       */

   };

   const onChangeSelectSucur = (e) =>{
            
            
              
    setSucursTemp( sucursals.find( obj => {return obj.id === parseInt(e.value)}));
     //console.log("c:",citTemp);
     //console.log("deparTemp:",departmTemp);
    
  };
  const onChangeSelectEntSuc = (e) =>{
        
        
    setSucurs({...sucurs,["id_ent"]: e.value});   
    //setDepartmTemp({...departm,["country"]: departments.find( obj => {return obj.id_country === parseInt(e.value)}).id_country} );
     
    
  };
  const onChangeSelectCitSuc = (e) =>{
        
        
    setSucurs({...sucurs,["id_city"]: e.value});   
    //setDepartmTemp({...departm,["country"]: departments.find( obj => {return obj.id_country === parseInt(e.value)}).id_country} );
     
    
  };





     //------------------------------------------------------------
     //estado de carga de datos de la base de datos
     const [isLoading, setIsLoading] = useState(false);

     //capturador de mensaje en caso de error al obtener datos de la base de datos
     const [errorMessage, setErrorMessage] = useState("");
    //------------------------------------------------
     
        
    useEffect(()=>{ 
        const getData = () => {
            setIsLoading(true);
            UserService.getEnterprise().then(enter => {setEnterprises(enter);}).catch( () => {setErrorMessage("No se puede obtener los datos del servidor");setIsLoading(false);} );
            UserService.getCountry().then(count =>setCountries(count));
            UserService.getDepartment().then(aux =>setDepartments(aux));
            UserService.getCitys().then(aux =>setCities(aux));
            UserService.getSucursal().then(aux =>setSucursals(aux));
            setIsLoading(false);
            //setEnterprises(UserService.getEnterprise());
            /*quirar esto*/
            /*setIsLoading(true);
            UserService.getContact().then(contacto => {setContactos(contacto);
                UserService.getEnterprise().then(empresa =>{setEmpresas(empresa); 
                    UserService.getCitys().then(c => {setCities(c);
                        setIsLoading(false);
                    }).catch( () => { setErrorMessage("No se puede obtener los datos del servidor"); setIsLoading(false); }  );
                }).catch( () => { setErrorMessage("No se puede obtener los datos del servidor"); setIsLoading(false); }  );
            }).catch( () => { setErrorMessage("No se puede obtener los datos del servidor"); setIsLoading(false); }  );
        
            */
       
            
    };
    getData();    
    },[]);  
           
    console.log("sucurs:",sucursals);
    console.log("errors:",errorMessage);
        



    return (
        <div  className='BodyContainer container-fluid vh-100'>

           
            <div className='row'>

                <div className='p-5 row ' >
                                
                    <div className='col-md-6 col r'  >
                          {/*  Crear/modificar empresa   */ }          
                        <div className='border rounded border-5 p-2 m-1'>
                            
                            { (!createEnterprise && !modifEntterprise) && (
                                <div className='m-2 input-group'>
                                    <span className=' input-group-text col-md-3'>Empresa</span>
                                            

                                    <Select
                                        id='select_ent'
                                        className = 'col-md-7'
                                        placeholder="Selecciones una opcion" 
                                        onChange= {(e) => onChangeSelectEnt(e)}            
                                        //defaultValue = { modifEntterprise && ((option) => ({label: ent.name,value: ent.id})) }
                                        options = {(enterprises.length !== 0)?  enterprises.map((option) => ({ label : option.name, value: option.id })) : <></>}
                                        isSearchable = {true}
                                    />
                                    <button className='btn col-md-1 btn-outline-secondary ' type='button'  onClick={createEntClick}><BsFillPlusCircleFill fontSize={"1.5em"}/></button>
                                        
                                    <button className='btn col-md-1 btn-outline-secondary' type='button'  onClick={modifEntClick}>
                                        <BiEdit fontSize={"1.5em"}/>
                                    </button>
                                            
                                </div>
                            )

                            }
                            
                            { (createEnterprise || modifEntterprise) && (
                                <div>
                                    <div className=' m-2' >   
                                                    
                                        <div className='m-2'>
                                            <label className='col-md-3'>NOMBRE</label>
                                            <input 
                                                type="text"         
                                                name='name' 
                                                placeholder="Nombre de la Empresa"
                                                className='col-md-9 '
                                                value={ent.name}
                                                onChange={onChangeEnt} 
                                            />
                                        </div>    
                                        <div className='m-2'>
                                            <label className='col-md-3'>NIT</label>
                                            <input 
                                                type="text"         
                                                name='nit' 
                                                placeholder="Nombre de la Empresa"
                                                className='col-md-9 '
                                                value={ent.nit}
                                                onChange={onChangeEnt} 
                                            />
                                        </div>   
                                        {(createEnterprise) && (<button onClick={crearEmpresa} className = "rounded m-2">CREAR</button>) }
                                        {(modifEntterprise) && (<button onClick={modificarEnterp} className = "rounded m-2">MODIFICAR</button>)}
                                        <button onClick={cancelCreateEntClick} className = "rounded m-2">CANCELAR</button>
                                        
                                    </div>
                                </div>

                                )

                            }
                                       
                        </div>  
                         {/*  Crear/modificar pais   */ }        
                        <div className='border rounded border-5 p-2 m-1'>
                            {   
                                (!createCountry && !modifCountry) && (
                                    <div className='m-2 input-group'>
                                        <span className=' input-group-text col-md-3'>PAIS</span>
                                        <Select
                                            className = 'col-md-7'
                                            placeholder="Selecciones una opcion" 
                                            //defaultValue= { editEnterprise && ( (option) => ({ label: cities.find( obj => { return obj.id === entEdit.id_city }  ).city_name
                                            //                                    + " - " + cities.find( obj => { return obj.id === entEdit.id_city }  ).country
                                            //                                    ,value: enterprise.idCit}) ) }
                                            onChange = { (e) =>onChangeSelectCount(e)}
                                            options = {(countries.length !== 0)?  countries.map((option) => ({ label : option.name, value: option.id })) : <></>}
                                            isSearchable = {true}
                                        /> 
                                        <button className='btn col-md-1 btn-outline-secondary ' type='button'  onClick={createCountryClick}><BsFillPlusCircleFill fontSize={"1.5em"}/></button>
                                        <button className='btn col-md-1 btn-outline-secondary' type='button'  onClick={modifCountryClick}>
                                            <BiEdit fontSize={"1.5em"}/>
                                        </button> 
                                    </div>
                                )
                            }
                            
                            {
                                (createCountry || modifCountry) && (
                                        
                                    <div className='m-2'>
                                        <label htmlFor="" className='col-md-3'>Nombre</label>
                                        <input 
                                            name="name" 
                                            type="text"
                                            //placeholder="Nombre de la Persona de Contacto"
                                            className='col-md-9 '
                                            value={countr.name}
                                            onChange={onChangCountr}                            
                                        />
                                        {(createCountry) && (<button onClick={crearCountry} className = "rounded m-2">CREAR</button>)}
                                        {(modifCountry) && (<button onClick={modificarCountry} className = "rounded m-2">MODIFICAR</button>)}
                                        <button onClick={cancelCreateCountryClick} className = "rounded m-2">CANCELAR</button>
                                    </div>
                                              
                                )
                            }
                               
                        </div>  
                         {/*  Crear/modificar departamento   */ }          
                        <div className='border rounded border-5 p-2 m-1'>
                            {
                                (!createDepartment && !modifDepartment) && (
                                <div className='m-2 input-group'>
                                    <span className=' input-group-text col-md-3'>DEPARTAMENTO</span>
                                    <Select
                                        className = 'col-md-7'
                                        placeholder="Selecciones una opcion" 
                                        //defaultValue= { editEnterprise && ( (option) => ({ label: cities.find( obj => { return obj.id === entEdit.id_city }  ).city_name
                                        //                                    + " - " + cities.find( obj => { return obj.id === entEdit.id_city }  ).country
                                        //                                    ,value: enterprise.idCit}) ) }
                                        onChange = { (e) =>onChangeSelectDepart(e)}
                                        options = {(departments.length !== 0)?  departments.map((option) => ({ label : option.name , value: option.id })) : <></>}
                                        isSearchable = {true}
                                    /> 
                                    <button className='btn col-md-1 btn-outline-secondary ' type='button'  onClick={createDepartmentClick}><BsFillPlusCircleFill fontSize={"1.5em"}/></button>
                                    <button className='btn col-md-1 btn-outline-secondary' type='button'  onClick={modifDepartmentClick}>
                                        <BiEdit fontSize={"1.5em"}/>
                                    </button> 
                                </div>
                                )
                            }
                            {
                                (createDepartment || modifDepartment) && (
                                <div>
                                    <div className='m-2 input-group'>
                                        <span className=' input-group-text col-md-3'>PAIS</span>
                                        <Select
                                            className = 'col-md-9'
                                            placeholder="Selecciones una opcion" 
                                            defaultValue= { modifDepartment && ( (option) =>({ label : departmTemp.country.country_name , value: departmTemp.country.country_id}) ) }
                                            onChange = { (e) =>onChangeSelectCountDep(e)}
                                            options = {(countries.length !== 0)?  countries.map((option) => ({ label : option.name, value: option.id })) : <></>}
                                            isSearchable = {true}
                                        /> 
                                        
                                    </div>



                                    <div className='m-2'>
                                        <label htmlFor="" className='col-md-3'>Nombre</label>
                                        <input 
                                            name="name" 
                                            type="text"
                                            //placeholder="Nombre de la Persona de Contacto"
                                            className='col-md-9 '
                                            value={departm.name}
                                            onChange={onChangDepartm}                            
                                        />
                                        { (createDepartment) && (<button onClick={crearDepartment} className = "rounded m-2">CREAR</button>)}
                                        {(modifDepartment) && (<button onClick={modificarDepartment} className = "rounded m-2">MODIFICAR</button>)}
                                        <button onClick={cancelCreateDepartmentClick} className = "rounded m-2">CANCEL</button>
                                    </div>

                                    
                                </div>
                                
                                       
                                )
                            }
                                 
                        </div>      
                    </div>                
                    <div className='col-md-6 col '  >
                    
                        {/* Crear/modificar ciudad*/}       
                        <div className='border rounded border-5 p-2 m-1'>
                        {
                            (!createCity && !modifCity) && (
                            <div className='m-2 input-group'>
                                <span className=' input-group-text col-md-3'>Ciudad</span>
                                <Select
                                    className = 'col-md-7'
                                    placeholder="Selecciones una opcion" 
                                    //defaultValue= { editEnterprise && ( (option) => ({ label: cities.find( obj => { return obj.id === entEdit.id_city }  ).city_name
                                    //                                    + " - " + cities.find( obj => { return obj.id === entEdit.id_city }  ).country
                                    //                                    ,value: enterprise.idCit}) ) }
                                    onChange = { (e) =>onChangeSelectCity(e)}
                                    options = {(cities.length !== 0)?  cities.map((option) => ({ label : option.city_name  , value: option.id })) : <></>}
                                    isSearchable = {true}
                                />
                                <button className='btn col-md-1 btn-outline-secondary ' type='button'  onClick={createCityClick}><BsFillPlusCircleFill fontSize={"1.5em"}/></button>     
                                <button className='btn col-md-1 btn-outline-secondary' type='button'  onClick={modifCityClick}>
                                        <BiEdit fontSize={"1.5em"}/>
                                </button> 
                            </div>
                            )
                        }
                        {
                            (createCity || modifCity) && (
                            <div>
                                <div className='m-2 input-group'>
                                    <span className=' input-group-text col-md-3'>DEPARTAMENTO</span>
                                    <Select
                                        className = 'col-md-9'
                                        placeholder="Selecciones una opcion" 
                                        defaultValue= { modifCity && ( (option) =>({ label : citTemp.department.department_name , value: citTemp.department.department_id}) ) }
                                            onChange = { (e) =>onChangeSelectDepCit(e)}
                                            options = {(departments.length !== 0)?  departments.map((option) => ({ label : option.name, value: option.id })) : <></>}
                                        isSearchable = {true}
                                    /> 
                                        
                                </div>
                                <div className=' m-2'> 
                                    <div className='m-2'>
                                        <label htmlFor="" className='col-md-3'>NOMBRE</label>
                                        <input 
                                            name="city_name" 
                                            type="text"
                                            //placeholder="Nombre de la Persona de Contacto"
                                            className='col-md-9 '
                                            value={cit.city_name}
                                            onChange={onChangeCit}
                                                                    
                                        />
                                    </div>
                                
                                    { (createCity) && (<button onClick={crearCity} className = "rounded m-2">CREAR</button>)}
                                    {(modifCity) && (<button onClick={modificarCity} className = "rounded m-2">MODIFICAR</button>     )}  
                                    <button onClick={cancelCreateCityClick} className = "rounded m-2">CANCELAR</button>                        
                                </  div>
                            </div>
                            
                            )
                        }
                        
                        </div>   
                        {/* Crear/modificar Sucursal*/}       
                        <div className='border rounded border-5 p-2 m-1'>
                        {
                            (!createSucursal && !modifSucursal) && (
                            <div className='m-2 input-group'>
                                <span className=' input-group-text col-md-3'>Sucursal</span>
                                <Select
                                    className = 'col-md-7'
                                    placeholder="Selecciones una opcion" 
                                    //defaultValue= { editEnterprise && ( (option) => ({ label: cities.find( obj => { return obj.id === entEdit.id_city }  ).city_name
                                    //                                    + " - " + cities.find( obj => { return obj.id === entEdit.id_city }  ).country
                                    //                                    ,value: enterprise.idCit}) ) }
                                    onChange = { (e) =>onChangeSelectSucur(e)}
                                    options = {(sucursals.length !== 0 && !isLoading)?  sucursals.map((option) => ({ label : option.enterprise.enterprise_name + " - " + option.city.city_name , value: option.id })) : <></>}
                                    isSearchable = {true}
                                    
                                />
                                <button className='btn col-md-1 btn-outline-secondary ' type='button'  onClick={createSucursalClick}><BsFillPlusCircleFill fontSize={"1.5em"}/></button>     
                                <button className='btn col-md-1 btn-outline-secondary' type='button'  onClick={modifSucursalClick}>
                                        <BiEdit fontSize={"1.5em"}/>
                                </button> 
                            </div>
                            )
                        }
                        {
                            (createSucursal || modifSucursal) && (
                                <div>
                                    <div className='m-2 input-group'>
                                        <span className=' input-group-text col-md-3'>EMPRESA</span>
                                        <Select
                                            className = 'col-md-9'
                                            placeholder="Selecciones una opcion" 
                                                //defaultValue= { editEnterprise && ( (option) => ({ label: cities.find( obj => { return obj.id === entEdit.id_city }  ).city_name
                                                //                                    + " - " + cities.find( obj => { return obj.id === entEdit.id_city }  ).country
                                                //                                    ,value: enterprise.idCit}) ) }
                                                //onChange = { (e) =>onChangeSelectCity(e)}
                                                options = {(enterprises.length !== 0)?  enterprises.map((option) => ({ label : option.name, value: option.id })) : <></>}
                                        
                                                isSearchable = {true}
                                        /> 
                                            
                                    </div>
                                    <div className='m-2 input-group'>
                                        <span className=' input-group-text col-md-3'>CIUDAD</span>
                                        <Select
                                            className = 'col-md-9'
                                            placeholder="Selecciones una opcion" 
                                                //defaultValue= { editEnterprise && ( (option) => ({ label: cities.find( obj => { return obj.id === entEdit.id_city }  ).city_name
                                                //                                    + " - " + cities.find( obj => { return obj.id === entEdit.id_city }  ).country
                                                //                                    ,value: enterprise.idCit}) ) }
                                                //onChange = { (e) =>onChangeSelectCity(e)}
                                                options = {(cities.length !== 0)?  cities.map((option) => ({ label : option.city_name  , value: option.id })) : <></>}
                                    
                                                isSearchable = {true}
                                        /> 
                                            
                                    </div>
                                    
                                    <div className=' m-2'> 
                                        <div className='m-2'>
                                            <label htmlFor="" className='col-md-3'>DIRECCION</label>
                                            <input 
                                                name="address" 
                                                type="text"
                                                //placeholder="Nombre de la Persona de Contacto"
                                                className='col-md-9 '
                                                value={sucurs.address}
                                                onChange={onChangeSucurs}
                                                                        
                                            />
                                        </div>
                                        
                                        { (createSucursal) && (<button onClick={crearSucursal} className = "rounded m-2">CREAR</button>)}
                                        {(modifSucursal) && (<button onClick={modificarSucursal} className = "rounded m-2">MODIFICAR</button>     )}  
                                        <button onClick={cancelCreateSucursalClick} className = "rounded m-2">CANCELAR</button>                        
                                    </div>
                                </div>

                            
                            )
                        }
                        
                        </div>    
                                
                    </div>

                </div>
                


            </div>
        </div> 
    
    );
};
export default BodyContainerOtros;