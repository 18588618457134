import React,{useEffect,useState} from "react";


import {
    Document,
    Page,
    View,
    Image,
    Text,
    StyleSheet,
    usePDF,
    Svg,
    Line
} from '@react-pdf/renderer';
import Select from 'react-select';
import logoImage from '../../assets/LOGO-BBA-TRANSPARENTE1.png';
import { Alignment } from "react-data-table-component";
//------------------------------------------------

const PDFViewer = ({
    title,
    style,
    className,
    fileName,
    children,
    innerRef,
    ...props
  }) => {
    const [instance, updateInstance] = usePDF({ document: children });
    const [url, set] = useState();
  
    useEffect(() => {
      if (instance.blob) {
        set(
          URL.createObjectURL(
            new File([instance.blob], fileName, {
              type: instance.blob.type
            })
          )
        );
      }
    }, [instance]);
  
    useEffect(updateInstance, [children]);
  
    return (
      <>
        <iframe
          title={title}
          ref={innerRef}
          width="100%"
          height="100%"
          src={url}
          className={className}
          {...props}
        />
      </>
    );
  };



//------------------------------------------

const styles = StyleSheet.create(
    {
        tabla: {
            display : "table",
            borderStyle : "solid",
            borderColor : "#000",
            borderWidth : 1,
            borderRightWidth: 0,
            borderBottomWidth: 0,

        },
        tablaFila : {
            margin : "auto",
            flexDirection: "row",
            height: "12px"
            
        },
        tablaColumna0: {
            width: "100%",
            borderStyle : "solid",
            borderColor : "#000",
            borderWidth : 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
        },
        tablaColumna1 : {
            width: "10%",
            borderStyle : "solid",
            borderColor : "#000",
            borderWidth : 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
                },
        tablaColumna2 : {
            width: "40%",
            borderStyle : "solid",
            borderColor : "#000",
            borderWidth : 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
        },
        tablaTexto: {
            margin: "auto",
            
            fontSize: 9,
        },  
        tablaTextoAzul: {
            margin: "auto",
            color: "navy",
            fontSize: 9,
        },
        tablaColumna3 : {
            width: "15%",
            borderStyle : "solid",
            borderColor : "#000",
            borderWidth : 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
        },
        tablaColumna4 : {
            width: "75%",
            borderStyle : "solid",
            borderColor : "#000",
            borderWidth : 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
        },
        texto: {
            margin: "auto",
           
            fontSize: 9,
            
            
        },
        textoAzul: {
            margin: "auto",
            textAlign:"left",
            fontSize: 9,
            color: "navy",
            alignItems:"flex-start",
        },
       
        borde: {
            borderBottomStyle:"solid",
            borderColor:"#000",
            borderWidth:1
        },
        textoNegritas: {
            fontSize: 9,
            margin : "auto",
            textAlign:"left",
            alignItems: "flex-start",
            fontWeight: "bold",
        },
        fila1 : {
            display: "flex",
            flexDirection: "row",
            height : "12px",
            
        },
        columna1 : {
            width: "20%",
            margin: 0,
        },
        columna2 : {
            width: "80%",
            margin: 0,
        },
        tablaColumna5 : {
            width: "13%",
            borderStyle : "solid",
            borderColor : "#000",
            borderWidth : 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
        },
        tablaColumna6 : {
            width: "61%",
            borderStyle : "solid",
            borderColor : "#000",
            borderWidth : 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
        },
        footerPage :{
            position: 'absolute',
            
            bottom: 20,
            left: 0,
            right: 0,
          
            
        }
    }
);



const ServicioPDF = ({quote,servs,servsStatus,cond,condSel,vtotal})  => {

    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user : null;
    function floatToString (num = 0){
        

        var numFormat = num.toLocaleString("es-BO") ;
        return numFormat ? numFormat : " "
    }
    var total = 0;
    
    console.log("servic",servs)
    function floatToString (num = 0){
        

        var numFormat = num.toLocaleString("es-BO") ;
        return numFormat ? numFormat : " "
    }

    function formatDate(date){
        const day = date.substring(8,10);
        const month = date.substring(5,7);
        const year = date.substring(0,4);
        return day+"-"+month+"-"+year;
    }
    
    
    console.log("Quote:",quote);
    const datos = quote;
   
    const pdfName = "Coti.pdf" 

    
    return (

        <>
        <PDFViewer fileName={pdfName}>
        <Document  >
                <Page size = "LETTER" wrap={false} >
                    <View style={{padding : "60px",paddingTop: "20px" }} >
                        <View>
                            <Image  
                                style={{ width : "30%"}}  
                                source={logoImage}
                            />
                        </View>

                        <View style={{display: "flex",flexDirection: "row"}} >
                            <View style={{width : "66%"}}>

                            </View>
                            
                          
                            
                        </View>
                        <View style={{marginTop: "3px"}}> 

                        </View>

                        <View style={styles.tabla}>
                            <View style={styles.tablaFila}>
                                <View style={styles.tablaColumna0}>
                                    <Text style={{fontSize: "8",fontWeight: "bold",margin: "auto"}}>COTIZACIÓN DE SERVICIOS DE TRANSBORDO Y DESCARGA</Text>
                                </View>
                            </View>
                            <View style={styles.tablaFila}>  
                                <View style={ styles.tablaColumna1}>
                                    <Text style={styles.tablaTexto}>EMPRESA :</Text>
                                </View>
                                <View style={ styles.tablaColumna2}>
                                    <Text style={styles.tablaTextoAzul}>{ datos.quote_parent_status ? datos.enterprise_contact.enterprise.enterprise_name :  datos.enterprise_name_wq.toUpperCase()  }</Text>
                                </View>
                                <View style={ styles.tablaColumna1}>
                                    <Text style={styles.tablaTexto}>ATN :</Text>
                                </View>
                                <View style={ styles.tablaColumna2}>
                                    <Text style={styles.tablaTextoAzul}>{ datos.quote_parent_status ? (datos.enterprise_contact.name +" " +datos.enterprise_contact.last_name) :datos.enterprise_contact_wq.toUpperCase()    }</Text>
                                </View>
                            </View>
                            <View style={styles.tablaFila}>  
                                <View style={ styles.tablaColumna1}>
                                    <Text style={styles.tablaTexto}>CIUDAD :</Text>
                                </View>
                                <View style={ styles.tablaColumna2}>
                                    <Text style={styles.tablaTextoAzul}>{  datos.quote_parent_status ? (datos.enterprise_contact.enterprise.enterprise_city.toUpperCase()) : " "  }</Text>
                                </View>
                                <View style={ styles.tablaColumna1}>
                                    <Text style={styles.tablaTexto}>TELF :</Text>
                                </View>
                                <View style={ styles.tablaColumna3}>
                                    <Text style={styles.tablaTextoAzul}>{ datos.quote_parent_status ?    (datos.enterprise_contact.phone) : " "    }</Text>
                                </View>
                                <View style={ styles.tablaColumna1}>
                                    <Text style={styles.tablaTexto}>FECHA :</Text>
                                </View>
                                <View style={ styles.tablaColumna3}>
                                    <Text style={styles.tablaTextoAzul}>{formatDate(datos.service_date)}</Text>
                                </View>
                            </View>
                            <View style={styles.tablaFila}>  
                                <View style={ styles.tablaColumna4}>
                                    <Text style={styles.tablaTexto}>SANTA CRUZ - BOLIVIA</Text>
                                </View>
                                
                                <View style={ styles.tablaColumna1}>
                                    <Text style={styles.tablaTexto}>N° :</Text>
                                </View>
                                <View style={ styles.tablaColumna3}>
                                    <Text style={styles.tablaTextoAzul}>{datos.service_number}</Text>
                                </View>
                            </View>
                        </View>

                        <View style={[{height:"60px"}]}>  
                            <Text style={[styles.texto,{marginLeft: "0"}]}>
                            Distinguidos Señores:     
                            </Text>
                            <Text style={[styles.texto,{textIndent:25,marginLeft:0}]}>
                            Mediante la presente, exponemos nuestra cotización de transbordo / transporte / descarga:
                            </Text>

                        </View>
                        <View  style={[{height:"170px"}]}>
                            <View style={[styles.fila1]}>
                                <Text style={[styles.textoNegritas,styles.columna1]}>
                                   Importador : 
                                </Text>
                                <Text style={[styles.textoAzul,styles.columna2]}>
                                    { datos.quote_parent_status ?   (datos.enterprise_contact.enterprise.enterprise_name.toUpperCase()) :  datos.enterprise_name_wq.toUpperCase() }
                                </Text>
                            </View>
                            <View style={[styles.fila1]}>
                                <Text style={[styles.textoNegritas,styles.columna1]}>
                                   Proveedor : 
                                </Text>
                                <Text style={[styles.textoAzul,styles.columna2]}>
                                    {   datos.quote_parent_status ?    (datos.contact_supplier ? datos.contact_supplier.supplier_name.toUpperCase() : 'SELECCIONAR PROVEEDOR') : datos.exporter_wq.toUpperCase()  }
                                </Text>
                            </View>
                            
                            <View style={[styles.fila1]}>
                                <Text style={[styles.textoNegritas,styles.columna1]}>
                                   Peso : 
                                </Text>
                                <Text style={[styles.textoAzul,styles.columna2]}>
                                    { datos.quote_parent_status ?  (floatToString(datos.weight)) : floatToString(datos.weight_wq)    + " Kgs."}
                                </Text>
                            </View>
                            <View style={[styles.fila1]}>
                                <Text style={[styles.textoNegritas,styles.columna1]}>
                                   Volumen : 
                                </Text>
                                <Text style={[styles.textoAzul,styles.columna2]}>
                                {   datos.quote_parent_status ?  (floatToString(datos.volume)) : floatToString (datos.volume_wq) + " M3"}
                                </Text>
                            </View>

                            {
                                ( datos.forklift_status) && (

                                    <View style={[styles.fila1]}>
                                        <Text style={[styles.textoNegritas,styles.columna1]}>
                                        Montacarga : 
                                        </Text>
                                        <Text style={[styles.textoAzul,styles.columna2]}>
                                        { datos.forklift}
                                        </Text>
                                    </View>

                                )
                            }
                            {

                                (datos.crane_status) && (

                                    <View style={[styles.fila1]}>
                                        <Text style={[styles.textoNegritas,styles.columna1]}>
                                        Grúa : 
                                        </Text>
                                        <Text style={[styles.textoAzul,styles.columna2]}>
                                        {   datos.crane }
                                        </Text>
                                    </View>

                                )

                            }
                            {
                                (datos.stevedores_status) && (

                                    <View style={[styles.fila1]}>
                                        <Text style={[styles.textoNegritas,styles.columna1]}>
                                        Estibadores : 
                                        </Text>
                                        <Text style={[styles.textoAzul,styles.columna2]}>
                                        { datos.stevedores }
                                        </Text>
                                    </View>

                                )
                            }
                            {
                                ( datos.pallet_truck_status) && (

                                    <View style={[styles.fila1]}>
                                        <Text style={[styles.textoNegritas,styles.columna1]}>
                                        Carro Hidraulico : 
                                        </Text>
                                        <Text style={[styles.textoAzul,styles.columna2]}>
                                        {  datos.pallet_truck }
                                        </Text>
                                    </View>

                                )
                            }
                            {
                                (datos.service1_status) && (
                                    <View style={[styles.fila1]}>
                                        <Text style={[styles.textoNegritas,styles.columna1]}>
                                        {datos.service1_descrip + " :"}
                                        </Text>
                                        <Text style={[styles.textoAzul,styles.columna2]}>
                                        {  datos.service1_amount }
                                        </Text>
                                    </View>
                                )
                            }
                            
                            {
                                (datos.service2_status) && (
                                    <View style={[styles.fila1]}>
                                        <Text style={[styles.textoNegritas,styles.columna1]}>
                                        {datos.service2_descrip + " :"}
                                        </Text>
                                        <Text style={[styles.textoAzul,styles.columna2]}>
                                        {  datos.service2_amount }
                                        </Text>
                                    </View>
                                )
                            }
                            <View style={[styles.fila1]}>
                                <Text style={[styles.textoNegritas,styles.columna1]}>
                                    Entregado en :
                                </Text>
                                <Text style={[styles.textoAzul,styles.columna2]}>
                                {datos.delivery_address}
                                </Text>
                            </View>

                            <View style={[styles.fila1]}>
                                <Text style={[styles.textoNegritas,styles.columna1]}>
                                    Observaciones :
                                </Text>
                                <Text style={[styles.textoAzul,styles.columna2]}>
                                {datos.observations}
                                </Text>
                            </View>
                            
                        </View>
                        <View style={[{height: "40px"}]}>
                            <Text style={[styles.texto,{color : "red",fontSize:8}]}>
                            {"TRANSPORTE LOCAL CON LA MAQUINARIA ADECUADA PARA SU EJECUCIÓN."}
                            </Text>
                        </View>

                        <View style={[{}]}>
                            <View style={[styles.tabla]}>
                               
                                        
                                        <View style={[styles.tablaFila,]}>
                                            {total = total + datos.amount1}
                                            <View style={[styles.tablaColumna6]}>
                                                <Text style={[styles.texto,{ marginLeft: '1%' }]}>
                                                COSTO DEL SERVICIO
                                                </Text>
                                            </View>
                                            <View style={[styles.tablaColumna5]}>
                                                <Text style={[styles.texto]}>
                                                $us
                                                </Text>
                                            </View>
                                            <View style={[styles.tablaColumna5]}>
                                                <Text style={[styles.textoAzul]}>
                                                {floatToString(datos.quotation_pricing)}
                                                </Text>
                                            </View>
                                            <View style={[styles.tablaColumna5]}>
                                                <Text style={[styles.texto]}>
                                                TOTAL
                                                </Text>
                                            </View>
                                            
                                        </View>
                            
                                

                              
                                
                            </View>
                          

                        </View>

                        <View  style={[]} >
                            <View style={[{height: "10px"},styles.fila1]}>  

                            </View>
                            <View style={[styles.fila1]}>
                                <Text style={[styles.textoNegritas,{fontSize: 8, width: "17%", margin: 0,}]}>
                                   Condición de Pago : 
                                </Text>
                                <Text style={[styles.texto,{fontSize: 7.3,margin: 0}]}>
                                    Al terminar el servicio.
                                </Text>
                            </View >
                            <View style={[styles.borde,{minHeight:"70px"}]} >
                                <Text style={[{width:"100%",padding:"1px"},styles.texto]}>
                                    Incluye los siguientes servicios:
                                </Text>
                                
                                <Text style={[styles.texto, {width:"100%",padding:"1px"}]}>
                                    {" * Factura de BBA con crédito fiscal."}  
                                </Text>
                                <Text style={[styles.texto, {width:"100%",padding:"1px"}]}>
                                    {" * Supervisión del proceso."}  
                                </Text>
                                <Text style={[styles.texto, {width:"100%",padding:"1px"}]}>
                                    {" * Informe mediante fotografías."}  
                                </Text>
                                
                               
                            </View>
                           

                                    
                           
                            <View>
                                
                                <Text style={[styles.textoNegritas,{padding:"2px",fontSize:8}]}>
                                EN CASO DE ACEPTACIÓN, FAVOR ENVIAR AUTORIZACIÓN DE POR ESCRITO.
                               
                                
                                </Text>
                            </View>
                        </View>
                        <View style={[{height:"10px"}]}>

                        </View>
                        <View style={[]}>
                            <Text style={[{fontSize:8,color : "red",textAlign: "center"}]}>
                            NOTA: SI ALGÚN DATO DE LA MERCADERÍA CAMBIA, ESTA COTIZACIÓN TAMBIÉN CAMBIA.
                            </Text>
                            <Text style={[{fontSize:10,color : "red",textAlign: "center",textDecoration:"underline",}]}>
                            LAS FACTURAS DE TRANSPORTE SON COMPUTARIZADAS Y SERÁN ENVIADAS VÍA CORREO.
                            </Text>
                        </View>
                        <View style={[{height:"40px",alignItems:"center"}]}>
                            <View style={[{height:"10px"}]}>

                            </View>
                            <Text style={[{fontSize:9}]}>
                            Sin otro particular, lo saludamos cordialmente
                            </Text>
                        </View>
                        <View>
                            <Text style={[{fontSize:9,textAlign:"right"}]}>
                                {
                                    user.name.toUpperCase() + " " + user.last_name.toUpperCase()
                                }
                        
                            </Text>
                            <Text style={[{fontSize:9,textAlign:"right"}]}>
                              
                                {
                                    user.area.toUpperCase()
                                }
                            </Text>
                        </View>
                        <View style={[{height:"10px"}]}>
                            <Text style={[{fontSize:8,textAlign:"left",opacity:0.9}]}>
                                {"Ref: "+  datos.quote_parent_status ?   ("Coti " + datos.quote_parent_number) : "-" }
                            </Text>
                        </View>

                        <Svg height="2" width="100%">
                            <Line
                            x1="0"
                            y1="0"
                            x2="600"
                            y2="0"
                            strokeWidth={3}
                            stroke="black"
                            opacity={0.8}
                            />
                        </Svg>
                        <View style={[styles.footerPage]}>
                            <View style={[{ display: "flex",flexDirection: "row",}]}>
                                <Text style={[{fontSize:8,textAlign:"center",opacity:0.9,width:"50%"}]}>  
                                    BBA Transporte Internacional S.R. L
                                </Text>
                                <Text style={[{fontSize:8,textAlign:"center",opacity:0.9,width:"50%"}]}>  
                                    BBA Transporte Internacional LTDA.
                                </Text>
                            </View>
                            <View style={[{ display: "flex",flexDirection: "row",}]}>
                                <Text style={[{fontSize:8,textAlign:"center",opacity:0.9,width:"50%"}]}>  
                                    Km. 9 al norte Cond. Las Tacuaras 
                                </Text>
                                <Text style={[{fontSize:8,textAlign:"center",opacity:0.9,width:"50%"}]}>  
                                    Av. Dr. Alberto Jackson Byington # 3055
                                </Text>
                            </View>
                            <View style={[{ display: "flex",flexDirection: "row",}]}>
                                <Text style={[{fontSize:8,textAlign:"center",opacity:0.9,width:"50%"}]}>  
                                    Telf.: 3-3411418 - 62009281 
                                </Text>
                                <Text style={[{fontSize:8,textAlign:"center",opacity:0.9,width:"50%"}]}>  
                                    Cel.: +55 11 948129459
                                </Text>
                            </View>
                            <View style={[{ display: "flex",flexDirection: "row",}]}>
                                <Text style={[{fontSize:8,textAlign:"center",opacity:0.9,width:"50%"}]}>  
                                    Santa Cruz - Bolívia     
                                </Text>
                                <Text style={[{fontSize:8,textAlign:"center",opacity:0.9,width:"50%"}]}>  
                                    Osasco - São Paulo - Brasil
                                </Text>
                            </View>

                            
                            
                            
                        </View>
                    </View>
                </Page>

        </Document>
        </PDFViewer>
        
        </>


        


    );
}

export default ServicioPDF;

